import Banner from '../Components/Banner';
import BusList from '../Components/BusList';
import ColorfulBanner from '../Components/ColorFullBanner';
import Footer from '../Components/Footer';
import Header from '../Components/Header';
import Partners from '../Components/Partners';
import SupportForm from '../Components/SupportForm';
import { GoogleOAuthProvider } from '@react-oauth/google'; 
import Snowfall from 'react-snowfall'; // Import thư viện snowfall
const TrangChu = () =>{
    return(
        <>
        <GoogleOAuthProvider clientId="260464850273-cqq92h183m8qfg60ooi0mqcbe5ibni0d.apps.googleusercontent.com">
            <div className="relative min-h-screen">

                {/* Hiệu ứng tuyết */}
                <Snowfall
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        zIndex: 10, // Đảm bảo tuyết nằm trên tất cả các thành phần khác
                    }} 
                />
                
                {/* Các phần khác của trang */}
                <Header />
                <Banner />
                <Partners />
                <BusList />
                <ColorfulBanner />
                <SupportForm />
                <Footer />
            </div>
        </GoogleOAuthProvider>
        </>
    )
}

export default TrangChu;