
import '@fortawesome/fontawesome-free/css/all.min.css';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import AccountDropdown from '../Components/AccountDropdown';

const Header = () => {
    const userEmail = localStorage.getItem("user_email");

    // // Check local storage for user name on component mount
    // useEffect(() => {
    //     const name = localStorage.getItem("ten");
    //     setUserName(name);
    // }, []);

    return (
        <header className="bg-gradient-to-r from-green-600 to-green-600">
            <nav className="flex justify-between items-center max-w-6xl mx-auto">
            <div className="flex">
                    <img
                        src="/images/logo.png"
                        alt="BusFree Logo"
                        className="h-16 w-23" // Kích thước hợp lý và tự động căn chỉnh tỉ lệ
                    />
                    </div>  

                <ul className="flex space-x-8">
                    <li>
                        <a href="/" className="flex items-center text-white text-lg hover:text-yellow-300 transition-colors duration-300">
                            <i className="fas fa-home text-lg mr-1 px-3 text-2xl"></i> Trang chủ
                        </a>
                    </li>
                    <li>
                        <Link to="/route" className="flex items-center text-white text-lg hover:text-yellow-300 transition-colors duration-300">
                            <i className="fas fa-bus text-lg mr-1"></i> Tuyến Xe
                        </Link>
                    </li>
                    <li>
                        <Link to="/about" className="flex items-center text-white text-lg hover:text-yellow-300 transition-colors duration-300">
                            <i className="fas fa-info-circle text-lg mr-1"></i> Giới thiệu
                        </Link>
                    </li>
                    <li>
                        <Link to="/contact" className="flex items-center text-white text-lg hover:text-yellow-300 transition-colors duration-300">
                            <i className="fas fa-envelope text-lg mr-1"></i> Hỗ trợ
                        </Link>
                    </li>
                    <li>
                        <Link to="/news" className="flex items-center text-white text-lg hover:text-yellow-300 transition-colors duration-300">
                            <i className="fas fa-newspaper text-lg mr-1"></i> Tin tức
                        </Link>
                    </li>
                    <li>
                        <Link to="/timkiem" className="flex items-center text-white text-lg hover:text-yellow-300 transition-colors duration-300">
                            <i className="fas fa-search text-lg mr-1"></i> Tìm Kiếm
                        </Link>
                    </li>
                    <AccountDropdown userName={userEmail}/>
                </ul>
            </nav>
        </header>
    );
};

export default Header;
