
// import React, { useEffect, useRef, useState } from 'react';
// import maplibregl from 'maplibre-gl';
// import 'maplibre-gl/dist/maplibre-gl.css';

// const GoongMap = () => {
//     const mapContainerRef = useRef(null);
//     const [map, setMap] = useState(null);
//     const [searchResults, setSearchResults] = useState([]);
//     const [directionsVisible, setDirectionsVisible] = useState(false);
//     const [startLocation, setStartLocation] = useState('');
//     const [endLocation, setEndLocation] = useState('');
//     const [mapStyles, setMapStyles] = useState([]);
//     const [selectedStyle, setSelectedStyle] = useState('Normal');

//     const apiUrl = 'https://rsapi.goong.io';
//     const apiKey = 'QFOsSIbHTdYzfRrboMH8CGZg9KvaOJlsXz6hZmjp'; // Thay thế bằng API key của bạn
//     const mapKey = 'BSXct3ZJXeeIRZZ1TmVDrInhNDXUBYyQv9vKOdEU'; // Thay thế bằng Map key của bạn
//     const center = [105.85242472181584, 21.029579719995272];
//     const zoom = 14;

//     useEffect(() => {
//         const initializeMap = () => {
//             const mapInstance = new maplibregl.Map({
//                 container: mapContainerRef.current,
//                 style: `https://tiles.goong.io/assets/goong_map_web.json?api_key=${mapKey}`,
//                 center: center,
//                 zoom: zoom,
//             });

//             mapInstance.on('load', () => {
//                 new maplibregl.Marker().setLngLat(center).addTo(mapInstance);
//                 setMapStyles([
//                     { name: 'Normal', url: `https://tiles.goong.io/assets/goong_map_web.json?api_key=${mapKey}` },
//                     { name: 'Satellite', url: `https://tiles.goong.io/assets/goong_satellite.json?api_key=${mapKey}` },
//                     { name: 'Dark', url: `https://tiles.goong.io/assets/goong_map_dark.json?api_key=${mapKey}` },
//                     { name: 'Light', url: `https://tiles.goong.io/assets/navigation_day.json?api_key=${mapKey}` },
//                     { name: 'Night', url: `https://tiles.goong.io/assets/navigation_night.json?api_key=${mapKey}` }
//                 ]);
//             });

//             setMap(mapInstance);
//         };

//         if (!map) initializeMap();

//         return () => {
//             if (map) {
//                 map.remove();
//             }
//         };
//     }, [map]);

//     const handleSearchInput = (event) => {
//         const query = event.target.value;
//         if (query.length >= 2) {
//             fetchDataAutoComplete(query);
//         } else {
//             setSearchResults([]);
//         }
//     };

//     const fetchDataAutoComplete = (query) => {
//         const apiLink = `${apiUrl}/Place/AutoComplete?api_key=${apiKey}&input=${encodeURIComponent(query)}`;
//         fetch(apiLink)
//             .then(response => response.json())
//             .then(data => {
//                 if (data.predictions) {
//                     setSearchResults(data.predictions);
//                 } else {
//                     setSearchResults([]);
//                 }
//             })
//             .catch(error => console.error('Error fetching data:', error));
//     };

//     const handlePlaceSelect = (place) => {
//         const { place_id } = place;
//         fetchPlaceDetails(place_id);
//         setSearchResults([]);
//     };

//     const fetchPlaceDetails = (placeId) => {
//         const apiLink = `${apiUrl}/Place/Detail?api_key=${apiKey}&place_id=${placeId}`;
//         fetch(apiLink)
//             .then(response => response.json())
//             .then(data => {
//                 if (data.result) {
//                     const { location } = data.result.geometry;
//                     const lngLat = [location.lng, location.lat];
//                     addMarker(lngLat);
//                 }
//             })
//             .catch(error => console.error('Error fetching place details:', error));
//     };

//     const addMarker = (lngLat) => {
//         new maplibregl.Marker().setLngLat(lngLat).addTo(map);
//         map.flyTo({ center: lngLat, zoom: zoom });
//     };

//     const handleGetDirections = () => {
//         if (startLocation && endLocation) {
//             fetchDirections(startLocation, endLocation);
//         } else {
//             alert('Please enter both start and end locations.');
//         }
//     };

//     const fetchDirections = (start, end) => {
//         const apiLink = `${apiUrl}/Direction?origin=${start}&destination=${end}&vehicle=car&api_key=${apiKey}`;
//         fetch(apiLink)
//             .then(response => response.json())
//             .then(data => {
//                 if (data.routes && data.routes.length > 0) {
//                     const route = data.routes[0].overview_polyline.points;
//                     const distance = data.routes[0].legs[0].distance.text;
//                     const time = data.routes[0].legs[0].duration.text;
//                     const decodedRoute = decodePolyline(route);
//                     displayRoute(decodedRoute, distance, time);
//                 } else {
//                     alert('Could not find a route.');
//                 }
//             })
//             .catch(error => console.error('Error fetching directions:', error));
//     };

//     const decodePolyline = (encoded) => {
//         const points = [];
//         let index = 0, len = encoded.length;
//         let lat = 0, lng = 0;

//         while (index < len) {
//             let b, shift = 0, result = 0;
//             do {
//                 b = encoded.charAt(index++).charCodeAt(0) - 63;
//                 result |= (b & 0x1f) << shift;
//                 shift += 5;
//             } while (b >= 0x20);
//             const dlat = ((result & 1) ? ~(result >> 1) : (result >> 1));
//             lat += dlat;

//             shift = 0;
//             result = 0;
//             do {
//                 b = encoded.charAt(index++).charCodeAt(0) - 63;
//                 result |= (b & 0x1f) << shift;
//                 shift += 5;
//             } while (b >= 0x20);
//             const dlng = ((result & 1) ? ~(result >> 1) : (result >> 1));
//             lng += dlng;

//             points.push([lng * 1e-5, lat * 1e-5]);
//         }

//         return points;
//     };

//     const displayRoute = (route, distance, time) => {
//         if (map.getSource('route')) {
//             map.removeLayer('route');
//             map.removeSource('route');
//         }

//         map.addSource('route', {
//             'type': 'geojson',
//             'data': {
//                 'type': 'Feature',
//                 'properties': {},
//                 'geometry': {
//                     'type': 'LineString',
//                     'coordinates': route
//                 }
//             }
//         });

//         map.addLayer({
//             'id': 'route',
//             'type': 'line',
//             'source': 'route',
//             'layout': {
//                 'line-join': 'round',
//                 'line-cap': 'round'
//             },
//             'paint': {
//                 'line-color': '#3887be',
//                 'line-width': 5,
//                 'line-opacity': 0.9
//             }
//         });

//         const midPoint = route[Math.floor(route.length / 2)];
//         new maplibregl.Popup()
//             .setLngLat(midPoint)
//             .setHTML(`<p>Khoảng cách: ${distance}</p> <p>Thời gian: ${time}</p>`)
//             .addTo(map);

//         map.fitBounds(route.reduce((bounds, coord) => bounds.extend(coord), new maplibregl.LngLatBounds(route[0], route[0])));
//     };

//     const handleMapStyleChange = (style) => {
//         setSelectedStyle(style.name);
//         map.setStyle(style.url);
//     };

//     return (
//       <div>
//       {/* Phần tìm kiếm */}
//       <div className="absolute flex flex-col items-start gap-6 p-4 z-10">
//         <div className="flex items-center w-full gap-4">
//           <input
//             className="w-80 h-10 px-3 border border-gray-300 rounded-l-sm focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-sm"
//             id="input"
//             onChange={handleSearchInput}
//             placeholder="Tìm kiếm địa điểm"
//           />
//           <button
//             onClick={() => setDirectionsVisible(!directionsVisible)}
//             className="p-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none"
//           >
//             {directionsVisible ? 'Ẩn Dẫn Đường' : 'Hiển Thị Dẫn Đường'}
//           </button>
//         </div>
//         {directionsVisible && (
//         <div className="absolute flex flex-col gap-9 p-4 bg-white shadow-lg rounded-lg w-[432px] z-10">
//           <input
//             type="text"
//             value={startLocation}
//             onChange={(e) => setStartLocation(e.target.value)}
//             placeholder="Điểm đầu"
//             className="w-full h-10 px-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
//           />
//           <input
//             type="text"
//             value={endLocation}
//             onChange={(e) => setEndLocation(e.target.value)}
//             placeholder="Điểm cuối"
//             className="w-full h-10 px-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
//           />
//           <button
//             onClick={handleGetDirections}
//             className="p-3 bg-green-600 text-white rounded-md hover:bg-green-700 focus:outline-none"
//           >
//             Dẫn đường
//           </button>
//         </div>
//       )}
//         {/* Kết quả tìm kiếm */}
//         <div className="absolute top-[58px] w-[432px] bg-white shadow-lg rounded-lg p-3 z-10 border border-gray-300">
//           {searchResults.map((result) => (
//             <div
//               key={result.place_id}
//               className="w-full cursor-pointer mb-2 p-2 hover:bg-gray-200 rounded-md"
//               onClick={() => handlePlaceSelect(result)}
//             >
//               {result.description}
//             </div>
//           ))}
//         </div>
        
//         <hr className="my-3 w-full" />
//       </div>
//          {/* Hiển thị dẫn đường */}
     
//       {/* Dropdown chọn kiểu bản đồ */}
//       {/* <div className="absolute flex items-center gap-4 p-4 bg-white shadow-lg rounded-lg z-10 w-[432px]">
//         <h3 className="text-lg font-semibold">Chọn kiểu bản đồ:</h3>
//         <select
//           onChange={(e) => {
//             const selectedStyle = mapStyles.find((style) => style.name === e.target.value);
//             if (selectedStyle && map) {
//               map.setStyle(selectedStyle.url); // Cập nhật kiểu bản đồ
//             }
//           }}
//           className="p-3 border rounded-md shadow-sm focus:ring-2 focus:ring-blue-500"
//         >
//           {mapStyles.map((style) => (
//             <option key={style.name} value={style.name}>
//               {style.name}
//             </option>
//           ))}
//         </select>
//       </div> */}
    
     
    
//       {/* Bản đồ */}
//       <div ref={mapContainerRef} style={{ height: '400px', width: '100%' }} />
//     </div>
    
      

//     );
// };

// export default GoongMap;

import React, { useState, useEffect, useRef } from 'react';
import * as maplibregl from 'maplibre-gl';

const MapComponent = ({ idchuyen }) => {
  const mapContainerRef = useRef(null);
  const [map, setMap] = useState(null);
  const [distance, setDistance] = useState(null);
  const [duration, setDuration] = useState(null);

  useEffect(() => {
    const mapInstance = new maplibregl.Map({
      container: mapContainerRef.current,
      style: 'https://tiles.goong.io/assets/goong_map_web.json?api_key=BSXct3ZJXeeIRZZ1TmVDrInhNDXUBYyQv9vKOdEU',
      center: [105.85242472181584, 21.029579719995272],
      zoom: 14,
    });

    setMap(mapInstance);

    mapInstance.on('load', () => {
      console.log('Map is loaded');
    });

    return () => {
      if (mapInstance) {
        mapInstance.remove();
      }
    };
  }, []);

  useEffect(() => {
    if (idchuyen) {
      console.log(`Fetching route details for trip ID: ${idchuyen}`);
      fetch(`http://localhost:8000/api/trips/${idchuyen}`)
        .then((response) => response.json())
        .then((data) => {
          console.log('Route data from API:', data);
          if (data && data.chitiettuyenduong) {
            const { vitri_batdau, vitri_ketthuc } = data.chitiettuyenduong;
            const start = [vitri_batdau.longitude, vitri_batdau.latitude];
            const end = [vitri_ketthuc.longitude, vitri_ketthuc.latitude];
            fetchDirections(start, end).then((route) => {
              if (route) {
                setDistance(route.distance);
                setDuration(route.duration);
                displayRouteOnMap(route.geometry.coordinates);
              }
            });
          }
        })
        .catch((error) => console.error('Error fetching route details:', error));
    }
  }, [idchuyen, map]);

  const fetchDirections = async (start, end) => {
    const url = `https://api.mapbox.com/directions/v5/mapbox/driving/${start[0]},${start[1]};${end[0]},${end[1]}?access_token=pk.eyJ1IjoiaG90aGlxdXluaHRyYW0iLCJhIjoiY200MTR3OTdzMXVnYTJsb2ZzYTF2d2NldCJ9.ASAtpN24vsDyneWuVEIGLw&geometries=geojson&overview=full`;

    try {
      const response = await fetch(url);
      const data = await response.json();
      if (data.routes && data.routes.length > 0) {
        return data.routes[0]; // Lấy tuyến đường đầu tiên
      }
    } catch (error) {
      console.error('Error fetching directions:', error);
    }
  };

  const displayRouteOnMap = (coordinates) => {
    if (!map || !map.isStyleLoaded()) {
      console.error('Map is not ready to display route.');
      return;
    }

    if (map.getLayer('route')) {
      map.removeLayer('route');
    }
    if (map.getSource('route')) {
      map.removeSource('route');
    }

    map.addSource('route', {
      type: 'geojson',
      data: {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: coordinates,
        },
      },
    });

    map.addLayer({
      id: 'route',
      type: 'line',
      source: 'route',
      paint: {
        'line-color': '#ff0000',
        'line-width': 4,
      },
    });

    map.fitBounds(coordinates, {
      padding: 50,
    });
  };

  const formatDistance = (meters) => `${(meters / 1000).toFixed(2)} km`;
  const formatDuration = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours} giờ ${remainingMinutes} phút`;
  };

  return (
    <div>
      <h1>Tuyến Đường của Chuyến Đi ID: {idchuyen}</h1>

      {distance && duration && (
        <div>
          <p>Khoảng cách: {formatDistance(distance)}</p>
          <p>Thời gian dự kiến: {formatDuration(duration)}</p>
        </div>
      )}

      <div ref={mapContainerRef} style={{ height: '400px', width: '100%' }} />
    </div>
  );
};

export default MapComponent;
