import React, { useState, useEffect } from "react";
import axios from "axios";
import Sidebar from "../Components/SideBar";
import Header from "../Components/Header";
import { useSnackbar } from "notistack";

const NewsManagement = () => {
  const [visibleNews, setVisibleNews] = useState([]);
  const [hiddenNews, setHiddenNews] = useState([]);
  const [currentVisiblePage, setCurrentVisiblePage] = useState(1);
  const [currentHiddenPage, setCurrentHiddenPage] = useState(1);
  const [totalVisiblePages, setTotalVisiblePages] = useState(1);
  const [totalHiddenPages, setTotalHiddenPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [previewThumbnail, setPreviewThumbnail] = useState(null);
  const [isAddPopupOpen, setIsAddPopupOpen] = useState(false);
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [activeTab, setActiveTab] = useState("visible"); // New state to track active tab
  const [newsData, setNewsData] = useState({
    id: null,
    title: "",
    content: "",
    thumbnail: null,
    views: 0,
    status: "published",
    anhien: "1",
    admin_id: "",
    admin_name: "",
    category_id: null,
    category_name: "",
  });

  // Fetch news lists
  useEffect(() => {
    const fetchNews = async () => {
      setIsLoading(true);
      try {
        const token = localStorage.getItem("access_token");
        
        // Fetch visible news
        const visibleResponse = await axios.get(
          `http://localhost:8000/api/news?page=${currentVisiblePage}&search=${searchQuery}&anhien=1`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        
        // Fetch hidden news
        const hiddenResponse = await axios.get(
          `http://localhost:8000/api/newshideen?page=${currentHiddenPage}&search=${searchQuery}&anhien=0`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
  
        setVisibleNews(visibleResponse.data.data);
        setCurrentVisiblePage(visibleResponse.data.current_page);
        setTotalVisiblePages(visibleResponse.data.last_page);
        
        setHiddenNews(hiddenResponse.data.data);
        setCurrentHiddenPage(hiddenResponse.data.current_page);
        setTotalHiddenPages(hiddenResponse.data.last_page);
      } catch (error) {
        console.error("Error fetching news:", error);
        alert("Failed to fetch news: " + error.message);
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchNews();
  }, [currentVisiblePage, currentHiddenPage, searchQuery]);

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentVisiblePage(1);
    setCurrentHiddenPage(1);
  };

  // Handle input changes (previous implementation remains the same)
  const handleInputChange = (e) => {
    const { name, value, type, files } = e.target;
  
    if (type === "file" && files.length > 0) {
      const file = files[0];
  
      // Kiểm tra định dạng file
      const validImageTypes = ["image/jpeg", "image/png", "image/jpg", "image/gif"];
      if (!validImageTypes.includes(file.type)) {
        alert("Chỉ chấp nhận các file hình ảnh có định dạng jpeg, png, jpg, hoặc gif.");
        return;
      }
  
      setNewsData({ ...newsData, [name]: file });
    } else {
      setNewsData({ ...newsData, [name]: value });
    }
  };

  const handleSubmitNews = async (e) => {
    e.preventDefault();
    setIsLoading(true);
  
    try {
      const token = localStorage.getItem("access_token");
      if (!token) {
        alert("Missing authentication token. Please login again.");
        return;
      }
  
      const formData = new FormData();
  
      // Gán admin_id từ thông tin người dùng đã đăng nhập
      const adminId = localStorage.getItem("admin_id"); // Ví dụ, nếu bạn lưu admin_id trong localStorage
      if (!adminId) {
        alert("Admin ID not found");
        return;
      }
      formData.append("admin_id", adminId); // Thêm admin_id vào formData
  
      // Kiểm tra và thêm thumbnail mới nếu có
      if (newsData.thumbnail instanceof File) {
        formData.append("thumbnail", newsData.thumbnail);
      } else {
        formData.append("thumbnail", "");
      }
  
      // Thêm các trường khác vào formData
      Object.keys(newsData).forEach((key) => {
        if (
          newsData[key] !== null &&
          newsData[key] !== "" &&
          !(key === "thumbnail" && !(newsData.thumbnail instanceof File))
        ) {
          formData.append(key, newsData[key]);
        }
      });
  
      const url = newsData.id
        ? `http://localhost:8000/api/newsupdate/${newsData.id}`
        : "http://localhost:8000/api/news";
  
      const response = await axios.post(url, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
  
      if (response.status === 200 || response.status === 201) {
        alert(newsData.id ? "Cập nhật tin tức thành công!" : "Thêm tin tức mới thành công!");
        setIsAddPopupOpen(false);
        setIsEditPopupOpen(false);
        setCurrentVisiblePage(1);
        setCurrentHiddenPage(1);
      }
    } catch (error) {
      console.error("Error submitting news:", error);
      const errorMessage =
        error.response?.data?.message || "Đã xảy ra lỗi khi gửi dữ liệu.";
      alert(`Error: ${errorMessage}`);
    } finally {
      setIsLoading(false);
    }
  };
  
  
  // Open edit news popup
  const handleEditNews = (newsItem) => {
    setNewsData({
      id: newsItem.id,
      title: newsItem.title,
      content: newsItem.content,
      thumbnail: newsItem.thumbnail, 
      views: newsItem.views,
      status: newsItem.status,
      anhien: newsItem.anhien,
      admin_id: newsItem.admin?.id,
      admin_name: newsItem.admin?.ten,
      category_id: newsItem.categoryDetail?.id,
      category_name: newsItem.categoryDetail?.name
    });
    setIsEditPopupOpen(true);
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setNewsData({ ...newsData, thumbnail: file });
      
      // Hiển thị ảnh mới ngay lập tức
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewThumbnail(reader.result); // Lưu preview để hiển thị
      };
      reader.readAsDataURL(file);
    }
  };

  // Delete news
  const handleDeleteNews = async (newsId) => {
    if (!window.confirm("Are you sure you want to delete this news?")) return;

    try {
      const token = localStorage.getItem("access_token");
      const response = await axios.delete(`http://localhost:8000/api/news/${newsId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status === 200) {
        alert("News deleted successfully!");
        setVisibleNews((prevNews) => prevNews.filter((item) => item.id !== newsId));
        setHiddenNews((prevNews) => prevNews.filter((item) => item.id !== newsId));
      }
    } catch (error) {
      console.error("Error deleting news:", error);
      alert("An error occurred: " + JSON.stringify(error.response?.data || error));
    }
  };

 // Toggle news visibility
const handleToggleVisibility = async (newsId, currentVisibility) => {
  try {
    const token = localStorage.getItem("access_token");
    const newVisibility = currentVisibility === 1 ? 0 : 1;

    const response = await axios.put(
      `http://localhost:8000/api/news/${newsId}`,
      { anhien: newVisibility },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {
      // Refresh news lists
      setCurrentVisiblePage(1);
      setCurrentHiddenPage(1);
      enqueueSnackbar("Cập nhật thông tin thành công!", { variant: "success" ,anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },});
    }
  } catch (error) {
    console.error("Error toggling news visibility:", error);
    alert("An error occurred: " + JSON.stringify(error.response?.data || error));
  }
};


  // Pagination handlers
  const handleVisiblePageChange = (page) => {
    if (page >= 1 && page <= totalVisiblePages) {
      setCurrentVisiblePage(page);
    }
  };

  const handleHiddenPageChange = (page) => {
    if (page >= 1 && page <= totalHiddenPages) {
      setCurrentHiddenPage(page);
    }
  };

  // Render news table
  const renderNewsTable = (newsList, isVisible) => (
    <table className="min-w-full table-auto border-collapse border border-gray-300">
      <thead>
        <tr>
          <th className="px-4 py-2 border border-gray-300">ID</th>
          <th className="px-4 py-2 border border-gray-300">Title</th>
          <th className="px-4 py-2 border border-gray-300">Content</th>
          <th className="px-4 py-2 border border-gray-300">Views</th>
          <th className="px-4 py-2 border border-gray-300">Status</th>
          <th className="px-4 py-2 border border-gray-300">Actions</th>
        </tr>
      </thead>
      <tbody>
        {newsList.map((newsItem) => (
          <tr key={newsItem.id}>
            <td className="px-4 py-2 border border-gray-300">{newsItem.id}</td>
            <td className="px-4 py-2 border border-gray-300">{newsItem.title}</td>
            <td className="px-4 py-2 border border-gray-300 max-w-xs truncate">{newsItem.content}</td>
            <td className="px-4 py-2 border border-gray-300">{newsItem.views}</td>
            <td className="px-4 py-2 border border-gray-300">{newsItem.status}</td>
            <td className="px-4 py-2 border border-gray-300 flex items-center gap-2">
              <button
                onClick={() => handleEditNews(newsItem)}
                className="bg-yellow-500 text-white px-2 py-1 rounded"
              >
                <i className="fas fa-edit"></i>
              </button>
              <button
                onClick={() => handleDeleteNews(newsItem.id)}
                className="bg-red-500 text-white px-2 py-1 rounded"
              >
                <i className="fas fa-trash"></i>
              </button>
              <button
                onClick={() => handleToggleVisibility(newsItem.id, newsItem.anhien)}
                className={`${isVisible ? 'bg-red-500' : 'bg-green-500'} text-white px-2 py-1 rounded`}
              >
                <i className={`fas ${isVisible ? 'fa-eye-slash' : 'fa-eye'}`}></i>
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  return (
    <div className="flex h-screen">
      <Sidebar />
      <div className="flex-1 flex flex-col">
        <Header />
        <div className="p-4 overflow-y-auto">
          <h1 className="text-2xl font-bold mb-4">Quản lý tin tức</h1>

          {/* Search and Add Button */}
          <div className="mb-4 flex items-center space-x-4">
            <div className="relative w-full md:w-[650px]">
              <input
                type="text"
                placeholder="Search by title or content..."
                className="border px-4 py-2 rounded w-full pl-10"
                value={searchQuery}
                onChange={handleSearchChange}
              />
              <i className="fas fa-search absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500"></i>
            </div>
            <button
              onClick={() => {
                setNewsData({
                  id: null,
                  title: "",
                  content: "",
                  thumbnail: null,
                  views: 0,
                  status: "published",
                  anhien: 1,
                });
                setIsAddPopupOpen(true);
              }}
              className="bg-green-500 text-white px-4 py-2 rounded flex items-center gap-2"
            >
              <i className="fas fa-plus text-lg"></i>
              <span className="text-sm">Thêm bài viết</span>
            </button>
          </div>

          {/* Tabs for Visible and Hidden News */}
          <div className="flex mb-4">
            <button 
              className={`px-4 py-2 ${activeTab === 'visible' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
              onClick={() => setActiveTab('visible')}
            >
              Bài viết hiện
            </button>
            <button 
              className={`px-4 py-2 ${activeTab === 'hidden' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
              onClick={() => setActiveTab('hidden')}
            >
              Bài viết ẩn
            </button>
          </div>

          {/* Conditionally render news tables */}
          {activeTab === 'visible' ? (
            <>
              {renderNewsTable(visibleNews, true)}
              {/* Visible News Pagination */}
              <div className="mt-4 flex justify-center gap-4">
                <button
                  onClick={() => handleVisiblePageChange(currentVisiblePage - 1)}
                  className="px-4 py-2 bg-blue-500 text-white rounded"
                  disabled={currentVisiblePage === 1}
                >
                  Previous
                </button>
                <span className="self-center">
                  Page {currentVisiblePage} of {totalVisiblePages}
                </span>
                <button
                  onClick={() => handleVisiblePageChange(currentVisiblePage + 1)}
                  className="px-4 py-2 bg-blue-500 text-white rounded"
                  disabled={currentVisiblePage === totalVisiblePages}
                >
                  Next
                </button>
              </div>
            </>
          ) : (
            <>
              {renderNewsTable(hiddenNews, false)}
              {/* Hidden News Pagination */}
              <div className="mt-4 flex justify-center gap-4">
                <button
                  onClick={() => handleHiddenPageChange(currentHiddenPage - 1)}
                  className="px-4 py-2 bg-blue-500 text-white rounded"
                  disabled={currentHiddenPage === 1}
                >
                  Previous
                </button>
                <span className="self-center">
                  Page {currentHiddenPage} of {totalHiddenPages}
                </span>
                <button
                  onClick={() => handleHiddenPageChange(currentHiddenPage + 1)}
                  className="px-4 py-2 bg-blue-500 text-white rounded"
                  disabled={currentHiddenPage === totalHiddenPages}
                >
                  Next
                </button>
              </div>
            </>
          )}
         {/* Add/Edit News Popup */}
         {(isAddPopupOpen || isEditPopupOpen) && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div className="bg-white p-6 rounded w-[600px]">
      <h2 className="text-2xl font-bold mb-4">
        {newsData.id ? "Chỉnh sửa nội dung bài viết" : "Thêm bài viết mới"}
      </h2>
      <form onSubmit={handleSubmitNews}>
        {/* Title */}
        <div className="mb-4">
          <label className="block mb-2 font-medium">Tiêu đề bài viết</label>
          <input
            type="text"
            name="title"
            value={newsData.title}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded"
            placeholder="Nhập tiêu đề bài viết"
            required
            maxLength={255}
          />
        </div>

        {/* Content */}
        <div className="mb-4">
          <label className="block mb-2 font-medium">Nội dung</label>
          <textarea
            name="content"
            value={newsData.content}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded h-40"
            placeholder="Nhập nội dung bài viết"
            required
          ></textarea>
        </div>

        {/* Thumbnail (Existing and New Upload) */}
        <div className="mb-4">
          <label className="block mb-2 font-medium">Ảnh đại diện</label>

          {/* Hiển thị ảnh hiện tại hoặc ảnh preview nếu có */}
          {previewThumbnail ? (
            <img
              src={previewThumbnail}
              alt="Preview Thumbnail"
              className="w-32 h-32 object-cover border border-gray-300 rounded mb-2"
            />
          ) : newsData.thumbnail && typeof newsData.thumbnail === "string" ? (
            <img
              src={newsData.thumbnail}
              alt="Thumbnail hiện tại"
              className="w-32 h-32 object-cover border border-gray-300 rounded mb-2"
            />
          ) : null}

          {/* Input để upload ảnh mới */}
          <input
            type="file"
            name="thumbnail"
            onChange={handleFileChange}
            className="w-full p-2 border border-gray-300 rounded"
            accept="image/jpeg,image/png,image/jpg,image/gif"
          />
        </div>

        {/* Form Buttons */}
        <div className="flex justify-end gap-4">
          <button
            type="button"
            onClick={() => {
              setIsAddPopupOpen(false);
              setIsEditPopupOpen(false);
            }}
            className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 transition"
          >
            Hủy
          </button>
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition"
          >
            {newsData.id ? "Cập nhật" : "Thêm bài viết"}
          </button>
        </div>
      </form>
    </div>
  </div>
)}


        </div>
      </div>
    </div>
  );
};

export default NewsManagement;