import React, { useState } from "react";
import { IonIcon } from "react-ion-icon";
import TrangChu from "./Trangchu";
import { Link, useNavigate } from "react-router-dom"; // Import useNavigate
import { useSnackbar } from "notistack";
const Register = ({ onClose }) => {
  const [nameFocused, setNameFocused] = useState(false);
  const [emailFocused, setEmailFocused] = useState(false);
  const [phoneFocused, setPhoneFocused] = useState(false);
  const [passwordFocused, setPasswordFocused] = useState(false);
  const [confirmPasswordFocused, setConfirmPasswordFocused] = useState(false);
  const [mssvFocused, setmssvFocused] = useState(false);
  const [addressFocused, setAddressFocused] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate(); // Initialize navigate

  const [formData, setFormData] = useState({
    ten: "",
    email: "",
    sdt: "",
    password: "",
    password_confirmation: "",
    mssv: "",
    gioitinh: "",
    diachi:"",
    img: ""
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [imagePreview, setImagePreview] = useState(null); // For image preview

  const validateForm = () => {
    const newErrors = {};
    if (!formData.ten) newErrors.ten = "Họ tên là bắt buộc";
    if (!formData.email) {
      newErrors.email = "Email là bắt buộc";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email không hợp lệ";
    }
    if (!formData.sdt) newErrors.sdt = "Số điện thoại là bắt buộc";
    else if (!/^\d{10}$/.test(formData.sdt)) {
      newErrors.sdt = "Số điện thoại không hợp lệ";
    }
    if (!formData.password) newErrors.password = "Mật khẩu là bắt buộc";
    else if (formData.password.length < 6) {
      newErrors.password = "Mật khẩu phải có ít nhất 6 ký tự";
    }
    if (!formData.password_confirmation) {
      newErrors.password_confirmation = "Nhập lại mật khẩu là bắt buộc";
    } else if (formData.password !== formData.password_confirmation) {
      newErrors.password_confirmation = "Mật khẩu không khớp";
    }
    if (!formData.mssv) newErrors.mssv = "Mã sinh viên là bắt buộc";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData({ ...formData, img: file });
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsSubmitting(true);

      const formDataToSend = new FormData();
      formDataToSend.append("ten", formData.ten);
      formDataToSend.append("email", formData.email);
      formDataToSend.append("sdt", formData.sdt);
      formDataToSend.append("password", formData.password);
      formDataToSend.append("password_confirmation", formData.password_confirmation);
      formDataToSend.append("mssv", formData.mssv);
      formDataToSend.append("gioitinh", formData.gioitinh);
      formDataToSend.append("diachi", formData.diachi);
      if (formData.img) formDataToSend.append("img", formData.img);

      const response = await fetch("http://localhost:8000/api/register", {
        method: "POST",
        body: formDataToSend,
      });

      console.log(formData.gioitinh); // Kiểm tra giá trị của gioitinh

      const result = await response.json();

      if (response.ok) {
        // alert("Đăng ký thành công!");
        localStorage.setItem("token", result.token); // Save token if present
        enqueueSnackbar('Đăng ký thành công!', { variant: 'success' ,anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        autoHideDuration: 2000, // Thời gian hiển thị (ms)
      });
        navigate("/login");
      } else {
alert(result.message || "Đã xảy ra lỗi. Vui lòng thử lại.");
      }

      setIsSubmitting(false);
    }
  };

  return (
    <>
      <TrangChu />
      <div id="registerForm" className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
  <div className="wrapper-register max-w-xl w-full bg-white rounded-2xl shadow-lg border border-gray-300 p-8 overflow-hidden relative">
    {/* Close Button */}
    <Link 
      to="/" 
      className="icon absolute text-center top-0 p-1 right-0 w-11 h-11 cursor-pointer bg-white rounded-bl-[20px] z-10  hover:bg-red-600 text-2xl text-[#162938] hover:text-white transition-all duration-300"
    >
      &times;
    </Link>
    
    <h2 className="text-3xl text-center text-[#162938] mb-6">Đăng ký</h2>
    <form className="grid grid-cols-1 md:grid-cols-2 gap-4" onSubmit={handleSubmit} encType="multipart/form-data">
      {/* Name Field */}
      <div className="input-box relative">
        <span className="icon absolute right-2 top-3 text-gray-700">
          <IonIcon name="person" />
        </span>
        <input
          type="text"
          name="ten"
          required
          value={formData.ten}
          onChange={(e) => setFormData({ ...formData, ten: e.target.value })}
          className="w-full h-10 border-b-2 border-[#162938] focus:border-[#28a745] bg-transparent outline-none text-gray-700 font-semibold pl-2 pr-10"
         
          onFocus={() => setNameFocused(true)}
                onBlur={(e) => setNameFocused(e.target.value !== "")}
        />
        <label
                className={`absolute left-2 transition-all duration-500 ${
                  nameFocused ? "-top-2 text-sm" : "top-2 text-base"
                } text-[#162938]`}
              >
                Họ tên  
              </label>
        {errors.ten && <span className="text-red-500 text-sm">{errors.ten}</span>}
      </div>

      {/* Email Field */}
      <div className="input-box relative">
        <span className="icon absolute right-2 top-3 text-gray-700">
          <IonIcon name="mail" />
        </span>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          className="w-full h-10 border-b-2 border-[#162938] focus:border-[#28a745] bg-transparent outline-none text-gray-700 font-semibold pl-2 pr-10"
          onFocus={() => setEmailFocused(true)}
                onBlur={(e) => setEmailFocused(e.target.value !== "")}
        />
        <label
                className={`absolute left-2 transition-all duration-500 ${
                  emailFocused ? "-top-2 text-sm" : "top-2 text-base"
                } text-[#162938]`}
              >
                Email  
              </label>
        {errors.email && <span className="text-red-500 text-sm">{errors.email}</span>}
      </div>

      {/* Password Fields */}
      
      <div className="input-box relative">
        <input
          type="password"
          name="password"
          value={formData.password}
          onChange={(e) => setFormData({ ...formData, password: e.target.value })}
          className="w-full h-10 border-b-2 border-[#162938] focus:border-[#28a745] bg-transparent outline-none text-gray-700 font-semibold pl-2"
          onFocus={() => setPasswordFocused(true)}
                onBlur={(e) => setPasswordFocused(e.target.value !== "")}
        />
        <label
                className={`absolute left-2 transition-all duration-500 ${
                  passwordFocused ? "-top-2 text-sm" : "top-2 text-base"
                } text-[#162938]`}
              >
                Mật khẩu  
              </label>
              {errors.password && <span className="text-red-500 text-sm">{errors.password}</span>}
            </div>
              <div className="input-box relative">
        <input
          type="password"
          name="password_confirmation"
          value={formData.password_confirmation}
          onChange={(e) => setFormData({ ...formData, password_confirmation: e.target.value })}
          className="w-full h-10 border-b-2 border-[#162938] focus:border-[#28a745] bg-transparent outline-none text-gray-700 font-semibold pl-2"        
          onFocus={() => setConfirmPasswordFocused(true)}
                onBlur={(e) => setConfirmPasswordFocused(e.target.value !== "")}
        />
        <label
                className={`absolute left-2 transition-all duration-500 ${
                  confirmPasswordFocused ? "-top-2 text-sm" : "top-2 text-base"
                } text-[#162938]`}
              >
                Nhập lại mật khẩu  
              </label>
        {errors.password_confirmation && <span className="text-red-500 text-sm">{errors.password_confirmation}</span>}
        </div>
      

      {/* Phone Field */}
      <div className="input-box relative">
        <span className="icon absolute right-2 top-3 text-gray-700">
          <IonIcon name="user" />
        </span>
        <input
          type="text"
          name="mssv"
          value={formData.mssv}
          onChange={(e) => setFormData({ ...formData, mssv: e.target.value })}
          className="w-full h-10 border-b-2 border-[#162938] focus:border-[#28a745] bg-transparent outline-none text-gray-700 font-semibold pl-2 pr-10"
         
          onFocus={() => setmssvFocused(true)}
                onBlur={(e) => setmssvFocused(e.target.value !== "")}

        />
         <label
                className={`absolute left-2 transition-all duration-500 ${
                  mssvFocused ? "-top-2 text-sm" : "top-2 text-base"
                } text-[#162938]`}
              >
                Mã số sinh viên 
              </label>
        {errors.mssv && <span className="text-red-500 text-sm">{errors.mssv}</span>}
      </div>
      <div className="input-box relative">
        <span className="icon absolute right-2 top-3 text-gray-700">
          <IonIcon name="call" />
        </span>
        <input
          type="text"
          name="sdt"
          value={formData.sdt}
          onChange={(e) => setFormData({ ...formData, sdt: e.target.value })}
          className="w-full h-10 border-b-2 border-[#162938] focus:border-[#28a745] bg-transparent outline-none text-gray-700 font-semibold pl-2 pr-10"
          onFocus={() => setPhoneFocused(true)}
                onBlur={(e) => setPhoneFocused(e.target.value !== "")}
        />
        <label
                className={`absolute left-2 transition-all duration-500 ${
                  phoneFocused ? "-top-2 text-sm" : "top-2 text-base"
                } text-[#162938]`}
              >
                Số điện thoại  
              </label>
        {errors.sdt && <span className="text-red-500 text-sm">{errors.sdt}</span>}
      </div>  

      {/* Gender Selection */}
      <div className="mb-4">
        <label className="block text-gray-700 font-semibold mb-2">Giới tính</label>
        <select
          name="gioitinh"
          value={formData.gioitinh}
          className="block w-full p-2 border border-gray-300 rounded-md"
          onChange={(e) => setFormData({ ...formData, gioitinh: e.target.value })}
        >
          <option value="" disabled>Chọn giới tính</option>
          <option value="nam">Nam</option>
          <option value="nu">Nữ</option>
          <option value="khac">Khác</option>
        </select>
      </div>
      <div className="input-box relative">
  <span className="icon absolute right-2 top-3 text-gray-700">
    <IonIcon name="home" />
  </span>
  <input
    type="text"
    name="diachi"
    value={formData.diachi}
    onChange={(e) => setFormData({ ...formData, diachi: e.target.value })}
    className="w-full h-10 border-b-2 border-[#162938] focus:border-[#28a745] bg-transparent outline-none text-gray-700 font-semibold pl-2 pr-10"
    onFocus={() => setAddressFocused(true)}
    onBlur={(e) => setAddressFocused(e.target.value !== "")}
  />
  <label
    className={`absolute left-2 transition-all duration-500 ${
      addressFocused ? "-top-2 text-sm" : "top-2 text-base"
    } text-[#162938]`}
  >
    Địa chỉ
  </label>
</div>

      {/* Image Upload */}
      <div className="col-span-2 mb-4">
        <label className="block text-gray-700 font-semibold mb-2">Tải lên hình ảnh</label>
        <input
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          className="block w-1/2 text-sm text-gray-700 border border-gray-300 rounded-md p-2"
        />
        {imagePreview && <img src={imagePreview} alt="Preview" className="mt-4 w-24 h-24 object-cover rounded-full" />}
      </div>

      {/* Submit Button */}
      <div className="col-span-2">
        <button
          type="submit"
          className="w-full h-10 bg-green-500 text-white font-semibold rounded-lg hover:bg-green-600 transition duration-300"
          disabled={isSubmitting}
        >
          {isSubmitting ? "Đang đăng ký..." : "Đăng ký"}
        </button>
      </div>

      <div className="col-span-2 text-center mt-4 flex justify-center">
        <span className="text-[#162938] text-sm">
          Đã có tài khoản? <Link to="/login" className="text-green-600 hover:underline">Đăng nhập</Link>
        </span>
      </div>
    </form>
  </div>
</div>

    </>
  );
};

export default Register;