import React from 'react';
import Footer from '../Components/Footer';
import Header from '../Components/Header';
import Banner from '../Components/Banner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck,faShieldAlt, faDollarSign, faBus } from '@fortawesome/free-solid-svg-icons'; // Import your desired icons
import FAQSection from '../Components/FAQSection';
const AboutBusFree = () => {
  return (
    <>
    <Header/>
    <Banner/>
    <section className="bg-white py-16">
      <div className="container mx-auto px-6">
        <h2 className="text-3xl font-bold text-center text-green-600 mb-6">
          Giới thiệu về BusFree
        </h2>
        <p className="text-lg text-gray-600 text-center mb-4">
        BusFree là dịch vụ xe buýt trực tuyến, cung cấp cho bạn những trải nghiệm di chuyển an toàn, tiện lợi và tiết kiệm.

</p>
<p className="text-lg text-gray-600 text-center mb-8">
  Chúng tôi cam kết đem lại dịch vụ tốt nhất với mạng lưới xe buýt rộng khắp và dễ dàng đặt vé chỉ bằng vài cú click. Với BusFree, bạn sẽ có thể trải nghiệm:
</p>

<div className="flex flex-wrap justify-center mb-8">
  <div className="max-w-xs mx-4 mb-6 bg-white shadow-lg transition-transform transform hover:scale-105 rounded-lg border border-gray-200 p-6">
    <h3 className="text-xl font-semibold text-gray-800 mb-2">Tiện lợi</h3>
    <p className="text-gray-600">
      Đặt vé nhanh chóng và dễ dàng qua ứng dụng hoặc website của chúng tôi. Bạn có thể kiểm tra lịch trình và tình trạng xe chỉ với vài cú nhấp chuột.
    </p>
  </div>
  <div className="max-w-xs mx-4 mb-6 bg-white shadow-lg transition-transform transform hover:scale-105 rounded-lg border border-gray-200 p-6">
    <h3 className="text-xl font-semibold text-gray-800 mb-2">An toàn</h3>
    <p className="text-gray-600">
      Tất cả xe buýt đều được kiểm tra chất lượng và có đội ngũ tài xế chuyên nghiệp. Chúng tôi luôn đặt sự an toàn của bạn lên hàng đầu.
    </p>
  </div>
  <div className="max-w-xs mx-4 mb-6 bg-white shadow-lg transition-transform transform hover:scale-105 rounded-lg border border-gray-200 p-6">
    <h3 className="text-xl font-semibold text-gray-800 mb-2">Tiết kiệm</h3>
    <p className="text-gray-600">
      Giá vé cạnh tranh và nhiều chương trình khuyến mãi hấp dẫn dành cho khách hàng. Bạn sẽ không phải lo lắng về chi phí khi đi lại.
    </p>
  </div>
</div>


{/* New Section for Student Ticket Booking */}
<div className="text-center mb-8">
  <h2 className="text-3xl font-bold text-green-600 mb-4">Đặt vé xe cho sinh viên</h2>
  <p className="text-lg text-gray-600 mb-4">
    BusFree hiểu rằng sinh viên thường xuyên di chuyển giữa nhà và trường học. Chính vì vậy, chúng tôi cung cấp những ưu đãi đặc biệt cho sinh viên, giúp bạn tiết kiệm chi phí hơn nữa.
  </p>
  <p className="text-lg text-gray-600 mb-4">
    Bạn có thể tận hưởng các chương trình khuyến mãi, giảm giá đặc biệt và dễ dàng đặt vé trực tuyến. Hãy tải ngay ứng dụng BusFree để không bỏ lỡ bất kỳ ưu đãi nào!
  </p>
</div>

{/* Utilities Section */}
<h2 className="text-3xl font-bold text-center text-green-600 my-8">
  Các tiện ích của BusFree
</h2>
<div className="flex flex-wrap justify-center mb-8">
  <div className="max-w-xs mx-4 mb-6 bg-white p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105">
    <h3 className="text-xl font-semibold text-gray-800 mb-2">Theo dõi chuyến đi</h3>
    <p className="text-gray-600">
      Theo dõi tình trạng xe buýt của bạn trong thời gian thực để biết chính xác thời gian đến nơi.
    </p>
  </div>
  <div className="max-w-xs mx-4 mb-6 bg-white p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105">
    <h3 className="text-xl font-semibold text-gray-800 mb-2">Hỗ trợ khách hàng</h3>
    <p className="text-gray-600">
      Đội ngũ hỗ trợ khách hàng 24/7 sẵn sàng giải đáp mọi thắc mắc và hỗ trợ bạn trong suốt hành trình.
    </p>
  </div>
  <div className="max-w-xs mx-4 mb-6 bg-white p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105">
    <h3 className="text-xl font-semibold text-gray-800 mb-2">Đặt vé linh hoạt</h3>
    <p className="text-gray-600">
      Có thể thay đổi hoặc hủy vé dễ dàng qua ứng dụng mà không mất phí.
    </p>
  </div>
</div>

<div className="text-center mt-8">
  <a 
    href="/contact" 
    className="inline-block bg-green-600 text-white px-6 py-3 rounded-lg shadow transition duration-300 hover:bg-green-700 hover:shadow-lg"
  >
    Liên hệ với chúng tôi
  </a>
</div>
</div>
    </section>
    <section className="bg-green-600 text-white py-12">
      <div className="container mx-auto px-4 md:px-12">
        <h2 className="text-4xl font-extrabold text-center mb-8">Hài lòng với ứng dụng!</h2>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {/* Benefits Section */}
          <div className="bg-white text-black rounded-lg p-6 shadow-lg flex flex-col justify-between">
          <ul className="list-none mb-1 text-lg space-y-1">
      <li className="flex items-start">
        <FontAwesomeIcon icon={faCircleCheck} className="text-green-500 mr-1" />
        <span>
          <strong>Ưu đãi hấp dẫn:</strong> Giúp bạn tiết kiệm chi phí khi đặt vé.
        </span>
      </li>
      <li className="flex items-start">
        <FontAwesomeIcon icon={faCircleCheck} className="text-green-500 mr-1" />
        <span>
          <strong>Tùy chọn thanh toán liền mạch:</strong> Đảm bảo trải nghiệm mua vé dễ dàng và nhanh chóng.
        </span>
      </li>
    </ul>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-1xl">
  {/* Play Store Section */}
            <div className="text-center">
                <h3 className="text-2xl font-semibold">4.5 ★</h3>
                <p className='text-gray-300'>50M+ lượt tải</p>
                <p>Play Store</p>
            </div>
            
            {/* App Store Section */}
            <div className="text-center">
                <h3 className="text-2xl font-semibold">4.6 ★</h3>
                <p className='text-gray-300'>10M+ lượt tải</p>
                <p>App Store</p>
            </div>
            </div>

          </div>
          
          {/* QR Code Section */}
          <div className="flex flex-col items-center justify-center bg-white p-2 rounded-lg shadow-lg">
            <img src="/images/QR.svg" alt="Quét để tải" className="mb-1 h-100 w-100" />
            <p className="text-center text-lg bg-green-600 rounded-lg ">Quét để tải</p>
          </div>
          
          {/* Download Links Section */}
          <div className="flex flex-col items-center justify-center bg-white p-6 rounded-lg shadow-lg">
            <p className="text-center text-lg mb-4 text-green-600"><b>Tải ứng dụng trên</b></p>
            <a href="https://play.google.com" className="bg-green-600 text-white rounded-full py-2 px-4 mb-2 transition duration-300 hover:bg-gray-200">
              <span className="font-bold">GET IT ON</span><br /> Google Play
            </a>
            <a href="https://www.apple.com/app-store/" className="bg-green-600 text-white rounded-full py-2 px-4 transition duration-300 hover:bg-gray-200">
              <span className="font-bold">Download on the</span><br /> App Store
            </a>
          </div>
        </div>
      </div>
    </section>
    <section className="bg-white py-16">
      <div className="container mx-auto px-6">
        <h2 className="text-3xl font-bold text-center text-green-600 mb-6">
          Tại sao chọn BusFree?
        </h2>
        <p className="text-lg text-gray-600 text-center mb-4">
          Những tiện ích dành cho hành khách khi đặt vé xe với BusFree.
        </p>
        <div className="flex flex-wrap justify-center">
          <div className="max-w-xs mx-4 mb-6 p-6 bg-gray-100 rounded-lg shadow-md">
            <div className="flex items-center mb-4">
              <FontAwesomeIcon icon={faShieldAlt} className="text-green-600 text-3xl mr-2" />
              <h3 className="text-xl font-semibold text-gray-800">Thanh toán an toàn và bảo mật</h3>
            </div>
            <p className="text-gray-600">
              Chúng tôi đảm bảo mọi giao dịch của bạn đều được bảo mật và an toàn.
            </p>
          </div>
          <div className="max-w-xs mx-4 mb-6 p-6 bg-gray-100 rounded-lg shadow-md">
            <div className="flex items-center mb-4">
              <FontAwesomeIcon icon={faDollarSign} className="text-green-600 text-3xl mr-2" />
              <h3 className="text-xl font-semibold text-gray-800">Không tính phí phụ thu</h3>
            </div>
            <p className="text-gray-600">
              BusFree cam kết không thu thêm bất kỳ khoản phí nào ngoài giá vé.
            </p>
          </div>
          <div className="max-w-xs mx-4 mb-6 p-6 bg-gray-100 rounded-lg shadow-md">
            <div className="flex items-center mb-4">
              <FontAwesomeIcon icon={faBus} className="text-green-600 text-3xl mr-2" />
              <h3 className="text-xl font-semibold text-gray-800">Chuyển đi an toàn và không lo lắng</h3>
            </div>
            <p className="text-gray-600">
              Với hệ thống xe buýt chất lượng cao, bạn có thể hoàn toàn yên tâm trong chuyến đi của mình.
            </p>
          </div>
        </div>
      </div>
    </section>
    <FAQSection/>
    <Footer/>
    </>
    
  );
};

export default AboutBusFree;
