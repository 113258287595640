import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'

const Header = () => {
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState("");
    const userEmail = localStorage.getItem("user_email");
    const userName = localStorage.getItem("user_name");
    const { enqueueSnackbar } = useSnackbar();

    // Danh sách các trang từ Sidebar
    const pages = [
        { to: "/admin", label: "Thống kê" },
        { to: "/Admin/qlNguoidung", label: "Quản lý người dùng" },
        { to: "/Admin/qlTuyenduong", label: "Quản lý tuyến đường" },
        { to: "/Admin/qlDatVe", label: "Quản lý vé xe" },
        { to: "/Admin/qlThanhToan", label: "Quản lý thanh toán" },
        { to: "/Admin/qlBinhLuan", label: "Quản lý bình luận" },
        { to: "/Admin/qltrips", label: "Quản lý chuyến đi" },
        { to: "/Admin/qlDM", label: "Quản lý danh mục" },
        { to: "/Admin/qlBus", label: "Quản lý xe buýt" },
        { to: "/Admin/qlPost", label: "Quản lý bài viết" },
    ];

    const handleSearch = (e) => {
        e.preventDefault();

        const matchedPage = pages.find((page) =>
            page.label.toLowerCase().includes(searchQuery.toLowerCase())
        );

        if (matchedPage) {
            navigate(matchedPage.to); // Điều hướng đến trang tìm thấy
        } else {
            enqueueSnackbar(`Không tìm thấy trang phù hợp`, { variant: "error",anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
              }, });
            // Thông báo khi không tìm thấy trang
        }

        setSearchQuery(""); // Reset thanh tìm kiếm
    };
    const userId = localStorage.getItem("user_id"); // Lấy ID từ localStorage hoặc nơi lưu trữ khác
    const handleLogout = () => {
        localStorage.removeItem("access_token");
        localStorage.removeItem("user_email");
        localStorage.removeItem("user_name");
        
        navigate("/");
    };
    
    return (
        <header className="bg-gray-900 text-white p-4 shadow-md flex justify-between items-center">
            <div className="flex items-center space-x-4">
                <h1 className="text-2xl font-bold">BusFree</h1>
            </div>
            <div className="flex items-center space-x-6">
                <form onSubmit={handleSearch} className="relative">
                    <input
                        type="text"
                        placeholder="Tìm kiếm..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="bg-gray-700 text-white text-sm rounded-full px-20 py-2 pl-10 focus:outline-none"
                    />
                    <button type="submit" className="absolute left-3 top-2.5 text-gray-400">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-5 h-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                            />
                        </svg>
                    </button>
                </form>
                <div className="flex items-center space-x-4">
                    <img src="images/admin.jpg" alt="Admin Profile" className="w-10 h-10 rounded-full" />
                    <div>
                        <p className="font-semibold">{userName}</p>
                        <button onClick={handleLogout} className="text-sm text-gray-300 hover:text-white">
                            Đăng xuất
                        </button>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;

