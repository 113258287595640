// import React from 'react';
// import { Link, useNavigate } from 'react-router-dom';

// const Sidebar = () => {
    
//     const userName = localStorage.getItem("user_name"); // Lấy tên người dùng từ localStorage

//     return (
//         <div className="flex h-screen">
//             <nav className="w-64 bg-gray-800 text-white min-h-screen py-6 px-4">
//                 <div className="flex items-center space-x-3 mb-6">
//                     <img src="images/admin.jpg" alt="Admin Profile" className="w-12 h-12 rounded-full" />
//                     <div>
//                         <p className="font-semibold">{userName}</p>
//                         <Link to="/Admin/profileAdmin" className="text-sm text-gray-300 hover:text-white">Xem hồ sơ</Link>
//                     </div>
//                 </div>
//                 <ul className="space-y-3">
//                     {[
//                         { to: "/admin", label: "Thống kê", icon: (
//                             <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//                                 <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 17a4 4 0 100-8 4 4 0 000 8zm-1 4h4m4-4h2a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2h2" />
//                             </svg>
//                         ) },
//                         { to: "/Admin/qlNguoidung", label: "Quản lý người dùng", icon: (
//                             <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//                                 <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5.121 17.804A4.992 4.992 0 0112 15a4.992 4.992 0 016.879 2.804M15 10a3 3 0 11-6 0 3 3 0 016 0z" />
//                             </svg>
//                         ) },
//                         { to: "/Admin/qlTuyenduong", label: "Quản lý tuyến đường", icon: (
//                             <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//                                 <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9.75 3v7.75H3v2.5h6.75V21h2.5v-7.75H21v-2.5h-8.75V3h-2.5z" />
//                             </svg>
//                         ) },
//                         { to: "/Admin/qlDatVe", label: "Quản lý vé xe", icon: (
//                             <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//                                 <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 10H5.5a2 2 0 01-2-2V5.5h16V8a2 2 0 01-2 2h-3m0 0v2a4 4 0 11-8 0v-2m8 0H9m0 0V8a2 2 0 012-2h2a2 2 0 012 2v2z" />
//                             </svg>
//                         ) },
//                         { to: "/Admin/qlThanhToan", label: "Quản lý thanh toán", icon: (
//                             <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//                                 <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3-.895 3-2-1.343-2-3-2zm9 4a9 9 0 10-18 0h18zm-9 6a9 9 0 01-9-9h18a9 9 0 01-9 9z" />
//                             </svg>
//                         ) },
//                         { to: "/Admin/qlBinhLuan", label: "Quản lý bình luận", icon: (
//                             <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//                                 <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v8m-4-4h8" />
//                             </svg>
//                         ) },
//                         { to: "/Admin/qltrips", label: "Quản lý chuyến đi", icon: (
//                             <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//                                 <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 12l2 2 4-4" />
//                             </svg>
//                         ) },
//                         {
//                             to: "/Admin/qlDM", 
//                             label: "Quản lý danh mục", 
//                             icon: (
//                               <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//                                 <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 9l9-7 9 7v10a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V9z" />
//                               </svg>
//                         ) },
//                         {
//                                 to: "/Admin/qlBus", 
//                                 label: "Quản lý xe buýt", 
//                                 icon: (
//                                     <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//                                     <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 2h14a2 2 0 012 2v12a2 2 0 01-2 2H5a2 2 0 01-2-2V4a2 2 0 012-2zm3 14h8M7 18h10v2H7v-2z" />
//                                     </svg>
//                         )},
//                         {
//                                 to: "/Admin/qlPost", 
//                                 label: "Quản lý bài viết", 
//                                 icon: (
//                                     <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//                                     <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 2h14a2 2 0 012 2v12a2 2 0 01-2 2H5a2 2 0 01-2-2V4a2 2 0 012-2zm3 14h8M7 18h10v2H7v-2z" />
//                                     </svg>
//                         ),}
                          
//                     ].map((item, index) => (
//                         <li key={index}>
//                             <Link to={item.to} className="flex items-center py-2 px-4 hover:bg-gray-300 rounded-md rounded-lg bg-gray-700 hover:bg-gray-600 transition">
//                                 {item.icon}
//                                 {item.label}
//                             </Link>
//                         </li>
//                     ))}
//                 </ul>
//             </nav>
//         </div>
//     );
// };

// export default Sidebar;

import React from 'react';
import { Link } from 'react-router-dom';

const Sidebar = () => {
  const userName = localStorage.getItem("user_name"); // Lấy tên người dùng từ localStorage

  return (
    <div className="flex h-screen">
      <nav className="w-full  bg-gradient-to-b from-gray-800 to-gray-900 text-white max-h-screen py-6 px-4 shadow-lg overflow-y-auto">
        <div className="flex items-center space-x-5 mb-8">
          <img
            src="images/admin.jpg"
            alt="Admin Profile"
            className="w-12 h-12 rounded-full border-2 border-gray-700 shadow-md"
          />
          <div>
            <p className="font-semibold text-lg">{userName}</p>
            <Link
              to="/Admin/profileAdmin"
              className="text-sm text-gray-400 hover:text-gray-200 transition"
            >
              Xem hồ sơ
            </Link>
          </div>
        </div>
        <ul className="space-y-2">
          {[
             { to: "/admin", label: "Thống kê", icon: (
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 17a4 4 0 100-8 4 4 0 000 8zm-1 4h4m4-4h2a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2h2" />
                                            </svg>
                                        ) },
                                        { to: "/Admin/qlNguoidung", label: "Quản lý người dùng", icon: (
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5.121 17.804A4.992 4.992 0 0112 15a4.992 4.992 0 016.879 2.804M15 10a3 3 0 11-6 0 3 3 0 016 0z" />
                                            </svg>
                                        ) },
                                        { to: "/Admin/qlTuyenduong", label: "Quản lý tuyến đường", icon: (
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9.75 3v7.75H3v2.5h6.75V21h2.5v-7.75H21v-2.5h-8.75V3h-2.5z" />
                                            </svg>
                                        ) },
                                        { to: "/Admin/qlDatVe", label: "Quản lý vé xe", icon: (
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 10H5.5a2 2 0 01-2-2V5.5h16V8a2 2 0 01-2 2h-3m0 0v2a4 4 0 11-8 0v-2m8 0H9m0 0V8a2 2 0 012-2h2a2 2 0 012 2v2z" />
                                            </svg>
                                        ) },
                                        { to: "/Admin/qlThanhToan", label: "Quản lý thanh toán", icon: (
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3-.895 3-2-1.343-2-3-2zm9 4a9 9 0 10-18 0h18zm-9 6a9 9 0 01-9-9h18a9 9 0 01-9 9z" />
                                            </svg>
                                        ) },
                                        { to: "/Admin/qlBinhLuan", label: "Quản lý bình luận", icon: (
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v8m-4-4h8" />
                                            </svg>
                                        ) },
                                        { to: "/Admin/qltrips", label: "Quản lý chuyến đi", icon: (
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 12l2 2 4-4" />
                                            </svg>
                                        ) },
                                        {
                                            to: "/Admin/qlDM", 
                                            label: "Quản lý danh mục", 
                                            icon: (
                                              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 9l9-7 9 7v10a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V9z" />
                                              </svg>
                                        ) },
                                        {
                                                to: "/Admin/qlBus", 
                                                label: "Quản lý xe buýt", 
                                                icon: (
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 2h14a2 2 0 012 2v12a2 2 0 01-2 2H5a2 2 0 01-2-2V4a2 2 0 012-2zm3 14h8M7 18h10v2H7v-2z" />
                                                    </svg>
                                        )},
                                        {
                                          to: "/Admin/qlPost", 
                                          label: "Quản lý bài viết", 
                                          icon: (
                                              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 3H3v8h8V3zM21 13v8h-8v-8h8zM11 13H3v8h8v-8zM21 3h-8v8h8V3z" />
                                              </svg>
                                          ),
                                      }
                                      
          ].map((item, index) => (
            <li key={index}>
              <Link
                to={item.to}
                className="flex items-center py-3 px-6 hover:bg-gray-700 rounded-lg shadow-sm transition"
              >
                {item.icon}
                <span className="font-medium">{item.label}</span>
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
