
import React, { useState } from "react";
import { GoogleLogin } from "@react-oauth/google";
import FacebookLogin from "react-facebook-login";
import { Link, useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { IonIcon } from "react-ion-icon";
import TrangChu from "./Trangchu";
import { useSnackbar } from "notistack";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailFocused, setEmailFocused] = useState(false);
  const [passwordFocused, setPasswordFocused] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const handleFacebookLogin = async (response) => {
    if (response.accessToken) {
      try {
        const backendResponse = await fetch(
          "http://localhost:8000/api/facebook-login",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ token: response.accessToken }),
          }
        );
  
        if (backendResponse.ok) {
          const data = await backendResponse.json();
          localStorage.setItem("access_token", data.data.token);
          localStorage.setItem("user_email", data.data.user.email);
          localStorage.setItem("user_name", data.data.user.ten || "Facebook User");
  
          navigate("/");
        } else {
          setError("Đăng nhập Facebook thất bại.");
        }
      } catch (error) {
        setError("Lỗi đăng nhập.");
      }
    } else {
      setError("Đăng nhập Facebook thất bại");
    }
  };
  

  const handleGoogleLogin = async (response) => {
    if (!response.credential) {
      setError("Đăng nhập Google thất bại.");
      return;
    }
    try {
      const backendResponse = await fetch(
        "http://localhost:8000/api/google-login",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ token: response.credential }),
        }
      );

      if (backendResponse.ok) {
        const data = await backendResponse.json();
        localStorage.setItem("access_token", data.data.token);
        localStorage.setItem("user_email", data.data.user.email);
        localStorage.setItem("user_name", data.data.user.ten || "Google User");
        enqueueSnackbar('Đăng nhập thành công!', { variant: 'success' ,anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          autoHideDuration: 2000, // Thời gian hiển thị (ms)
        });

        navigate("/");
      } else {
        setError("Đăng nhập Google thất bại.");
      }
    } catch (error) {
      setError("Lỗi đăng nhập.");
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setError("");

    try {
      const response = await fetch("http://localhost:8000/api/login", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, password }),
      });

      if (response.ok) {
        const data = await response.json();

        // Lưu token và thông tin người dùng vào localStorage
        localStorage.setItem("access_token", data.access_token);

        const userResponse = await fetch("http://localhost:8000/api/user", {
          method: "GET",
          headers: { Authorization: `Bearer ${data.access_token}` },
        });

        if (userResponse.ok) {
          const userData = await userResponse.json();

          localStorage.setItem("user_name", userData.user.ten);
          localStorage.setItem("user_email", userData.user.email);
          localStorage.setItem("vaitro", userData.user.vaitro);

          enqueueSnackbar("Đăng nhập thành công!", {
            variant: "success",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            autoHideDuration: 2000,
          });

          // Kiểm tra vai trò và chuyển hướng
          if (userData.user.vaitro === 1) {
            navigate("/admin"); // Admin
          } else {
            navigate("/"); // Người dùng thường
          }
        } else {
          setError("Không thể tải thông tin người dùng.");
        }
      } else {
        const errorData = await response.json();
        setError(errorData.message || "Đăng nhập thất bại.");
      }
    } catch (err) {
      setError("Đăng nhập thất bại.");
    }
  };
  return (
    <>
      <TrangChu />
      <div id="loginForm" className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
        <div className="wrapper-login relative flex w-[500px] h-[600px] justify-center bg-white rounded-2xl shadow-lg overflow-hidden">
          {/* Close Button Icon */}
          <Link
            to="/"
            className="icon absolute text-center top-0 p-1 right-0 w-11 h-11 cursor-pointer bg-white rounded-bl-[20px] z-10  hover:bg-red-600 text-2xl text-[#162938] hover:text-white transition-all duration-300"
          >
            &times;
          </Link>

          <div className="form-box login p-16 w-full">
            <h2 className="text-3xl text-center text-[#162938] mb-6">
              Đăng nhập
            </h2>
            <form onSubmit={handleLogin}>
              {/* Email Input */}
              <div className="input-box relative mb-9 w-full h-[50px]">
                <span className="icon absolute right-8 top-15 text-[#162938] lg-57">
                  <IonIcon name="mail" />
                </span>

                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full h-12 border-b-2 border-[#162938] focus:border-[#28a745] bg-transparent outline-none text-[#162938] font-semibold pl-2"
                  onFocus={() => setEmailFocused(true)}
                  onBlur={(e) => setEmailFocused(e.target.value !== "")}
                />
                <label
                  className={`absolute left-2 transition-all duration-500 ${
                    emailFocused ? "-top-2 text-sm" : "top-2 text-base"
                  } text-[#162938]`}
                >
                  Email
                </label>
              </div>

              {/* Password Input */}
              <div className="input-box relative mb-8 w-full h-[50px]">
                <span className="icon absolute right-8 top-15 text-[#162938] lg-57">
                  <IonIcon name="lock-closed" />
                </span>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full h-12 border-b-2 border-[#162938] focus:border-[#28a745] bg-transparent outline-none text-[#162938] font-semibold pl-2"
                  onFocus={() => setPasswordFocused(true)}
                  onBlur={(e) => setPasswordFocused(e.target.value !== "")}
                />
                <label
                  className={`absolute left-2 transition-all duration-500 ${
                    passwordFocused ? "-top-2 text-sm" : "top-2 text-base"
                  } text-[#162938]`}
                >
                  Mật khẩu
                </label>
              </div>
              <div className="flex justify-between items-center mb-4">
                {/* Ghi nhớ mật khẩu */}
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="remember-me"
                    className="w-4 h-4 border-[#162938] rounded-md"
                    // checked={rememberMe}
                    // onChange={(e) => setRememberMe(e.target.checked)}
                  />
                  <label
                    htmlFor="remember-me"
                    className="ml-2 text-[#162938] text-sm"
                  >
                    Ghi nhớ mật khẩu
                  </label>
                </div>

                {/* Quên mật khẩu */}
                <Link
                  to="/forgot-password"
                  className="text-green-600 hover:underline text-sm"
                >
                  Quên mật khẩu?
                </Link>
              </div>

              {/* Error Message */}
              {error && (
                <p className="text-red-500 text-center mb-4">{error}</p>
              )}

              {/* Login Button */}
              <button
                type="submit"
                className="w-full h-12 bg-[#28a745] text-white font-medium rounded-lg hover:bg-[#207d3a]"
              >
                Đăng nhập
              </button>
            </form>

            {/* Social Login Buttons */}
            <div className="mt-4 flex justify-center">
              <span>HOẶC</span>
            </div>

            <div className="flex justify-center mt-4 space-x-4">
              {/* <FacebookLogin
                appId={"1497019844342354"}
                autoLoad={true}
                fields="name,email,picture"
                callback={handleFacebookLogin}
                textButton="Facebook"
                cssClass="social-button facebook-button flex items-center p-2 border border-[#28a745] rounded-lg bg-white text-[#28a745]"
              /> */}
              <GoogleLogin
                onSuccess={handleGoogleLogin}
                onError={() => setError("Đăng nhập Google thất bại")}
                className="social-button google-button flex items-center p-2 text-sm border-[#28a745] rounded-lg bg-white text-[#28a745]"
              />
            </div>

            {/* Register and Forgot Password Links */}
            <div className="mt-4 flex justify-center text-sm text-[#162938]">
              <p>Bạn chưa có tài khoản?</p>
              <Link
                to="/register"
                className="text-green-600 hover:underline ml-1"
              >
                Đăng ký ngay!
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
