import React, { useEffect, useState } from "react";
import axios from "axios";
import Sidebar from "../Components/SideBar";
import Header from "../Components/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faRedo } from "@fortawesome/free-solid-svg-icons";
import { faTrash, faEdit, faUndo} from '@fortawesome/free-solid-svg-icons';
function CateManage() {
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [categoryDetails, setCategoryDetails] = useState({});
  const [openDropdowns, setOpenDropdowns] = useState({});
  const [showAddDetailPopup, setShowAddDetailPopup] = useState(false);
  const [showEditDetailPopup, setShowEditDetailPopup] = useState(false); // State for edit popup
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [deletedCategories, setDeletedCategories] = useState([]); // Danh sách các danh mục đã xóa
  const [deletedCategoryDetails, setDeletedCategoryDetails] = useState({}); // Chi tiết của từng danh mục đã xóa
  const [showAddCategoryPopup, setShowAddCategoryPopup] = useState(false);
  const [openDeleteDropdowns, setOpenDeleteDropdowns] = useState({}); // Trạng thái mở/đóng dropdown cho danh mục đã xóa
  const [showUpdateCategoryPopup, setShowUpdateCategoryPopup] = useState(false);
  const [newDetail, setNewDetail] = useState({
    category_id: "",
    code: "",
    manual_code: "",
    name: "",
    description: "",
    manual_code: "",
    an_hien: 1,
  });
  const [newCategory, setNewCategory] = useState({
    code: "",
    name: "",
    description: "",
    order_id: 1,
    an_hien: 1
  });
  const handleAddCategory = () => {
    const accessToken = localStorage.getItem("access_token"); // Lấy token từ localStorage hoặc từ nơi bạn lưu trữ
    // Nếu không có token, có thể thông báo lỗi hoặc yêu cầu người dùng đăng nhập
    if (!accessToken) {
      alert("Vui lòng đăng nhập.");
      return;
    }
    axios
      .post(
        "http://localhost:8000/api/category",
        newCategory,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`, // Thêm token vào header
          },
        }
      )
      .then((response) => {
        // Cập nhật lại danh sách danh mục sau khi thêm thành công
        setCategories((prevCategories) => [...prevCategories, response.data]);
        setShowAddCategoryPopup(false); // Đóng popup
      })
      .catch((error) => {
        console.error("Error adding category:", error);
        if (error.response) {
          alert(`Error: ${error.response.data.message || "Invalid data."}`);
        }
      });
  };
  const handleDeleteCategory = (categoryId) => {
    const accessToken = localStorage.getItem("access_token");
    // Xác nhận xóa danh mục
    if (window.confirm("Bạn có chắc chắn muốn xóa danh mục này?")) {
      axios
        .delete(`http://localhost:8000/api/category/${categoryId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`, // Thêm token xác thực nếu cần
          },
        })
        .then((response) => {
          // Cập nhật lại danh sách danh mục sau khi xóa thành công
          setCategories((prevCategories) =>
            prevCategories.filter((category) => category.id !== categoryId)
          );
          alert("Danh mục đã được xóa thành công!");
        })
        .catch((error) => {
          console.error("Error deleting category:", error);
          if (error.response) {
            alert(`Error: ${error.response.data.message || "Có lỗi xảy ra."}`);
          }
        });
    }
  };
  const handleShowUpdateCategoryPopup = (category) => {
    setNewCategory({
      id: category.id,
      code: category.code,
      name: category.name,
      description: category.description,
      order_id: category.order_id,
      an_hien: category.an_hien,
    });
    setShowUpdateCategoryPopup(true); // Mở popup cập nhật
  };
  
  const handleUpdateCategory = () => {
    const accessToken = localStorage.getItem("access_token");
    axios
      .put(`http://localhost:8000/api/category/${newCategory.id}`, newCategory, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        const updatedCategory = response.data; // Đảm bảo API trả về danh mục đã được cập nhật
        // Cập nhật danh sách categories trong state
        setCategories((prevCategories) =>
          prevCategories.map((category) =>
            category.id === updatedCategory.id ? updatedCategory : category
          )
        );
        setShowUpdateCategoryPopup(false); // Đóng popup
        alert("Cập nhật danh mục thành công!");
      })
      .catch((error) => {
        console.log("Dữ liệu gửi đi:", newCategory);
        console.error("Lỗi khi cập nhật danh mục:", error);
        if (error.response) {
          alert(`Error: ${error.response.data.message || "Invalid data."}`);
        }
      });
  };
  
  const [editingDetail, setEditingDetail] = useState({}); // State for the detail being edited
// Cấu hình interceptor để tự động thêm access_token vào header
axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("access_token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);
  // Fetch all categories on component mount
  useEffect(() => {
    axios
      .get("http://localhost:8000/api/category")
      .then((response) => {
        setCategories(response.data);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, []);

  // Fetch details for a specific category
  const fetchCategoryDetails = (categoryId) => {
    if (categoryDetails[categoryId]) {
      setOpenDropdowns((prev) => ({
        ...prev,
        [categoryId]: !prev[categoryId],
      }));
      return;
    }

    axios
      .get(`http://localhost:8000/api/category/${categoryId}/details`)
      .then((response) => {
        setCategoryDetails((prev) => ({
          ...prev,
          [categoryId]: response.data,
        }));
        setOpenDropdowns((prev) => ({
          ...prev,
          [categoryId]: true,
        }));
      })
      .catch((error) => {
        console.error(`Error fetching details for category ${categoryId}:`, error);
      });
  };

  // Handle opening add detail popup
  const handleShowAddDetailPopup = (categoryId) => {
    setSelectedCategoryId(categoryId);
    setNewDetail({
      name: "",
      description: "",
      an_hien: 1, // Default to "Visible"
    });
    setShowAddDetailPopup(true);
  };
  

  // Handle opening edit detail popup
  const handleShowEditDetailPopup = (detail) => {
    console.log(detail)
    setSelectedCategoryId(detail.category_id);
    setEditingDetail(detail); // Set the detail being edited
    setShowEditDetailPopup(true); // Show the edit popup
  };

  // Handle form input changes
  const handleDetailInputChange = (e) => {
    const { name, value } = e.target;
    setNewDetail((prev) => ({ ...prev, [name]: value }));
  };

  const handleEditDetailInputChange = (e) => {
    const { name, value } = e.target;
    setEditingDetail((prev) => ({ ...prev, [name]: value }));
  };

  // Submit new detail to API
const handleAddDetail = () => {
  if (!selectedCategoryId) return;

  axios
    .post(
      `http://localhost:8000/api/category/${selectedCategoryId}/details`,
      newDetail
    )
    .then((response) => {
      setCategoryDetails((prev) => ({
        ...prev,
        [selectedCategoryId]: [...(prev[selectedCategoryId] || []), response.data],
      }));
      setShowAddDetailPopup(false);
       
    })
    .catch((error) => {
      console.error("Error adding detail:", error);
      if (error.response) {
        alert(`Error: ${error.response.data.message || "Invalid data."}`);
      }
    });
};


const handleEditDetail = () => {
  if (!selectedCategoryId) {
    alert("Category ID is not selected. Please try again.");
    return;
  }

  // Tiến hành gọi API nếu selectedCategoryId hợp lệ
  axios
    .put(
      `http://localhost:8000/api/category/${selectedCategoryId}/details/${editingDetail.id}`,
      {
        name: editingDetail.name,
        description: editingDetail.description,
        code: editingDetail.code,
        manual_code: editingDetail.manual_code,
        order_id: editingDetail.order_id,
        an_hien: editingDetail.an_hien,
      }
    )
    .then((response) => {
      setCategoryDetails((prev) => {
        const updatedDetails = prev[selectedCategoryId].map((detail) =>
          detail.id === editingDetail.id ? response.data : detail
        );
        return { ...prev, [selectedCategoryId]: updatedDetails };
      });
      setShowEditDetailPopup(false);
      window.location.reload();
    })
    .catch((error) => {
      console.error("Error editing detail:", error);
      if (error.response) {
        alert(`Error: ${error.response.data.message || "Invalid data."}`);
      }
    });
};



const handleDeleteDetail = (categoryId, detailId) => {
  axios
    .delete(`http://localhost:8000/api/category/${categoryId}/details/${detailId}`)
    .then(() => {
      // Remove the deleted detail from the state
      setCategoryDetails((prev) => {
        const updatedDetails = prev[categoryId].filter((detail) => detail.id !== detailId);
        return { ...prev, [categoryId]: updatedDetails };
      });
    })
    .catch((error) => {
      console.error("Error deleting detail:", error);
      if (error.response) {
        alert(`Error: ${error.response.data.message || "Unable to delete."}`);
      } else {
        alert("An unexpected error occurred.");
      }
    });
};



  // Lấy danh sách danh mục đã xóa khi component được mount
  useEffect(() => {
    axios
      .get("http://localhost:8000/api/category") // API lấy danh sách danh mục đã xóa
      .then((response) => {
        console.log(response.data)
        setDeletedCategories(response.data); // Cập nhật danh sách danh mục vào state
      })
      .catch((error) => {
        console.error("Error fetching deleted categories:", error);
      });
  }, []);

  // Lấy chi tiết cho một danh mục đã xóa
const fetchDeletedCategoryDetails = (categoryId) => {
    // Nếu chi tiết đã được load trước đó, không cần fetch lại
    if (deletedCategoryDetails[categoryId]) return;

    axios
      .get(`http://localhost:8000/api/category/${categoryId}/detailsDelete`) // API lấy chi tiết danh mục đã xóa
      .then((response) => {
        console.log("Fetched data for deleted category:", categoryId, response.data); // Log dữ liệu

        // Cập nhật state với dữ liệu mới
         setDeletedCategoryDetails((prev) => ({
          ...prev,
          [categoryId]: response.data,
        }));
        setOpenDeleteDropdowns((prev) => ({
          ...prev,
          [categoryId]: true,
        }));
      })
      .catch((error) => {
        console.error(`Error fetching details for deleted category ${categoryId}:`, error);
      });
  };

const handleRestoreDetail = (categoryId, detailId) => {
  axios
    .patch(`http://localhost:8000/api/category/${categoryId}/details/${detailId}/restore`)
    .then((response) => {
      // Cập nhật lại chi tiết sau khi khôi phục
      alert("Detail restored successfully!");
      // Bạn có thể thêm logic để cập nhật state hoặc re-fetch dữ liệu nếu cần.
      window.location.reload()
    })
    .catch((error) => {
      console.error("Error restoring detail:", error);
      if (error.response) {
        alert(`Error: ${error.response.data.message || "Unable to restore."}`);
      } else {
        alert("An unexpected error occurred.");
      }
    });
};



  return (
    <>
    <div className="flex h-screen">
      <Sidebar />
      <div className="bg-gray-100 flex-1 flex flex-col">
        <Header />
        
      <div className="container mx-auto p-4 overflow-y-auto">
        <h1 className="text-2xl font-bold mb-4">Category Management</h1>
        {/* <button
        className="text-lg font-semibold text-center mb-4 bg-blue-500 text-white py-1.5 px-3 rounded hover:bg-blue-700 flex items-center justify-center gap-2" // Thay đổi text-lg và py-1.5, px-3 để làm nút nhỏ hơn
        onClick={() => setShowAddCategoryPopup(true)}
      >
        <i className="fas fa-plus h-5 w-5"></i>
        Tạo Danh Mục Mới
      </button> */}
      {showAddCategoryPopup && (
  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
    <div className="bg-white p-6 rounded-lg shadow-lg w-96">
      <h2 className="text-lg font-bold mb-4">Tạo Danh Mục Mới</h2>
      <form>
        {/* Trường Tên Danh Mục */}
        <div className="mb-3">
          <label className="block text-sm font-medium text-gray-700">Tên Danh Mục</label>
          <input
            type="text"
            name="name"
            value={newCategory.name}
            onChange={(e) => setNewCategory({ ...newCategory, name: e.target.value })}
            className="w-full border rounded px-3 py-2"
          />
        </div>

        {/* Trường Mô Tả */}
        <div className="mb-3">
          <label className="block text-sm font-medium text-gray-700">Mô Tả</label>
          <textarea
            name="description"
            value={newCategory.description}
            onChange={(e) => setNewCategory({ ...newCategory, description: e.target.value })}
            className="w-full border rounded px-3 py-2"
          ></textarea>
        </div>

        {/* Trường Order ID */}
        <div className="mb-3">
          <label className="block text-sm font-medium text-gray-700">Order ID</label>
          <input
            type="number"
            name="order_id"
            value={newCategory.order_id}
            onChange={(e) => setNewCategory({ ...newCategory, order_id: e.target.value })}
            className="w-full border rounded px-3 py-2"
          />
        </div>

        {/* Trường Hiển Thị/Ẩn */}
        <div className="mb-3">
          <label className="inline-flex items-center text-sm font-medium text-gray-700">
            <input
              type="checkbox"
              name="an_hien"
              checked={newCategory.an_hien}
              onChange={(e) => setNewCategory({ ...newCategory, an_hien: e.target.checked })}
              className="form-checkbox"
            />
            <span className="ml-2">Hiển thị</span>
          </label>
        </div>

        {/* Trường Mã Danh Mục */}
        <div className="mb-3">
          <label className="block text-sm font-medium text-gray-700">Mã Danh Mục (Code)</label>
          <input
            type="text"
            name="code"
            value={newCategory.code}
            onChange={(e) => setNewCategory({ ...newCategory, code: e.target.value })}
            className="w-full border rounded px-3 py-2"
          />
        </div>

        {/* Button Hủy và Thêm */}
        {/* <div className="flex justify-end mt-4">
          <button
            type="button"
            className="py-2 px-4 bg-gray-500 text-white rounded-lg mr-2"
            onClick={() => setShowAddCategoryPopup(false)} // Đóng popup
          >
            Hủy
          </button>
          <button
            type="button"
            className="py-2 px-4 bg-blue-500 text-white rounded-lg"
            onClick={handleAddCategory} // Gửi yêu cầu thêm danh mục
          >
            Thêm
          </button>
        </div> */}
      </form>
    </div>
  </div>
)}
              {showUpdateCategoryPopup && (
  <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center z-50">
    <div className="bg-white p-6 rounded-lg shadow-lg w-96">
      <h2 className="text-xl font-semibold mb-4">Cập nhật danh mục</h2>
      <form onSubmit={handleUpdateCategory}>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Mã danh mục:</label>
          <input
            type="text"
            name="code"
            value={newCategory.code} // Hiển thị giá trị của mã danh mục
            onChange={(e) => setNewCategory({ ...newCategory, code: e.target.value })}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Tên danh mục:</label>
          <input
            type="text"
            name="name"
            value={newCategory.name} // Hiển thị giá trị của tên danh mục
            onChange={(e) => setNewCategory({ ...newCategory, name: e.target.value })}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Mô tả:</label>
          <textarea
            name="description"
            value={newCategory.description} // Hiển thị giá trị của mô tả
            onChange={(e) => setNewCategory({ ...newCategory, description: e.target.value })}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Trạng thái:</label>
          <select
            name="an_hien"
            value={newCategory.an_hien} // Hiển thị giá trị của trạng thái
            onChange={(e) => setNewCategory({ ...newCategory, an_hien: parseInt(e.target.value) })}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
          >
            <option value={1}>Hiển thị</option>
            <option value={0}>Ẩn</option>
          </select>
        </div>

        <div className="flex justify-end space-x-4">
          <button
            type="submit"
            className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          >
            Cập nhật
          </button>
          <button
            type="button"
            onClick={() => setShowUpdateCategoryPopup(false)}
            className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500"
          >
            Hủy
          </button>
        </div>
      </form>
    </div>
  </div>
)}



        <table className="table-auto w-full border-collapse border border-gray-300">
          <thead>
            <tr className="bg-gray-200">
              <th className="border border-gray-300 px-4 py-2">ID</th>
              <th className="border border-gray-300 px-4 py-2">Category</th>
              <th className="border border-gray-300 px-4 py-2">Mô tả</th>
              <th className="border border-gray-300 px-4 py-2">Details</th>
              <th className="border border-gray-300 px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {categories.length > 0 ? (
              categories.map((category) => (
                <React.Fragment key={category.id}>
                  <tr className="hover:bg-gray-100">
                  <td className="border border-gray-300 px-4 py-2">{category.id}</td>
                    <td className="border border-gray-300 px-4 py-2">{category.name}</td>
                    <td className="border border-gray-300 px-4 py-2">{category.description}</td>
                    <td
                      className="border border-gray-300 px-4 py-2 text-center cursor-pointer"
                      onClick={() => fetchCategoryDetails(category.id)}
                    >
                      {openDropdowns[category.id] ? "▲" : "▼"}
                    </td>
                    <td className="border border-gray-300 px-4 py-2 text-center align-middle">
                    <div className="flex items-center justify-start gap-4 space-x-4">
                      {/* Nút Add Detail */}
                    <button
                        className="flex items-center justify-center px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400"
                        onClick={() => handleShowAddDetailPopup(category.id)}
                      >
                        <FontAwesomeIcon icon={faPlus} className="w-5 h-5" />
                      <span>Add Detail</span>
                    </button>

                      {/* Nút Xóa */}
                      {/* <button
                        className="flex items-center justify-center px-4 py-2 bg-red-500 text-white border border-red-500 rounded-lg hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400"
                        onClick={() => handleDeleteCategory(category.id)} // Gọi hàm xóa khi nhấn
                      >
                        <i className="fas fa-trash-alt w-5 h-5"></i>
                      </button> */}
                      {/* Nút Cập Nhật Danh Mục với Icon */}
                      {/* <button
                        className="flex items-center justify-center px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400"
                        onClick={() => handleShowUpdateCategoryPopup(category)} // Mở popup cập nhật danh mục
                      >
                        <i className="fas fa-edit w-5 h-5"></i>
                      </button> */}
                    </div>
                    </td>
                  </tr>
                  
                  {openDropdowns[category.id] && (
                    <tr>
                      <td colSpan="5" className="border border-gray-300 px-4 py-2">
                        {categoryDetails[category.id]?.length ? (
                          <table className="table-auto w-full border-collapse border border-gray-300">
                            <thead>
                              <tr className="bg-gray-100">
                                <th className="border border-gray-300 px-4 py-2">ID</th>
                                <th className="border border-gray-300 px-4 py-2">Name</th>
                                <th className="border border-gray-300 px-4 py-2">Description</th>
                                <th className="border border-gray-300 px-4 py-2">An Hien</th>
                                <th className="border border-gray-300 px-4 py-2">Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {categoryDetails[category.id].map((detail) => (
                                <tr key={detail.id}>
                                  <td className="border border-gray-300 px-4 py-2">{detail.id}</td>
                                  <td className="border border-gray-300 px-4 py-2">{detail.name}</td>
                                  <td className="border border-gray-300 px-4 py-2">{detail.description}</td>
                                  <td className="border border-gray-300 px-4 py-2">
                                    {detail.an_hien ? "Hiện" : "Ẩn"}
                                  </td>
                                  <td className="border border-gray-300 px-4 py-2 text-center align-middle">
                                  <div className="flex justify-center items-center gap-4">
                                  <button
                                    className="p-2 bg-yellow-500 text-white rounded-lg hover:bg-blue-600"
                                    onClick={() => handleShowEditDetailPopup(detail)} // Open edit popup
                                  >
                                    <FontAwesomeIcon icon={faEdit} className="w-5 h-5" />
                                  </button>
                                  <button
                                    className="p-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
                                    onClick={() => handleDeleteDetail(category.id, detail.id)}
                                  >
                                    <FontAwesomeIcon icon={faTrash} className="w-5 h-5" />
                                  </button>
                                </div>

                                </td>

                                </tr>
                              ))}
                            </tbody>
                          </table>
                        ) : (
                          <p className="text-gray-500">No details available</p>
                        )}
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))
            ) : (
              <tr>
                <td colSpan="3" className="text-center py-4">
                  No categories available
                </td>
              </tr>
            )}
          </tbody>
        </table>


             {/* Danh sách danh mục đã xoá*/}
      <div className="p-4">
      <h1 className="text-2xl font-semibold text-gray-700 mb-4">Danh sách danh mục đã xóa</h1>


      <table className="table-auto w-full border-collapse border border-gray-300">
      <thead>
        <tr className="bg-gray-200">
          <th className="border border-gray-300 px-4 py-2">ID</th>
          <th className="border border-gray-300 px-4 py-2">Category</th>
          <th className="border border-gray-300 px-4 py-2">Description</th>
          <th className="border border-gray-300 px-4 py-2">Details</th>
         
        </tr>
      </thead>
      <tbody>
        {categories.length > 0 ? (
          categories.map((category) => (
            <React.Fragment key={category.id}>
              <tr className="hover:bg-gray-100">
                <td className="border border-gray-300 px-4 py-2">{category.id}</td>
                <td className="border border-gray-300 px-4 py-2">{category.name}</td>
                <td className="border border-gray-300 px-4 py-2">{category.description}</td>
                <td
                  className="border border-gray-300 px-4 py-2 text-center cursor-pointer"
                  onClick={() => fetchDeletedCategoryDetails(category.id)}
                >
                  {openDeleteDropdowns[category.id] ? "▲" : "▼"}
                </td>
                
              </tr>
              {/* Hiển thị chi tiết nếu dropdown được mở */}
              {openDeleteDropdowns[category.id] && (
                <tr>
                  <td colSpan="5" className="border border-gray-300 px-4 py-2">
                    {deletedCategoryDetails[category.id]?.length > 0 ? (
                      <table className="table-auto w-full border-collapse border border-gray-300">
                        <thead>
                          <tr className="bg-gray-100">
                            <th className="border border-gray-300 px-4 py-2">ID</th>
                            <th className="border border-gray-300 px-4 py-2">Name</th>
                            <th className="border border-gray-300 px-4 py-2">Description</th>
                            <th className="border border-gray-300 px-4 py-2">An Hien</th>
                            <th className="border border-gray-300 px-4 py-2">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {deletedCategoryDetails[category.id].map((detail) => (
                            <tr key={detail.id}>
                              <td className="border border-gray-300 px-4 py-2">{detail.id}</td>
                              <td className="border border-gray-300 px-4 py-2">{detail.name}</td>
                              <td className="border border-gray-300 px-4 py-2">{detail.description}</td>
                              <td className="border border-gray-300 px-4 py-2">
                                {detail.an_hien ? "Hiện" : "Ẩn"}
                              </td>
                              <td className="border border-gray-300 px-4 py-2 text-center">
                              <button
                        className="bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600 flex items-center justify-center"
                        onClick={() => handleRestoreDetail(category.id,detail.id)}
                      >
                        <i className="fas fa-undo"></i>
                      </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <p className="text-gray-500">No details available</p>
                    )}
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))
        ) : (
          <tr>
            <td colSpan="5" className="text-center py-4">
              No categories available
            </td>
          </tr>
        )}
      </tbody>
    </table>


      </div>

      </div>

      {/* Add Detail Popup */}
      {showAddDetailPopup && (
  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
    <div className="bg-white p-6 rounded-lg shadow-lg w-96">
      <h2 className="text-lg font-bold mb-4">Add Detail</h2>
      <form>
        <div className="mb-3">
          <label className="block text-sm font-medium text-gray-700">Name</label>
          <input
            type="text"
            name="name"
            value={newDetail.name}
            onChange={handleDetailInputChange}
            className="w-full border rounded px-3 py-2"
          />
        </div>
        <div className="mb-3">
          <label className="block text-sm font-medium text-gray-700">Code</label>
          <input
            type="text"
            name="code"
            value={newDetail.code}
            onChange={handleDetailInputChange}
            className="w-full border rounded px-3 py-2"
          />
        </div>
        <div className="mb-3">
          <label className="block text-sm font-medium text-gray-700">Manual Code</label>
          <input
            type="text"
            name="manual_code"
            value={newDetail.manual_code}
            onChange={handleDetailInputChange}
            className="w-full border rounded px-3 py-2"
          />
        </div>
        <div className="mb-3">
          <label className="block text-sm font-medium text-gray-700">Description</label>
          <textarea
            name="description"
            value={newDetail.description}
            onChange={handleDetailInputChange}
            className="w-full border rounded px-3 py-2"
          ></textarea>
        </div>
        <div className="mb-3">
          <label className="block text-sm font-medium text-gray-700">Order ID</label>
          <input
            type="number"
            name="order_id"
            value={newDetail.order_id}
            onChange={handleDetailInputChange}
            className="w-full border rounded px-3 py-2"
          />
        </div>
        <div className="mb-3">
          <label className="block text-sm font-medium text-gray-700">An Hien</label>
          <select
            name="an_hien"
            value={newDetail.an_hien}
            onChange={handleDetailInputChange}
            className="w-full border rounded px-3 py-2"
          >
            <option value={1}>Hiện</option>
            <option value={0}>Ẩn</option>
          </select>
        </div>
        <div className="flex justify-end mt-4">
          <button
            type="button"
            className="py-2 px-4 bg-gray-500 text-white rounded-lg mr-2"
            onClick={() => setShowAddDetailPopup(false)}
          >
            Cancel
          </button>
          <button
            type="button"
            className="py-2 px-4 bg-blue-500 text-white rounded-lg"
            onClick={handleAddDetail}
          >
            Add
          </button>
        </div>
      </form>
    </div>
  </div>
)}


      {/* Edit Detail Popup */}
      {showEditDetailPopup && (
  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
    <div className="bg-white p-6 rounded-lg shadow-lg w-96">
      <h2 className="text-lg font-bold mb-4">Edit Detail</h2>
      <form>
        <div className="mb-3">
          <label className="block text-sm font-medium text-gray-700">Name</label>
          <input
            type="text"
            name="name"
            value={editingDetail.name}
            onChange={handleEditDetailInputChange}
            className="w-full border rounded px-3 py-2"
          />
        </div>
        <div className="mb-3">
          <label className="block text-sm font-medium text-gray-700">Code</label>
          <input
            type="text"
            name="code"
            value={editingDetail.code}
            onChange={handleEditDetailInputChange}
            className="w-full border rounded px-3 py-2"
          />
        </div>
        <div className="mb-3">
          <label className="block text-sm font-medium text-gray-700">Manual Code</label>
          <input
            type="text"
            name="manual_code"
            value={editingDetail.manual_code}
            onChange={handleEditDetailInputChange}
            className="w-full border rounded px-3 py-2"
          />
        </div>
        <div className="mb-3">
          <label className="block text-sm font-medium text-gray-700">Description</label>
          <textarea
            name="description"
            value={editingDetail.description}
            onChange={handleEditDetailInputChange}
            className="w-full border rounded px-3 py-2"
          ></textarea>
        </div>
        <div className="mb-3">
          <label className="block text-sm font-medium text-gray-700">Order ID</label>
          <input
            type="number"
            name="order_id"
            value={editingDetail.order_id}
            onChange={handleEditDetailInputChange}
            className="w-full border rounded px-3 py-2"
          />
        </div>
        <div className="mb-3">
          <label className="block text-sm font-medium text-gray-700">An Hien</label>
          <select
            name="an_hien"
            value={editingDetail.an_hien}
            onChange={handleEditDetailInputChange}
            className="w-full border rounded px-3 py-2"
          >
            <option value={1}>Hiện</option>
            <option value={0}>Ẩn</option>
          </select>
        </div>
        <div className="flex justify-end mt-4">
          <button
            type="button"
            className="py-2 px-4 bg-gray-500 text-white rounded-lg mr-2"
            onClick={() => setShowEditDetailPopup(false)}
          >
            Cancel
          </button>
          <button
            type="button"
            className="py-2 px-4 bg-blue-500 text-white rounded-lg"
            onClick={handleEditDetail}
          >
            Save
          </button>
        </div>
      </form>
    </div>
  </div>
)}
    </div>
    </div>
    </>
  );
}

export default CateManage;
