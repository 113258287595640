import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoute = ({ roleRequired }) => {
  const accessToken = localStorage.getItem("access_token");
  const userRole = localStorage.getItem("vaitro");

  if (!accessToken) {
    // Nếu chưa đăng nhập, chuyển hướng đến trang đăng nhập
    return <Navigate to="/login" />;
  }

  if (roleRequired && userRole !== roleRequired) {
    // Nếu vai trò không phù hợp, chuyển hướng đến trang không được phép
    return <Navigate to="/not-authorized" />;
  }

  return <Outlet />; // Hiển thị nội dung của route được bảo vệ
};

export default ProtectedRoute;
