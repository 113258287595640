import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

const BusList = () => {
  const [trips, setTrips] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch the trips data from your Laravel API
    axios
      .get('http://localhost:8000/api/trips')
      .then((response) => {
        setTrips(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('There was an error fetching the trips!', error);
        setLoading(false);
      });
  }, []);


  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <section className="bus-list w-full max-w-[2000px] bg-gradient-to-br from-white to-gray-200 p-12 shadow-2xl rounded-lg overflow-y-auto">
        <h2 className="text-center text-4xl mb-12 text-gray-800">Danh sách xe buýt tại BusFree</h2>
        <table className="w-full border-separate border-spacing-0 bg-white shadow-md rounded-xl overflow-hidden">
          <thead className="bg-green-600 text-white">
            <tr>
              <th className="p-5 text-lg">Tên Xe</th>
              <th className="p-5 text-lg">Biển số xe</th>
              <th className="p-5 text-lg">Hình ảnh</th>
              <th className="p-5 text-lg">Địa điểm đến</th>
              <th className="p-5 text-lg">Địa điểm kết thúc</th>
              <th className="p-5 text-lg">Trạng thái</th>
              <th className="p-5 text-lg">Hành động</th>
            </tr>
          </thead>
          <tbody>
            {trips.map((trip) => (
              <tr key={trip.idchuyen} className="transition-transform duration-300 bg-gray-50 hover:bg-white">
                <td className="p-6 text-center text-green-600 font-semibold">{trip.chitietxebuss?.tenxe}</td>
                <td className="p-6 text-center text-gray-600">{trip.chitietxebuss?.biensoxe}</td>
                <td className="p-6 text-center">
                  <div className="relative inline-block w-32 h-20">
                    <img
                      src={trip.chitietxebuss?.image}
                      className="w-full h-full object-cover rounded-lg border-2 border-gray-200 shadow-lg transform transition-transform duration-300 hover:scale-110 hover:border-green-600"
                    />
                  </div>
                </td>
                <td className="p-6 text-center text-gray-600">{trip.chitiettuyenduong?.noixuatphat}</td>
                <td className="p-6 text-center text-gray-600">{trip.chitiettuyenduong?.noiden}</td>
                <td className="p-6 text-center text-gray-600">{trip.chitietxebuss?.trangthai}</td>
                <td className="p-6 text-center">
                  <Link to={`/trips/${trip.idchuyen}`}>
                  <button className="flex items-center gap-2 bg-green-600 text-white px-4 py-2 rounded-lg shadow-md transform transition-all duration-300 hover:bg-green-700 hover:shadow-lg active:bg-green-800 active:scale-95">
                    <i className="fas fa-info-circle"></i> {/* Icon Font Awesome hoặc tùy chỉnh */}
                    Chi tiết
                  </button>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>
    </>
  );
};

export default BusList;
