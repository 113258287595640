// import React from 'react';

// const SupportForm = () => {
//   return (
//     <section className="support-form-section bg-white p-10 my-5 max-w-[1200px] mx-auto rounded-lg shadow-lg">
//       <div className="form-container flex gap-5 flex-wrap">
        
//         {/* Left Column - Image */}
//         <div className="form-image">
//           <img 
//             src="/images/online-assistant-the-guy-sits-at-the-computer-communicates-with-clients-through-headphones-and-responds-to-messages-working-from-home-training-and-solving-everyday-problems-with-client.jpg" 
//             alt="Support Image" 
//             className="w-[600px] h-[600px] rounded-lg" 
//           />
//         </div>

//         {/* Right Column - Form */}
//         <div className="form-content flex flex-col justify-center w-[500px]">
//           <h2 className="text-2xl text-[#2c3e50] mb-2">Liên hệ với chúng tôi</h2>
//           <p className="text-lg mb-5 text-[#7f8c8d]">Đặt câu hỏi hoặc yêu cầu hỗ trợ về việc đặt vé.</p>

//           <form className="support-form flex flex-col gap-4">
//             <label htmlFor="name" className="text-left font-medium text-[#2c3e50]">Họ và tên</label>
//             <input 
//               type="text" 
//               id="name" 
//               name="name" 
//               placeholder="Nhập họ và tên của bạn" 
//               required 
//               className="p-2 text-lg border border-[#bdc3c7] rounded-md w-full focus:border-green-500 focus:outline-none" 
//             />

//             <label htmlFor="email" className="text-left font-medium text-[#2c3e50]">Email</label>
//             <input 
//               type="email" 
//               id="email" 
//               name="email" 
//               placeholder="Nhập email của bạn" 
//               required 
//               className="p-2 text-lg border border-[#bdc3c7] rounded-md w-full focus:border-green-500 focus:outline-none" 
//             />

//             <label htmlFor="phone" className="text-left font-medium text-[#2c3e50]">Số điện thoại</label>
//             <input 
//               type="tel" 
//               id="phone" 
//               name="phone" 
//               placeholder="Nhập số điện thoại của bạn" 
//               required 
//               className="p-2 text-lg border border-[#bdc3c7] rounded-md w-full focus:border-green-500 focus:outline-none" 
//             />

//             <label htmlFor="message" className="text-left font-medium text-[#2c3e50]">Câu hỏi</label>
//             <textarea 
//               id="message" 
//               name="message" 
//               placeholder="Nhập câu hỏi của bạn" 
//               rows="5" 
//               required 
//               className="p-2 text-lg border border-[#bdc3c7] rounded-md w-full focus:border-green-500 focus:outline-none" 
//             />

//             <button 
//               type="submit" 
//               className="p-3 text-lg bg-green-500 text-white border-none rounded-md cursor-pointer transition-colors duration-300 hover:bg-green-600"
//             >
//               Gửi câu hỏi
//             </button>
//           </form>

//           <div className="contact-info mt-8 text-lg">
//             <h3 className="mb-2 text-[#2c3e50]">Thông tin liên hệ:</h3>
//             <p className="text-[#7f8c8d]">
//               Email: <a href="mailto:support@websitedatve.com" className="text-green-500 hover:underline">support@websitedatve.com</a>
//             </p>
//             <p className="text-[#7f8c8d]">
//               Hotline: <a href="tel:+84123456789" className="text-green-500 hover:underline">+84 123 456 789</a>
//             </p>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default SupportForm;

import React, { useState } from 'react';

const SupportForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch('http://localhost:8000/api/contact', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setSuccessMessage('Câu hỏi của bạn đã được gửi thành công.');
          setFormData({ name: '', email: '', phone: '', message: '' });
        } else {
          setErrorMessage('Đã xảy ra lỗi khi gửi biểu mẫu. Vui lòng thử lại.');
        }
      })
      .catch((error) => {
        setErrorMessage('Đã gửi thành công');
      });
  };

  return (
    <section className="support-form-section bg-white p-10 my-5 max-w-[1200px] mx-auto rounded-lg shadow-lg">
      <div className="form-container flex gap-5 flex-wrap">
        {/* Left Column - Image */}
        <div className="form-image">
          <img
            src="/images/online-assistant-the-guy-sits-at-the-computer-communicates-with-clients-through-headphones-and-responds-to-messages-working-from-home-training-and-solving-everyday-problems-with-client.jpg"
            alt="Support Image"
            className="w-[600px] h-[600px] rounded-lg"
          />
        </div>

        {/* Right Column - Form */}
        <div className="form-content flex flex-col justify-center w-[500px]">
          <h2 className="text-2xl text-[#2c3e50] mb-2">Liên hệ với chúng tôi</h2>
          <p className="text-lg mb-5 text-[#7f8c8d]">Đặt câu hỏi hoặc yêu cầu hỗ trợ về việc đặt vé.</p>

          {successMessage && <p className="text-green-500">{successMessage}</p>}
          {errorMessage && <p className="text-red-500">{errorMessage}</p>}

          <form className="support-form flex flex-col gap-4" onSubmit={handleSubmit}>
            <label htmlFor="name" className="text-left font-medium text-[#2c3e50]">Họ và tên</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Nhập họ và tên của bạn"
              required
              className="p-2 text-lg border border-[#bdc3c7] rounded-md w-full focus:border-green-500 focus:outline-none"
            />

            <label htmlFor="email" className="text-left font-medium text-[#2c3e50]">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Nhập email của bạn"
              required
              className="p-2 text-lg border border-[#bdc3c7] rounded-md w-full focus:border-green-500 focus:outline-none"
            />

            <label htmlFor="phone" className="text-left font-medium text-[#2c3e50]">Số điện thoại</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              placeholder="Nhập số điện thoại của bạn"
              required
              className="p-2 text-lg border border-[#bdc3c7] rounded-md w-full focus:border-green-500 focus:outline-none"
            />

            <label htmlFor="message" className="text-left font-medium text-[#2c3e50]">Câu hỏi</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              placeholder="Nhập câu hỏi của bạn"
              rows="5"
              required
              className="p-2 text-lg border border-[#bdc3c7] rounded-md w-full focus:border-green-500 focus:outline-none"
            />

            <button
              type="submit"
              className="p-3 text-lg bg-green-500 text-white border-none rounded-md cursor-pointer transition-colors duration-300 hover:bg-green-600"
            >
              Gửi câu hỏi
            </button>
          </form>

          <div className="contact-info mt-8 text-lg">
            <h3 className="mb-2 text-[#2c3e50]">Thông tin liên hệ:</h3>
            <p className="text-[#7f8c8d]">
              Email: <a href="mailto:support@websitedatve.com" className="text-green-500 hover:underline">support@websitedatve.com</a>
            </p>
            <p className="text-[#7f8c8d]">
              Hotline: <a href="tel:+84123456789" className="text-green-500 hover:underline">+84 123 456 789</a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SupportForm;
