import React, { useEffect, useState } from 'react';
import BusInfo from '../Components/BusInfo';
import SeatSelection from '../Components/SeatSelection';
import MapView from '../Components/MapView';
import CommentsForm from '../Components/CommentsForm';
import { useParams } from 'react-router-dom';
import Footer from '../Components/Footer';
import Header from '../Components/Header';

const DetailBus = () => {
    const { id } = useParams(); // Get ID from URL
    const [bus, setBus] = useState(null); // State to hold bus details
    const [loading, setLoading] = useState(true); // Loading state
    const [error, setError] = useState(null); // Error state

    useEffect(() => {
        // Fetch bus details from Laravel API
        const fetchBusDetails = async () => {
            try {
                const response = await fetch(`http://localhost:8000/api/trips/${id}`);
                if (!response.ok) {
                    throw new Error('Bus not found');
                }
                const data = await response.json();
                setBus(data); // Set bus details in state
            } catch (error) {
                setError(error.message); // Set error message
            } finally {
                setLoading(false); // Stop loading
            }
        };

        fetchBusDetails();
    }, [id]);

    if (error) {
        return <div className="p-6">{error}</div>;
    }

    if (!bus) {
        return <div className="p-6">Xe bus không tồn tại!</div>;
    }

    // Data for bus route
    const busRoute = {
        start: { lat: bus.chitiettuyenduong.vitri_batdau_lat, lng: bus.chitiettuyenduong.vitri_batdau_lng }, // Starting point from bus details
        end: { lat: bus.chitiettuyenduong.vitri_ketthuc_lat, lng: bus.chitiettuyenduong.vitri_ketthuc_lng }, // End point from bus details
    };

    return (
        <>
        <Header/>
            <h1 className="text-4xl text-gray-800 text-center mt-8 mb-12 tracking-wide">
                Thông tin chi tiết {bus.chitietxebuss.tenxe}
            </h1>
            <div className="container mx-auto p-4">
                <BusInfo bus={bus} />
                <br />
                <h1 className='text-3xl'>Tuyến xe: {bus.chitiettuyenduong.noixuatphat} - {bus.chitiettuyenduong.noiden} </h1>
                <label>
                    - <b>Lộ trình chính</b>: Xuất phát từ {bus.chitiettuyenduong.bendi} và kết thúc tại {bus.chitiettuyenduong.benden} <hr />
                    - <b>Thời gian hoạt động</b>: Xe hoạt động hàng ngày từ {bus.thoigianbatdau} đến {bus.thoigianketthuc}, phù hợp cho cả người đi làm và du lịch. <hr />
                    - <b>Tần suất chuyến</b>: Xe chạy với tần suất 5 tiếng một chuyến, giúp người dân dễ dàng sắp xếp thời gian. <hr />
                    - <b>Thời gian hành trình</b>: Trung bình mất khoảng 5 đến 10 phút để di chuyển từ đầu đến cuối tuyến, tùy thuộc vào tình hình giao thông. <hr />
                </label>
                <br />
                <h1 className='text-3xl'>Mô tả chi tiết về xe</h1>
                <label>
                    - <b>Số lượng ghế</b>: Xe có {bus.chitietxebuss.sochongoi} ghế ngồi thoải mái, cùng không gian đứng dành cho những giờ cao điểm. <hr />
                    - <b>Loại xe</b>: Xe bus cỡ lớn với màu sơn {bus.chitietxebuss.mausac} đặc trưng, trang bị điều hòa không khí, đảm bảo sự thoải mái trong điều kiện thời tiết nóng. <hr />
                    - <b>Tiện ích</b>: Wifi miễn phí trên xe, giúp hành khách có thể truy cập internet trong suốt chuyến đi, cổng sạc USB tại mỗi ghế ngồi, tiện lợi cho việc sạc điện thoại hoặc thiết bị điện tử cá nhân. <hr />
                </label>
                <br />
                <h1 className='text-3xl'>Liên hệ và hỗ trợ</h1>
                <label>
                    - <b>Số điện thoại hỗ trợ</b>: 1900 1234 - Tổng đài hỗ trợ khách hàng 24/7 để giải đáp mọi thắc mắc. <hr />
                    <i className='text-red-700'>Bạn có thể tra cứu lịch trình hoặc mua vé online qua ứng dụng BusFreeApp hoặc Website chính thức BusFree.com</i>
                </label>
                <SeatSelection totalSeats={bus.sochongoi} />
                <br />
                {/* Truyền id chuyến đi vào component MapView */}
                <MapView idchuyen={id} busRoute={busRoute} />
                <br />
                <CommentsForm/>
            </div>
            <Footer />
        </>
    );
};

export default DetailBus;
