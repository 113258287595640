import React from "react";
import { useNavigate } from "react-router-dom";

const NotAuthorized = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate("/"); // Điều hướng về trang chủ
  };

  return (
    <div className="text-center mt-10">
      <h1 className="text-3xl font-bold text-red-500">Không có quyền truy cập</h1>
      <p>Vui lòng liên hệ quản trị viên nếu bạn nghĩ đây là nhầm lẫn.</p>
      <button
        onClick={handleGoHome}
        className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-300"
      >
        Quay về trang chủ
      </button>
    </div>
  );
};

export default NotAuthorized;
