
import React, { useEffect, useRef, useState } from 'react';
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import './Mapp.css';
const GoongMap = () => {
    const mapContainerRef = useRef(null);
    const [map, setMap] = useState(null);
    const [searchResults, setSearchResults] = useState([]);
    const [directionsVisible, setDirectionsVisible] = useState(false);
    const [startLocation, setStartLocation] = useState('');
    const [endLocation, setEndLocation] = useState('');
    const [mapStyles, setMapStyles] = useState([]);
    const [selectedStyle, setSelectedStyle] = useState('Normal');

    const apiUrl = 'https://rsapi.goong.io';
    const apiKey = 'QFOsSIbHTdYzfRrboMH8CGZg9KvaOJlsXz6hZmjp'; // Thay thế bằng API key của bạn
    const mapKey = 'BSXct3ZJXeeIRZZ1TmVDrInhNDXUBYyQv9vKOdEU'; // Thay thế bằng Map key của bạn
    const center = [105.85242472181584, 21.029579719995272];
    const zoom = 14;

    useEffect(() => {
        const initializeMap = () => {
            const mapInstance = new maplibregl.Map({
                container: mapContainerRef.current,
                style: `https://tiles.goong.io/assets/goong_map_web.json?api_key=${mapKey}`,
                center: center,
                zoom: zoom,
            });

            mapInstance.on('load', () => {
                new maplibregl.Marker().setLngLat(center).addTo(mapInstance);
                setMapStyles([
                    { name: 'Normal', url: `https://tiles.goong.io/assets/goong_map_web.json?api_key=${mapKey}` },
                    { name: 'Satellite', url: `https://tiles.goong.io/assets/goong_satellite.json?api_key=${mapKey}` },
                    { name: 'Dark', url: `https://tiles.goong.io/assets/goong_map_dark.json?api_key=${mapKey}` },
                    { name: 'Light', url: `https://tiles.goong.io/assets/navigation_day.json?api_key=${mapKey}` },
                    { name: 'Night', url: `https://tiles.goong.io/assets/navigation_night.json?api_key=${mapKey}` }
                ]);
            });

            setMap(mapInstance);
        };

        if (!map) initializeMap();

        return () => {
            if (map) {
                map.remove();
            }
        };
    }, [map]);

    const handleSearchInput = (event) => {
        const query = event.target.value;
        if (query.length >= 2) {
            fetchDataAutoComplete(query);
        } else {
            setSearchResults([]);
        }
    };

    const fetchDataAutoComplete = (query) => {
        const apiLink = `${apiUrl}/Place/AutoComplete?api_key=${apiKey}&input=${encodeURIComponent(query)}`;
        fetch(apiLink)
            .then(response => response.json())
            .then(data => {
                if (data.predictions) {
                    setSearchResults(data.predictions);
                } else {
                    setSearchResults([]);
                }
            })
            .catch(error => console.error('Error fetching data:', error));
    };

    const handlePlaceSelect = (place) => {
        const { place_id } = place;
        fetchPlaceDetails(place_id);
        setSearchResults([]);
    };

    const fetchPlaceDetails = (placeId) => {
        const apiLink = `${apiUrl}/Place/Detail?api_key=${apiKey}&place_id=${placeId}`;
        fetch(apiLink)
            .then(response => response.json())
            .then(data => {
                if (data.result) {
                    const { location } = data.result.geometry;
                    const lngLat = [location.lng, location.lat];
                    addMarker(lngLat);
                }
            })
            .catch(error => console.error('Error fetching place details:', error));
    };

    const addMarker = (lngLat) => {
        new maplibregl.Marker().setLngLat(lngLat).addTo(map);
        map.flyTo({ center: lngLat, zoom: zoom });
    };

    const handleGetDirections = () => {
        if (startLocation && endLocation) {
            fetchDirections(startLocation, endLocation);
        } else {
            alert('Please enter both start and end locations.');
        }
    };

    const fetchDirections = (start, end) => {
        const apiLink = `${apiUrl}/Direction?origin=${start}&destination=${end}&vehicle=car&api_key=${apiKey}`;
        fetch(apiLink)
            .then(response => response.json())
            .then(data => {
                if (data.routes && data.routes.length > 0) {
                    const route = data.routes[0].overview_polyline.points;
                    const distance = data.routes[0].legs[0].distance.text;
                    const time = data.routes[0].legs[0].duration.text;
                    const decodedRoute = decodePolyline(route);
                    displayRoute(decodedRoute, distance, time);
                } else {
                    alert('Could not find a route.');
                }
            })
            .catch(error => console.error('Error fetching directions:', error));
    };

    const decodePolyline = (encoded) => {
        const points = [];
        let index = 0, len = encoded.length;
        let lat = 0, lng = 0;

        while (index < len) {
            let b, shift = 0, result = 0;
            do {
                b = encoded.charAt(index++).charCodeAt(0) - 63;
                result |= (b & 0x1f) << shift;
                shift += 5;
            } while (b >= 0x20);
            const dlat = ((result & 1) ? ~(result >> 1) : (result >> 1));
            lat += dlat;

            shift = 0;
            result = 0;
            do {
                b = encoded.charAt(index++).charCodeAt(0) - 63;
                result |= (b & 0x1f) << shift;
                shift += 5;
            } while (b >= 0x20);
            const dlng = ((result & 1) ? ~(result >> 1) : (result >> 1));
            lng += dlng;

            points.push([lng * 1e-5, lat * 1e-5]);
        }

        return points;
    };

    const displayRoute = (route, distance, time) => {
        if (map.getSource('route')) {
            map.removeLayer('route');
            map.removeSource('route');
        }

        map.addSource('route', {
            'type': 'geojson',
            'data': {
                'type': 'Feature',
                'properties': {},
                'geometry': {
                    'type': 'LineString',
                    'coordinates': route
                }
            }
        });

        map.addLayer({
            'id': 'route',
            'type': 'line',
            'source': 'route',
            'layout': {
                'line-join': 'round',
                'line-cap': 'round'
            },
            'paint': {
                'line-color': '#3887be',
                'line-width': 5,
                'line-opacity': 0.9
            }
        });

        const midPoint = route[Math.floor(route.length / 2)];
        new maplibregl.Popup()
            .setLngLat(midPoint)
            .setHTML(`<p>Khoảng cách: ${distance}</p> <p>Thời gian: ${time}</p>`)
            .addTo(map);

        map.fitBounds(route.reduce((bounds, coord) => bounds.extend(coord), new maplibregl.LngLatBounds(route[0], route[0])));
    };

    const handleMapStyleChange = (style) => {
        setSelectedStyle(style.name);
        map.setStyle(style.url);
    };

    return (
        <div>
        <div ref={mapContainerRef} className="h-screen w-full" />
        <div className="absolute top-8 left-16 flex flex-col items-start gap-4" id="search-container">
          {/* Phần tìm kiếm */}
          <div className="flex items-center w-full">
            <input
              className="w-80 min-h-[40px] px-2 shadow-sm rounded-l-sm border border-transparent outline-none"
              id="input"
              onChange={handleSearchInput}
              placeholder="Tìm kiếm địa điểm"
            />
            <button
              onClick={() => setDirectionsVisible(!directionsVisible)}
              className="ml-4 p-2 bg-blue-500 text-white rounded"
            >
              {directionsVisible ? 'Hide Directions' : 'Show Directions'}
            </button>
          </div>
          {/* Kết quả tìm kiếm */}
          <div className="absolute top-[44px] w-[432px] bg-white shadow-inner rounded p-2 z-10">
            {searchResults.map((result) => (
              <div
                key={result.place_id}
                className="w-full cursor-pointer mb-2 p-2 hover:bg-gray-200"
                onClick={() => handlePlaceSelect(result)}
              >
                {result.description}
              </div>
            ))}
          </div>
          <hr />
          {/* Dropdown chọn kiểu bản đồ */}
          <div className="flex items-center gap-2">
            <h3 className="text-lg font-semibold">Chọn kiểu bản đồ:</h3>
            <select
              onChange={(e) => {
                const selectedStyle = mapStyles.find((style) => style.name === e.target.value);
                if (selectedStyle && map) {
                  map.setStyle(selectedStyle.url); // Cập nhật kiểu bản đồ
                }
              }}
              className="p-2 border rounded shadow-sm"
            >
              {mapStyles.map((style) => (
                <option key={style.name} value={style.name}>
                  {style.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        {/* Hiển thị dẫn đường */}
        {directionsVisible && (
          <div className="absolute top-20 left-16 flex flex-col gap-2 p-2 bg-white rounded shadow-lg">
            <input
              type="text"
              value={startLocation}
              onChange={(e) => setStartLocation(e.target.value)}
              placeholder="Điểm đầu"
              className="w-72 h-10 px-2 shadow-sm rounded-l-sm border border-transparent outline-none"
            />
            <input
              type="text"
              value={endLocation}
              onChange={(e) => setEndLocation(e.target.value)}
              placeholder="Điểm cuối"
              className="w-72 h-10 px-2 shadow-sm rounded-l-sm border border-transparent outline-none"
            />
            <button onClick={handleGetDirections} className="p-2 bg-green-500 text-white rounded">
              Dẫn đường
            </button>
          </div>
        )}
      </div>
      

    );
};

export default GoongMap;



