import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Confetti from "react-confetti"; // Import Confetti

const PaymentResult = () => {
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [loading, setLoading] = useState(true); // Trạng thái để hiển thị "Đang xử lý"
  const [isSuccess, setIsSuccess] = useState(false); // Trạng thái để điều khiển hiển thị pháo giấy
  const location = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(location.search);

    // Giả lập quá trình thanh toán
    setTimeout(() => {
      const resultCode = query.get("resultCode");
      const orderId = query.get("orderId");
      const amount = query.get("amount");
      const orderInfo = query.get("orderInfo"); // Thông tin về vé

      // Kiểm tra mã kết quả và xử lý dữ liệu
      if (resultCode === "0") {
        let tickets = orderInfo;
        try {
          tickets = JSON.parse(orderInfo); // Giả sử orderInfo có thể là chuỗi JSON
        } catch (e) {
          console.error("Không thể parse orderInfo:", e);
        }

        setPaymentDetails({
          success: true,
          amount: amount || "Chưa có thông tin",
          tickets: tickets || "Không có thông tin vé",
        });
        setIsSuccess(true); // Đặt trạng thái thành công để hiển thị pháo giấy
      } else {
        setPaymentDetails({
          success: false,
          message: `Thanh toán thất bại (Mã lỗi: ${resultCode})`,
        });
      }

      // Cập nhật trạng thái loading sau khi xử lý xong
      setLoading(false);
    }, 2000); // Giả lập thời gian xử lý thanh toán 2 giây
  }, [location]);

  return (
    <div className="flex justify-center items-center min-h-screen bg-gradient-to-r from-indigo-200 via-pink-200 to-yellow-200 p-6">
      {/* Hiển thị Confetti khi thanh toán thành công */}
      {isSuccess && <Confetti width={window.innerWidth} height={window.innerHeight} />}
      
      <div className="max-w-lg w-full bg-white p-8 rounded-2xl shadow-2xl border-2 border-gray-300">
        {loading ? (
          <div className="text-center text-lg text-gray-700 font-semibold">
            Đang xử lý kết quả thanh toán...
          </div>
        ) : (
          <div
            className={`${
              paymentDetails.success
                ? "bg-green-100 border-green-400"
                : "bg-red-100 border-red-400"
            } p-4 mb-6 rounded-xl shadow-lg`}
          >
            {paymentDetails.success ? (
              <>
                <div className="text-center text-2xl font-bold text-green-800">
                  Thanh toán thành công!
                </div>
                <div className="text-center text-xl mt-4">
                  <strong>Số tiền thanh toán:</strong> {paymentDetails.amount}{" "}
                  VNĐ
                </div>

                {/* Thông tin vé */}
                <div className="mt-6 bg-gray-50 p-4 rounded-md border border-gray-300">
                  <div className="text-center text-xl font-semibold text-gray-700">
                    Thông tin vé
                  </div>
                  <div className="text-center mt-4">
                    <strong>Thông tin vé: </strong>
                    {JSON.stringify(paymentDetails.tickets)}
                  </div>
                </div>
              </>
            ) : (
              <div className="text-center text-xl font-semibold text-red-700">
                {paymentDetails.message ||
                  "Thanh toán thất bại. Vui lòng thử lại."}
              </div>
            )}
          </div>
        )}

        <div className="flex justify-center mt-6">
          <a
            href="/"
            className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-500 transition-all duration-200 transform hover:scale-105"
          >
            Quay lại trang chủ
          </a>
        </div>
      </div>
    </div>
  );
};

export default PaymentResult;
