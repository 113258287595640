import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

const MainContent = () => {
    const [year, setYear] = useState(new Date().getFullYear()); // State cho năm
    const [month, setMonth] = useState(null); // State cho tháng
    const [week, setWeek] = useState(null); // State cho tuần
    const [date, setDate] = useState(null); // State cho ngày
    const [trips, setTrips] = useState([]);
    const [statistics, setStatistics] = useState({
        totalTicketsSold: 0,
        totalTrips: 0,
        totalRevenue: 0,
    });
    const [revenuePerMonth, setRevenuePerMonth] = useState([]); // Doanh thu theo tháng
    const chart1Ref = useRef(null);
    const chart2Ref = useRef(null);
    const chartInstance1 = useRef(null);
    const chartInstance2 = useRef(null);

    // Cấu hình Interceptor
    useEffect(() => {
        const interceptor = axios.interceptors.request.use(
            (config) => {
                const token = localStorage.getItem('access_token');
                if (token) {
                    config.headers['Authorization'] = `Bearer ${token}`;
                }
                return config;
            },
            (error) => Promise.reject(error)
        );

        return () => {
            axios.interceptors.request.eject(interceptor);
        };
    }, []);

    useEffect(() => {
        fetchStatistics();
    }, []);

    useEffect(() => {
        fetchTrips(year, month, week, date);
    }, [year, month, week, date]);

    useEffect(() => {
        if (year) fetchRevenuePerMonth(year);
    }, [year]);

    useEffect(() => {
        if (chart1Ref.current) setupChart1();
        return () => {
            if (chartInstance1.current) chartInstance1.current.destroy();
        };
    }, []);

    useEffect(() => {
        if (chart2Ref.current && revenuePerMonth.labels) {
            setupChart2(revenuePerMonth);
        }
        return () => {
            if (chartInstance2.current) chartInstance2.current.destroy();
        };
    }, [revenuePerMonth]);

    const fetchStatistics = async () => {
        try {
            const response = await axios.get('http://localhost:8000/api/statistics');
            setStatistics(response.data);
        } catch (error) {
            console.error("Error fetching statistics:", error);
        }
    };

    const fetchTrips = async (year, month = null, week = null, date = null) => {
        try {
            let query = `?year=${year}`;
            if (month) query += `&month=${month}`;
            if (week) query += `&week=${week}`;
            if (date) query += `&date=${date}`;

            const tripResponse = await axios.get(`http://localhost:8000/api/trips${query}`);
            setTrips(tripResponse.data);

            const routeRevenueResponse = await axios.get(`http://localhost:8000/api/statisticsrevenue${query}`);
            setupChart1(routeRevenueResponse.data);
        } catch (error) {
            console.error("Error fetching trips:", error);
        }
    };

    const fetchRevenuePerMonth = async (year) => {
        try {
            const response = await axios.get(`http://localhost:8000/api/revenue-per-month?year=${year}`);
            setRevenuePerMonth(response.data);
        } catch (error) {
            console.error("Error fetching revenue per month:", error);
        }
    };

    const setupChart1 = (data) => {
        if (!data?.labels || !data?.datasets) {
            console.error("Invalid data for Chart 1");
            return;
        }

        if (chartInstance1.current) chartInstance1.current.destroy();

        const ctx1 = chart1Ref.current.getContext('2d');
        const gradient = ctx1.createLinearGradient(0, 0, 0, 400);
        gradient.addColorStop(0, 'rgba(75, 192, 192, 0.5)');
        gradient.addColorStop(1, 'rgba(75, 192, 192, 0.1)');

        chartInstance1.current = new Chart(ctx1, {
            type: 'bar',
            data: {
                labels: data.labels,
                datasets: [{
                    label: 'Doanh thu theo tuyến',
                    data: data.datasets[0]?.data || [],
                    backgroundColor: gradient,
                    borderColor: 'rgba(75, 192, 192, 1)',
                    borderWidth: 2,
                }],
            },
            options: {
                scales: {
                    y: { beginAtZero: true },
                },
                plugins: {
                    legend: {
                        position: 'top',
                        labels: { color: '#333' },
                    },
                },
            },
        });
    };

    const setupChart2 = (data) => {
        if (!data?.labels || !data?.data) {
            console.error("Invalid data for Chart 2");
            return;
        }

        if (chartInstance2.current) chartInstance2.current.destroy();

        const ctx2 = chart2Ref.current.getContext('2d');
        const gradient = ctx2.createLinearGradient(0, 0, 0, 400);
        gradient.addColorStop(0, 'rgba(153, 102, 255, 0.5)');
        gradient.addColorStop(1, 'rgba(153, 102, 255, 0.1)');

        chartInstance2.current = new Chart(ctx2, {
            type: 'line',
            data: {
                labels: data.labels,
                datasets: [
                    {
                        label: `Doanh thu năm ${year} (VNĐ)`,
                        data: data.data.map((item) => parseFloat(item)),
                        fill: true,
                        backgroundColor: gradient,
                        borderColor: 'rgba(153, 102, 255, 1)',
                        tension: 0.4,
                    },
                ],
            },
            options: {
                scales: {
                    y: { beginAtZero: true },
                },
                plugins: {
                    legend: {
                        position: 'top',
                        labels: { color: '#333' },
                    },
                },
            },
        });
    };
    
    return (
        <div className="main-content">
            <h1 className="text-3xl font-bold text-center mb-8">Thống kê Đặt Vé Xe Buýt</h1>
            <main className="flex-1 p-5">
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
        {/* Card: Tổng số vé đã bán */}
        <div className="bg-white shadow-lg rounded-lg p-5 flex items-center">
            <div className="bg-blue-100 p-4 rounded-full mr-4">
                <i className="fas fa-ticket-alt text-blue-500 text-3xl"></i>
            </div>
            <div>
                <h2 className="text-xl font-semibold mb-1">Tổng số vé đã bán</h2>
                <p className="text-3xl text-blue-500">{statistics.tong_ve_ban_duoc}</p>
            </div>
        </div>

        {/* Card: Tổng số chuyến xe */}
        <div className="bg-white shadow-lg rounded-lg p-5 flex items-center">
            <div className="bg-green-100 p-4 rounded-full mr-4">
                <i className="fas fa-bus text-green-500 text-3xl"></i>
            </div>
            <div>
                <h2 className="text-xl font-semibold mb-1">Tổng số chuyến xe</h2>
                <p className="text-3xl text-green-500">{statistics.tong_chuyen_di}</p>
            </div>
        </div>

        {/* Card: Tổng doanh thu */}
        <div className="bg-white shadow-lg rounded-lg p-5 flex items-center">
            <div className="bg-red-100 p-4 rounded-full mr-4">
                <i className="fas fa-dollar-sign text-red-500 text-3xl"></i>
            </div>
            <div>
                <h2 className="text-xl font-semibold mb-1">Tổng doanh thu</h2>
                <p className="text-3xl text-red-500">{statistics.tong_doanh_thu} VNĐ</p>
            </div>
        </div>
    </div>
</main>
<div className="mb-4 flex items-center space-x-4">
<label htmlFor="year" className="mr-2">Chọn năm:</label>
    <select
        id="year"
        value={year}
        onChange={(e) => setYear(e.target.value)}
        className="border border-gray-300 rounded p-2"
    >
        {[2025, 2024, 2023, 2022, 2021].map((y) => (
            <option key={y} value={y}>{y}</option>
        ))}
    </select>
    <div>
        <label htmlFor="month" className="mr-2">Chọn tháng:</label>
        <select
            id="month"
            value={month}
            onChange={(e) => setMonth(e.target.value)}
            className="border border-gray-300 rounded p-2"
        >
            {[...Array(12).keys()].map((i) => (
                <option key={i + 1} value={i + 1}>{i + 1}</option>
            ))}
        </select>
    </div>

    <div>
        <label htmlFor="week" className="mr-2">Chọn tuần:</label>
        <select
            id="week"
            value={week}
            onChange={(e) => setWeek(e.target.value)}
            className="border border-gray-300 rounded p-2"
        >
            {[...Array(52).keys()].map((i) => (
                <option key={i + 1} value={i + 1}>Tuần {i + 1}</option>
            ))}
        </select>
    </div>

    <div>
        <label htmlFor="date" className="mr-2">Chọn ngày:</label>
        <select
            id="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            className="border border-gray-300 rounded p-2"
        >
            {[...Array(31).keys()].map((i) => (
                <option key={i + 1} value={i + 1}>{i + 1}</option>
            ))}
        </select>
    </div>
</div>
            <div className="grid grid-cols-2 gap-4">
                <div>
                    <canvas id="revenueMap1" ref={chart1Ref} className="my-8 bg-white rounded-lg shadow-lg"></canvas>
                </div>
                <div>
                    <canvas id="revenueMap2" ref={chart2Ref} className="my-8 bg-white rounded-lg shadow-lg"></canvas>
                </div>
            </div>

            <div className="bg-white shadow-lg rounded-lg p-5">
                <h2 className="text-xl font-semibold mb-4">Chuyến xe gần đây</h2>
                <table className="min-w-full border border-gray-300">
                    <thead className="bg-gray-200">
                        <tr>
                            <th className="border border-gray-300 px-4 py-2">Tuyến</th>
                            <th className="border border-gray-300 px-4 py-2">Thời gian xuất phát</th>
                            <th className="border border-gray-300 px-4 py-2">Số ghế</th>
                            <th className="border border-gray-300 px-4 py-2">Trạng thái</th>
                        </tr>
                    </thead>
                    <tbody>
                        {trips.map((trip) => (
                            <tr key={trip.idchuyen}>
                                <td className="border border-gray-300 px-4 py-2">{trip.chitietxebuss.tenxe}</td>
                                <td className="border border-gray-300 px-4 py-2">{trip.chitiettuyenduong.noixuatphat}</td>
                                <td className="border border-gray-300 px-4 py-2">{trip.chitietxebuss.sochongoi}</td>
                                <td className={`border border-gray-300 px-4 py-2`}>{trip.chitietxebuss.sochongoi}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default MainContent;
