import React, { useEffect, useState } from 'react';
import axios from 'axios'; // Thư viện HTTP
import Footer from '../Components/Footer';
import Header from '../Components/Header';

function News() {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Gọi API lấy danh sách bài viết
    axios
      .get('http://localhost:8000/api/news') // Thay bằng endpoint API của bạn
      .then((response) => {
        const data = response.data.data.map((item) => ({
          id: item.id,
          title: item.title,
          description: item.content.length > 100 ? `${item.content.slice(0, 50)}...` : item.content, // Tự động rút gọn
          author: item.admin.ten,
          authorImgSrc: item.admin.img || 'https://via.placeholder.com/40', // Ảnh đại diện admin hoặc placeholder
          imgSrc: item.thumbnail || 'https://via.placeholder.com/150',
        }));
        setArticles(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching articles:', error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <p className="text-gray-700">Loading...</p>
      </div>
    );
  }

  return (
    <div className="font-roboto bg-gray-100">
      <Header />
      {/* Banner */}
      <section className="relative w-full h-96 md:h-[500px]">
        <img
          src="images/Banner.jpg"
          alt="News Banner"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <h1 className="text-3xl md:text-5xl text-white font-bold">Our News</h1>
        </div>
      </section>

      {/* Main Content */}
      <main className="container mx-auto p-4">
        <section className="mb-8">
          <h2 className="text-2xl font-bold text-gray-800 mb-6">Latest News</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {articles.map((article) => (
              <article
                key={article.id}
                className="bg-white p-4 rounded-lg shadow hover:shadow-lg transition-shadow"
              >
                <a href={`/news/${article.id}`}>
                  <img
                    src={article.imgSrc}
                    alt={article.title}
                    className="w-full h-40 object-cover rounded mb-4"
                  />
                  <h3 className="text-lg font-semibold text-gray-800 mb-2 truncate">
                    {article.title}
                  </h3>
                  <p className="text-gray-600 text-sm mb-3 truncate">
                    {article.description}
                  </p>
                  <div className="flex items-center mt-4">
                    <img
                      src={article.authorImgSrc}
                      alt={`${article.author}'s profile`}
                      className="w-8 h-8 rounded-full mr-3"
                    />
                    <p className="text-gray-500 text-xs">By {article.author}</p>
                  </div>
                </a>
              </article>
            ))}
          </div>
        </section>
      </main>

      <Footer />
    </div>
  );
}

export default News;
