import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate(); // Initialize navigate

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setError("");
    setMessage("");

    try {
      const response = await fetch("http://localhost:8000/api/forgot-password", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        const data = await response.json();
        setMessage("Link đặt lại mật khẩu đã được gửi!");
      } else {
        const errorData = await response.json();
        setError(errorData.message);
      }
    } catch (error) {
      setError("Có lỗi xảy ra. Vui lòng thử lại.");
    }
  };

  return (
    <div className="flex justify-center items-center h-screen bg-gray-100">
      <div className="w-[400px] p-6 bg-white shadow-lg rounded-lg">
        <h2 className="text-2xl font-bold mb-4 text-center">Quên mật khẩu</h2>
        <form onSubmit={handleForgotPassword}>
          <div className="input-box relative mb-4">
            <label className="text-[#162938]">Email</label>
            <input
              type="email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded mt-2"
            />
          </div>

          {message && <p className="text-green-500 text-center mb-4">{message}</p>}
          {error && <p className="text-red-500 text-center mb-4">{error}</p>}

          <button
            type="submit"
            className="w-full bg-[#28a745] text-white font-medium rounded p-2 mt-4 hover:bg-[#207d3a]"
          >
            Gửi yêu cầu đặt lại mật khẩu
          </button>
        </form>

        {/* Back to Login Button */}
        <button
          onClick={() => navigate("/login")}
          className="w-full bg-gray-200 text-[#162938] font-medium rounded p-2 mt-4 hover:bg-gray-300"
        >
          Quay về trang đăng nhập
        </button>
      </div>
    </div>
  );
};

export default ForgotPassword;
