import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';

const ResetPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Extract token and email from the URL
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  const email = queryParams.get('email');

  const [password, setPassword] = useState('');
  const [password_confirmation, setConfirmPassword] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  // Clear messages on component mount or when inputs change
  useEffect(() => {
    setSuccess(null);
    setError(null);
  }, [password, password_confirmation]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate password match
    if (password !== password_confirmation) {
      setError('Passwords do not match');
      return;
    }

    try {
      const response = await axios.post('http://localhost:8000/api/reset-password', {
        token,
        email,
        password,
        password_confirmation
      });

      // Set success message and navigate to login after a short delay
      setSuccess(response.data.message);
      setTimeout(() => {
        navigate('/login');
      }, 2000);
    } catch (err) {
      // Handle error response
      console.error('Error resetting password:', err.response);
      const errorMessage = err.response?.data?.message || err.message || 'An error occurred while resetting the password';
      setError(errorMessage);
    }
  };

  return (
    <div className="flex justify-center items-center h-screen bg-gray-100">
    <div className="w-[400px] p-6 bg-white shadow-lg rounded-lg">
      <h2 className="text-2xl font-bold mb-4 text-center">Đặt lại mật khẩu</h2>
      
      {/* Thông báo lỗi và thành công */}
      {error && <p className="text-red-500 text-center mb-4">{error}</p>}
      {success && <p className="text-green-500 text-center mb-4">{success}</p>}
      
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-[#162938]" htmlFor="password">Mật khẩu mới</label>
          <input
            id="password"
            name="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="w-full p-2 border border-gray-300 rounded mt-2"
          />
        </div>
        
        <div className="mb-4">
          <label className="block text-[#162938]" htmlFor="confirm-password">Xác nhận lại mật khẩu</label>
          <input
            id="confirm_password"
            name="password_confirmation"
            type="password"
            value={password_confirmation}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
            className="w-full p-2 border border-gray-300 rounded mt-2"
          />
        </div>
        
        <button
          type="submit"
          className="w-full bg-[#28a745] text-white font-medium rounded p-2 mt-4 hover:bg-[#207d3a]"
        >
          Đặt lại mật khẩu
        </button>
      </form>
    </div>
  </div>
  );
};

export default ResetPassword;
