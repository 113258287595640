
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import ChatAI from '../Components/ChatAI';

const BusListPage = () => {
  const [trips, setTrips] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  useEffect(() => {
    // Fetch the trips data from your Laravel API
    axios
      .get('http://localhost:8000/api/trips')
      .then((response) => {
        console.log(response.data)
        setTrips(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('There was an error fetching the trips!', error);
        setLoading(false);
      });
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

 // Filtered and searched buses
 const filteredTrips = trips.filter((trip) => {
  const tenxe = trip.chitietxebuss.tenxe.toLowerCase() || ''; // Tránh lỗi nếu tenxe là null
  const trangthai = trip.chitietxebuss?.trangthai || ''; // Giá trị mặc định là chuỗi rỗng nếu không tồn tại
 // Chuyển đổi trạng thái thành chuỗi để so sánh

 return (
  tenxe.includes(searchTerm.toLowerCase()) &&
  (statusFilter === '' || trangthai.toLowerCase() === statusFilter.toLowerCase())
);

});


  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleStatusFilterChange = (e) => {
    setStatusFilter(e.target.value);
  };

  if (error) return <div>Error: {error}</div>;

  return (
    <>
      <Header />
      {/* Banner Section */}
      <div className="relative w-full mb-6">
        <img
          src="/images/dsc6562-16178596099391625167279.webp"
          alt="Bus Banner"
          className="w-full h-72 object-cover rounded-xl shadow-lg transform hover:scale-105 transition-all duration-300"
        />
        <div className="absolute inset-0 flex items-center justify-center text-center text-white bg-black bg-opacity-40">
          <div className="max-w-lg">
            <h1 className="text-4xl font-extrabold text-shadow-lg mb-4">
              Khám Phá Các Tuyến Xe Buýt Tiện Lợi
            </h1>
            <p className="text-lg font-semibold">
              Chọn tuyến đường và trải nghiệm hành trình an toàn, nhanh chóng cùng chúng tôi!
            </p>
          </div>
        </div>
      </div>

      {/* Bus Service Information Section */}
      <div className="bg-white p-6 rounded-xl shadow-md mb-8">
        <h2 className="text-3xl font-bold text-center text-green-700 mb-4">
          Tại sao chọn BusFree?
        </h2>
        <div className="max-w-4xl mx-auto text-lg text-gray-800">
          <p className="mb-4">
            BusFree cung cấp dịch vụ xe buýt với nhiều ưu điểm nổi bật giúp chuyến đi của bạn trở nên thuận tiện và an toàn hơn:
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li>
              <strong>Đảm bảo chất lượng</strong>: Tất cả các xe đều được bảo dưỡng định kỳ, đảm bảo hoạt động tốt.
            </li>
            <li>
              <strong>Tiết kiệm chi phí</strong>: BusFree cung cấp các tuyến xe tiết kiệm chi phí di chuyển cho người dân.
            </li>
            <li>
              <strong>An toàn</strong>: Chúng tôi cam kết đưa bạn đến đích an toàn và đúng giờ với các tài xế lành nghề.
            </li>
            <li>
              <strong>Tiện lợi</strong>: Với các tuyến đường linh hoạt và dễ dàng truy cập, bạn sẽ không phải lo lắng về việc tìm xe nữa.
            </li>
          </ul>
          <p className="mt-4">
            <strong>Lý do hạn chế:</strong> Mặc dù chúng tôi cung cấp dịch vụ chất lượng, nhưng cũng có một số tuyến đường có thể bị ảnh hưởng bởi tình trạng giao thông hoặc bảo trì không thường xuyên. Tuy nhiên, chúng tôi luôn nỗ lực cải thiện để mang đến trải nghiệm tốt nhất cho bạn.
          </p>
        </div>
      </div>

      {/* Main Content */}
      <section className="bus-list w-full max-w-7xl mx-auto p-8 bg-white rounded-2xl shadow-lg">
        <h2 className="text-3xl font-bold text-gray-800 mb-6 text-center">
          Chọn xe buýt mà bạn muốn đi
        </h2>

        {/* Search and Filter Section */}
        <div className="flex flex-wrap justify-between items-center mb-6 gap-4">
            {/* Search Input */}
            <div className="relative flex-1 min-w-[250px]">
              <input
                type="text"
                placeholder="Tìm kiếm theo tên xe..."
                className="w-full p-3 pl-10 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-700 transition-all"
                value={searchTerm}
                onChange={handleSearchChange}
              />
              <span className="absolute top-3 left-3 text-gray-400">
                <i className="fas fa-search"></i> {/* Font Awesome search icon */}
              </span>
            </div>

            {/* Status Filter */}
            <div className="relative min-w-[200px]">
            <select
  className="w-full p-4 pl-10 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-700 transition-all"
  value={statusFilter}
  onChange={handleStatusFilterChange}
>
  <option value="">Tất cả trạng thái</option>
  <option value="Đang Hoạt Động">Đang hoạt động</option>
  <option value="Không Hoạt Động">Không hoạt động</option>
</select>

              <span className="absolute top-3 left-3 text-gray-400">
                <i className="fas fa-filter"></i> {/* Font Awesome filter icon */}
              </span>
            </div>

            {/* Reset Button */}
            <button
              onClick={() => {
                setSearchTerm('');
                setStatusFilter('');
              }}
              className="p-3 bg-red-600 text-white rounded-lg shadow-md flex items-center gap-2 transition-all duration-300 hover:bg-red-700 active:bg-red-500"
            >
              <i className="fas fa-sync-alt"></i> {/* Font Awesome refresh icon */}
              Làm mới
            </button>
          </div>
        <table className="w-full border-separate border-spacing-0 bg-white shadow-md rounded-xl overflow-hidden">
          <thead className="bg-green-600 text-white">
            <tr>
              <th className="p-5 text-lg">Tên Xe</th>
              <th className="p-5 text-lg">Biển số xe</th>
              <th className="p-5 text-lg">Hình ảnh</th>
              <th className="p-5 text-lg">Địa điểm xuất phát</th>
              <th className="p-5 text-lg">Địa điểm kết thúc</th>
              <th className="p-5 text-lg">Trạng thái</th>
              <th className="p-5 text-lg">Hành động</th>
            </tr>
          </thead>
          <tbody>
  {filteredTrips.length > 0 ? (
    filteredTrips.map((trip) => (
      <tr
        key={trip.idchuyen}
        className="transition-transform duration-300  bg-gray-50 hover:bg-white"
      >
        <td className="p-6 text-center text-green-600 font-semibold">{trip.chitietxebuss?.tenxe}</td>
        <td className="p-6 text-center text-gray-600">{trip.chitietxebuss?.biensoxe}</td>
        <td className="p-6 text-center">
          <div className="relative inline-block w-32 h-20">
            <img
              src={trip.chitietxebuss?.image}
              className="w-full h-full object-cover rounded-lg border-2 border-gray-200 shadow-lg transform transition-transform duration-300 hover:scale-110 hover:border-green-600"
            />
          </div>
        </td>

        <td className="p-6 text-center text-gray-600">{trip.chitiettuyenduong?.noixuatphat}</td>
        <td className="p-6 text-center text-gray-600">{trip.chitiettuyenduong?.noiden}</td>
        <td className="p-6 text-center text-gray-600">{trip.chitietxebuss?.trangthai}</td>
        <td className="p-6 text-center">
          <Link to={`/trips/${trip.idchuyen}`}>
          <button className="flex items-center gap-2 bg-green-600 text-white px-4 py-2 rounded-lg shadow-md transform transition-all duration-300 hover:bg-green-700 hover:shadow-lg active:bg-green-800 active:scale-95">
  <i className="fas fa-info-circle"></i> {/* Icon Font Awesome hoặc tùy chỉnh */}
  Chi tiết
</button>

          </Link>
        </td>
      </tr>
    ))
  ) : (
    <tr>
      <td colSpan="8" className="p-6 text-center text-gray-500">
        Không tìm thấy dữ liệu phù hợp.
      </td>
    </tr>
  )}
</tbody>

        </table>

        {/* Page Number Buttons */}
        <div className="flex justify-center mt-6">
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              onClick={() => handlePageChange(index + 1)}
              className={`px-4 py-2 mx-1 rounded-lg ${currentPage === index + 1 ? 'bg-green-600 text-white' : 'bg-gray-300 text-gray-800'}`}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </section>

      <br />
      <Footer />
    </>
  );
};

export default BusListPage;
