import React, { useState, useRef, useEffect } from "react";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import { useNavigate } from "react-router-dom";

const ThanhToan = () => {
  const [selectedPayment, setSelectedPayment] = useState(
    "Chọn phương thức thanh toán"
  );
  const [selectedMethod, setSelectedMethod] = useState("");
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [amount, setAmount] = useState(0);
  const [giaChuyen, setGiaChuyen] = useState(0);
  const [ticketId, setTicketId] = useState("");
  const [serverPaymentStatus, setServerPaymentStatus] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [bookingInfo, setBookingInfo] = useState(null);
  const [checkingStatus, setCheckingStatus] = useState(false); // Trạng thái kiểm tra thanh toán
  const [expiryTime, setExpiryTime] = useState(null); // Quản lý thời gian hết hạn
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  const handlePaymentSelect = (method) => {
    setSelectedPayment(method);
    setSelectedMethod(`Phương thức thanh toán đã chọn: ${method}`);
    if (dropdownRef.current) {
      dropdownRef.current.style.maxHeight = "0";
    }
  };

  const isLoggedIn = () => {
    const token = localStorage.getItem("access_token");
    return !!token; // Trả về true nếu token tồn tại
  };

  useEffect(() => {
    if (!isLoggedIn()) {
      navigate("/login"); // Điều hướng về trang đăng nhập nếu chưa đăng nhập
    } else {
      const userName = localStorage.getItem("user_name"); // Lấy tên người dùng từ localStorage
      const bookingData = JSON.parse(localStorage.getItem("bookingInfo")); // Lấy thông tin đặt vé

      if (userName && bookingData) {
        setUserInfo(userName);
        setBookingInfo(bookingData);

        // Kiểm tra và gán giá trị giaChuyen
        const giaChuyen = bookingData?.bus?.giachuyen || 0;
        setGiaChuyen(giaChuyen);

        // Kiểm tra và gán tổng tiền ghế
        if (bookingData.selectedSeats && bookingData.selectedSeats.length > 0) {
          const seatCost = bookingData.selectedSeats.length * giaChuyen; // Tính tổng tiền ghế
          setAmount(seatCost); // Đồng bộ tổng tiền cần thanh toán
        } else {
          console.error(
            "Danh sách ghế trống hoặc không hợp lệ trong bookingInfo."
          );
          setPaymentStatus("Danh sách ghế không hợp lệ.");
        }
      } else {
        console.error("Thông tin người dùng hoặc đặt vé không tồn tại.");
        setPaymentStatus("Không tìm thấy thông tin người dùng hoặc đặt vé.");
      }
    }
  }, []);

  const toggleDropdown = () => {
    if (dropdownRef.current) {
      dropdownRef.current.style.maxHeight =
        dropdownRef.current.style.maxHeight === "0px" ||
        !dropdownRef.current.style.maxHeight
          ? `${dropdownRef.current.scrollHeight}px`
          : "0";
    }
  };

  const handlePayment = async () => {
    if (selectedPayment === "Chọn phương thức thanh toán") {
      setPaymentStatus("Vui lòng chọn phương thức thanh toán.");
      return;
    }

    const momoApi = "http://localhost:8000/api/momo/create-atm"; // API MoMo

    try {
      if (!amount || amount <= 0) {
        setPaymentStatus("Số tiền thanh toán không hợp lệ.");
        return;
      }

      // Tạo orderInfo với thông tin chi tiết về vé
      const orderInfo = `Đặt vé xe với mã: ${
        bookingInfo.id
      }, Ghế: ${bookingInfo.selectedSeats.join(", ")}.`;

      const ticketIds = bookingInfo?.id || []; // Danh sách ghế đã chọn
      const buyerName = localStorage.getItem("user_name"); // Lấy tên người mua từ localStorage

      if (!buyerName) {
        setPaymentStatus(
          "Không tìm thấy tên người mua. Vui lòng đăng nhập lại."
        );
        return;
      }

      // Payload gửi đến API MoMo
      const payload = {
        amount: parseFloat(amount), // Tổng tiền thanh toán
        orderInfo, // Mô tả chi tiết vé
        ticketIds, // Danh sách ghế đã chọn
        buyerName, // Tên người mua
        redirectUrl: "http://localhost:3000/payment-result", // URL kết quả thanh toán
      };

      console.log("Payload gửi đi:", payload);

      // Gọi API MoMo
      const momoResponse = await fetch(momoApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: JSON.stringify(payload),
      });

      const momoResult = await momoResponse.json();
      console.log("Phản hồi từ server:", momoResult);

      // Xử lý phản hồi từ API
      if (
        momoResponse.ok &&
        momoResult.status === "success" &&
        momoResult.payUrl
      ) {
        const buyerNameFromServer = momoResult.buyerName || "Không xác định";

        setPaymentStatus(
          `Thanh toán thành công! Người mua: ${buyerNameFromServer}`
        );
        setUserInfo(buyerNameFromServer);

        window.open(momoResult.payUrl, "_blank"); // Mở liên kết thanh toán trong tab mới
        setExpiryTime(Date.now() + 300000); // 5 phút (300000ms)
      } else {
        throw new Error(
          momoResult.message || "Không thể tạo liên kết thanh toán."
        );
      }
    } catch (error) {
      console.error("Lỗi thanh toán:", error);
      setPaymentStatus(`Lỗi: ${error.message}`);
    }
  };

  const closePopup = () => {
    setPaymentStatus(null);
  };

  return (
    <>
      <Header />
      <section>
        <main className="py-8">
          <div className="max-w-7xl mx-auto p-6 bg-gray-100 rounded-lg shadow-md">
            <h2 className="text-2xl font-bold mb-6 text-center">Thanh Toán</h2>
            <div className="flex flex-col lg:flex-row justify-between space-y-6 lg:space-y-0 lg:space-x-6">
              {/* Payment Method Section */}
              <div className="p-6 rounded-lg lg:w-1/3 h-auto">
                <h3 className="text-lg font-semibold mb-4">
                  Phương Thức Thanh Toán
                </h3>
                <div className="relative mb-4">
                  <button
                    onClick={toggleDropdown}
                    className="flex justify-between items-center w-full bg-gray-50 border rounded-lg p-2 text-left focus:outline-none"
                  >
                    <span className="text-sm font-semibold">
                      {selectedPayment}
                    </span>
                    <svg
                      className="w-5 h-5 ml-2"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 9l6 6 6-6"
                      />
                    </svg>
                  </button>
                  <div
                    ref={dropdownRef}
                    className="absolute left-0 right-0 mt-1 bg-white border rounded-lg shadow-lg overflow-hidden transition-all duration-300 ease-in-out max-h-0"
                  >
                    <label
                      className="flex items-center border-b p-2 cursor-pointer"
                      onClick={() => handlePaymentSelect("MoMo")}
                    >
                      <input
                        type="radio"
                        name="phuong_thuc_thanh_toan"
                        value="momo"
                        className="hidden"
                      />
                      <span className="text-sm font-semibold">MoMo</span>
                    </label>
                    <label
                      className="flex items-center p-2 cursor-pointer"
                      onClick={() => handlePaymentSelect("ZaloPay")}
                    >
                      <input
                        type="radio"
                        name="phuong_thuc_thanh_toan"
                        value="zalopay"
                        className="hidden"
                      />
                      <span className="text-sm font-semibold">ZaloPay</span>
                    </label>
                  </div>
                </div>
                <p className="mt-2 text-sm text-gray-600">{selectedMethod}</p>
                <div className="text-sm mb-4">
                  <p className="mb-1">
                    Số tiền cần thanh toán:{" "}
                    <span className="font-semibold text-green-600">
                      {amount.toLocaleString()} VNĐ
                    </span>
                  </p>
                </div>
                <button
                  onClick={handlePayment}
                  className="bg-green-500 px-4 py-2 text-white rounded-lg"
                >
                  Thanh Toán Ngay
                </button>
              </div>
              <div className="lg:w-1/3 h-auto p-6 rounded-lg bg-gray-50">
                <h3 className="text-lg font-semibold mb-4">
                  Hướng Dẫn Thanh Toán
                </h3>
                <p className="text-sm text-gray-600 mb-2">
                  Vui lòng chọn phương thức thanh toán mà bạn muốn sử dụng. Sau
                  khi hoàn tất, hãy kiểm tra lại thông tin trước khi nhấn "Thanh
                  Toán Ngay".
                </p>
                <p className="text-sm text-gray-600 mb-2">
                  Đảm bảo rằng bạn đã điền đúng thông tin chuyển khoản để tránh
                  bị lỗi trong quá trình giao dịch.
                </p>
                {/* <h3 className="text-lg font-semibold mb-4 mt-4">
                  Hướng Dẫn Thanh Toán
                </h3>
                <p className="text-sm text-gray-600 mb-2">
                  Vui lòng chọn phương thức thanh toán mà bạn muốn sử dụng. Sau
                  khi hoàn tất, hãy kiểm tra lại thông tin trước khi nhấn "Thanh
                  Toán Ngay".
                </p>
                <p className="text-sm text-gray-600 mb-2">
                  Đảm bảo rằng bạn đã điền đúng thông tin chuyển khoản để tránh
                  bị lỗi trong quá trình giao dịch.
                </p> */}
              </div>
              {/* Ticket Information Section */}
              <div className="max-w-md mx-auto p-4 bg-white border border-gray-300 rounded-lg h-auto">
                <h2 className="text-xl font-bold text-center mb-4">
                  VÉ XE BUÝT
                </h2>
                <div className="border-t border-gray-300 my-2"></div>

                {/* Lấy dữ liệu từ localStorage */}
                {bookingInfo ? (
                  <>
                    {/* Ticket Details */}
                    <div className="flex justify-between text-sm mb-2">
                      <span className="text-gray-600">Mã vé:</span>
                      <span className="font-semibold">
                        {bookingInfo.ticketIds.join(", ") || "null"}
                      </span>
                    </div>
                    <div className="flex justify-between text-sm mb-2">
                      <span className="text-gray-600">Người mua:</span>
                      <span className="font-semibold">
                        {userInfo || "null"}
                      </span>
                    </div>
                    <div className="flex justify-between text-sm mb-2">
                      <span className="text-gray-600">Ngày đặt:</span>
                      <span className="font-semibold">
                        {bookingInfo.purchaseDate || "null"}
                      </span>
                    </div>
                    <div className="flex justify-between text-sm mb-2">
                      <span className="text-gray-600">Khởi hành:</span>
                      <span className="font-semibold">
                        {bookingInfo?.bus?.thoigianbatdau || "null"}
                      </span>
                    </div>
                    <div className="flex justify-between text-sm mb-2">
                      <span className="text-gray-600">Kết thúc:</span>
                      <span className="font-semibold">
                        {bookingInfo?.bus?.thoigianketthuc || "null"}
                      </span>
                    </div>
                    <div className="flex justify-between text-sm mb-2">
                      <span className="text-gray-600">Tuyến đường:</span>
                      <span className="font-semibold">
                        {bookingInfo?.bus?.idtuyenduong || "null"}
                      </span>
                    </div>
                    <div className="flex justify-between text-sm mb-2">
                      <span className="text-gray-600">Số ghế:</span>
                      <span className="font-semibold">
                        {bookingInfo.selectedSeats.join(", ") || "null"}
                      </span>
                    </div>
                    <div className="flex justify-between text-sm mb-2">
                      <span className="text-gray-600">Giá vé:</span>
                      <span className="font-semibold">
                        {(giaChuyen || 0).toLocaleString()} VNĐ
                      </span>
                    </div>

                    <div className="flex justify-between text-sm mb-2">
                      <span className="text-gray-600">Sự kiện đỗ:</span>
                      <span className="font-semibold">---</span>
                    </div>

                    <div className="border-t border-gray-300 my-2"></div>

                    <div className="flex justify-between font-bold text-lg mt-2">
                      <span>Tổng Tiền:</span>
                      <span className="text-green-600">
                        {" "}
                        {bookingInfo.totalPrice.toLocaleString()} VNĐ
                      </span>
                    </div>

                    <div className="border-t border-gray-300 my-2"></div>
                    <div className="text-center mt-4">
                      {/* Mã QR */}
                      <div className="mt-4">
                        <img
                          src={`https://api.qrserver.com/v1/create-qr-code/?data=${
                            bookingInfo.id || "null"
                          }&size=150x150`}
                          alt="Mã QR vé"
                          className="mx-auto"
                        />
                      </div>
                      <p className="text-xs text-gray-600">
                        Quét mã QR này khi lên xe.
                      </p>
                    </div>

                    <div className="text-center mt-4">
                      <p className="text-sm text-gray-600">
                        Cảm ơn bạn đã sử dụng dịch vụ!
                      </p>
                      <svg id="barcode" className="mx-auto my-2"></svg>
                    </div>
                  </>
                ) : (
                  <p className="text-center text-red-500">
                    Không tìm thấy thông tin đặt vé. Vui lòng kiểm tra lại.
                  </p>
                )}
              </div>
            </div>
          </div>
        </main>
      </section>
      <Footer />
    </>
  );
};

export default ThanhToan;
