import React, { useState } from 'react';

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const faqData = [
    {
      question: "Làm thế nào để đặt vé buýt cho sinh viên qua BusFree?",
      answer: "Bạn chỉ cần truy cập vào trang web hoặc ứng dụng của chúng tôi, chọn tuyến xe dành cho sinh viên, sau đó làm theo hướng dẫn để đặt vé."
    },
    {
      question: "BusFree có ưu đãi gì cho sinh viên khi đặt vé?",
      answer: "Chúng tôi có nhiều chương trình giảm giá và khuyến mãi đặc biệt cho sinh viên. Bạn có thể kiểm tra các ưu đãi trên trang web hoặc ứng dụng."
    },
    {
      question: "Có dịch vụ nào dành riêng cho sinh viên không?",
      answer: "Có, BusFree cung cấp dịch vụ đặt vé linh hoạt và dễ dàng cho sinh viên với nhiều tuyến xe liên kết giữa trường học và các địa điểm phổ biến."
    },
    {
      question: "Giá vé cho sinh viên thường là bao nhiêu?",
      answer: "Giá vé cho sinh viên thường dao động từ 2,000 đến 5,000 VND, tùy thuộc vào tuyến đường và thời gian di chuyển."
    },
    {
      question: "Tôi có thể hủy hoặc thay đổi vé đã đặt không?",
      answer: "Có, bạn có thể hủy hoặc thay đổi vé đã đặt thông qua trang web hoặc ứng dụng mà không mất phí."
    },
  ];

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="bg-white p-8 rounded-lg shadow-md mt-10">
      <h2 className="text-3xl font-bold text-center text-green-600 mb-6">Câu hỏi thường gặp</h2>
      {faqData.map((item, index) => (
        <div key={index} className="border-b border-gray-200 mb-4">
          <button
            className="flex justify-between items-center w-full py-4 text-left focus:outline-none"
            onClick={() => toggleFAQ(index)}
          >
            <span className="text-lg font-semibold text-gray-800">{item.question}</span>
            <svg className={`w-6 h-6 transition-transform ${openIndex === index ? 'transform rotate-180' : ''}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v8m0 0l-4-4m4 4l4-4" />
            </svg>
          </button>
          {openIndex === index && (
            <div className="mt-2">
              <p className="text-gray-600">{item.answer}</p>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default FAQ;
