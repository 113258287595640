import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const CommentsForm = () => {
  const [reviews, setReviews] = useState([]); // Đánh giá hiện tại
  const [newRating, setNewRating] = useState(null); // Đánh giá sao mới
  const [newReview, setNewReview] = useState(''); // Nội dung đánh giá mới
  const [notification, setNotification] = useState(''); // Thông báo
  const { id } = useParams(); // Lấy ID chuyến từ URL
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Kiểm tra người dùng đăng nhập

  // Lấy dữ liệu đánh giá khi tải trang
  useEffect(() => {
    const fetchReviews = async () => {
      try {
        // Lấy dữ liệu đánh giá từ API
        const response = await fetch(`http://localhost:8000/api/ratingsGet/${id}`);
        if (!response.ok) {
          throw new Error('Không thể lấy dữ liệu đánh giá từ API');
        }
        const data = await response.json();
        setReviews(data); // Cập nhật đánh giá từ API

        // Lưu vào localStorage để sử dụng lần sau (không cần cho mọi người, chỉ để tối ưu hóa khi reload)
        localStorage.setItem(`reviews_${id}`, JSON.stringify(data));
      } catch (error) {
        console.error("Error fetching reviews:", error);
      }

      // Kiểm tra xem người dùng có đăng nhập không (token)
      const token = localStorage.getItem('access_token');
      if (token) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    };

    // Kiểm tra nếu có dữ liệu trong localStorage
    const storedReviews = localStorage.getItem(`reviews_${id}`);
    if (storedReviews) {
      setReviews(JSON.parse(storedReviews)); // Lấy dữ liệu từ localStorage nếu có
    } else {
      fetchReviews(); // Nếu không có trong localStorage thì gọi API để lấy dữ liệu
    }
  }, [id]);

  // Xử lý gửi đánh giá
  const handleSubmitReview = async (e) => {
    e.preventDefault();

    // Kiểm tra người dùng đã đăng nhập
    const token = localStorage.getItem('access_token');
    const userName = localStorage.getItem('user_name');
    if (!token) {
      setNotification("Vui lòng đăng nhập để gửi đánh giá.");
      return;
    }

    if (newRating === null) {
      setNotification("Vui lòng chọn số sao trước khi gửi đánh giá.");
      return;
    }

    const reviewData = {
      id_chuyen: id,
      rating: newRating,
      review: newReview,
      time: new Date().toISOString(),
    };

    try {
      const response = await fetch('http://localhost:8000/api/ratings', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(reviewData),
      });

      if (response.ok) {
        const result = await response.json();
        setNotification(result.message || 'Đánh giá của bạn đã được thêm thành công!');

        // Cập nhật lại dữ liệu đánh giá vào state
        const updatedReviews = [...reviews, { ...reviewData, user: { name: userName } }];
        setReviews(updatedReviews);

        // Cập nhật lại vào localStorage
        localStorage.setItem(`reviews_${id}`, JSON.stringify(updatedReviews));

        // Reset form input
        setNewRating(null);
        setNewReview('');
      } else {
        const errorData = await response.json();
        setNotification(errorData.message || 'Có lỗi xảy ra khi gửi đánh giá.');
      }
    } catch (error) {
      console.error("Lỗi khi gửi đánh giá:", error);
      setNotification('Có lỗi xảy ra khi gửi đánh giá.');
    }
  };

  return (
    <div className="p-4">
      <h3 className="text-xl font-semibold mb-4">Bình luận tại đây:</h3>

      {/* Thông báo */}
      {notification && (
        <div className="mb-3 text-center text-white bg-green-500 p-2 rounded border border-green-600 text-sm">
          {notification}
        </div>
      )}

      {/* Form đánh giá */}
      <form onSubmit={handleSubmitReview} className="mb-4">
        <div className="mb-2">
          <label className="block text-sm font-medium mb-1">Đánh giá (1-5):</label>
          <div className="flex space-x-2 text-gray-300">
            {[1, 2, 3, 4, 5].map((star) => (
              <label key={star} className="cursor-pointer">
                <input
                  type="radio"
                  name="rating"
                  value={star}
                  className="hidden peer"
                  checked={newRating === star}
                  onChange={() => setNewRating(star)}
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className={`w-8 h-8 fill-current ${newRating >= star ? "text-yellow-400" : "text-gray-300"} peer-hover:text-yellow-500`}
                  viewBox="0 0 26 28"
                >
                  <path d="M26 10.109c0 .281-.203.547-.406.75l-5.672 5.531 1.344 7.812c.016.109.016.203.016.313 0 .406-.187.781-.641.781a1.27 1.27 0 0 1-.625-.187L13 21.422l-7.016 3.687c-.203.109-.406.187-.625.187-.453 0-.656-.375-.656-.781 0-.109.016-.203.031-.313l1.344-7.812L.39 10.859c-.187-.203-.391-.469-.391-.75 0-.469.484-.656.875-.719l7.844-1.141 3.516-7.109c.141-.297.406-.641.766-.641s.625.344.766.641l3.516 7.109 7.844 1.141c.375.063.875.25.875.719z" />
                </svg>
              </label>
            ))}
          </div>
        </div>
        <div className="mb-2">
          <label className="block text-sm font-medium mb-1">Nhận xét:</label>
          <textarea
            name="review"
            value={newReview}
            onChange={(e) => setNewReview(e.target.value)}
            className="w-full p-2 border rounded"
            rows="3"
            required
          ></textarea>
        </div>
        <button
          type="submit"
          className="flex items-center gap-2 bg-green-600 text-white px-4 py-2 rounded-lg shadow-md transition-all duration-300 hover:bg-blue-600 hover:shadow-lg active:bg-blue-700 active:scale-95"
        >
          <i className="fas fa-paper-plane"></i>
          Gửi đánh giá
        </button>
      </form>

      {/* Hiển thị các đánh giá */}
      {reviews.length > 0 ? (
        <div className="space-y-4">
          {reviews.map((review) => (
            <div key={review.id_chuyen} className="p-4 border border-gray-300 rounded-lg shadow-lg bg-white">
              <h4 className="text-xl font-semibold text-gray-800 mb-2">
                {review.user?.name || "Người dùng ẩn danh"}
              </h4>

              {/* Đánh giá sao */}
              <div className="flex items-center mb-3">
                <div className="flex text-yellow-400">
                  {[...Array(5)].map((_, starIndex) => (
                    <svg
                      key={starIndex}
                      xmlns="http://www.w3.org/2000/svg"
                      className={`w-5 h-5 ${review.rating > starIndex ? "text-yellow-500" : "text-gray-300"}`}
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <path d="M12 2l3.09 6.26 6.91 1-5 4.85 1.18 6.91L12 15.27 7.82 21l1.18-6.91-5-4.85 6.91-1L12 2z" />
                    </svg>
                  ))}
                </div>
              </div>

              {/* Nhận xét */}
              <p className="text-gray-700">{review.review}</p>

              {/* Thời gian đánh giá */}
              <p className="text-sm text-gray-500 mt-2">
                {new Date(review.time).toLocaleString()}
              </p>
            </div>
))}
        </div>
      ) : (
        <p className="text-gray-600">Chưa có đánh giá nào cho chuyến đi này.</p>
      )}
    </div>
  );
};

export default CommentsForm;
