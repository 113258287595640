// import React, { useEffect, useState, useRef } from 'react';
// import { Bar, Line } from 'react-chartjs-2';

// const AdminDashboard = () => {
//     const chartRef1 = useRef(null);
//     const chartRef2 = useRef(null);

//     const [revenueData1, setRevenueData1] = useState({
//         labels: ['Tuyến 1', 'Tuyến 2', 'Tuyến 3', 'Tuyến 4'],
//         datasets: [{
//             label: 'Doanh thu (VNĐ)',
//             data: [10000000, 8000000, 15000000, 5000000],
//             backgroundColor: 'rgba(75, 192, 192, 0.2)',
//             borderColor: 'rgba(75, 192, 192, 1)',
//             borderWidth: 1,
//         }]
//     });

//     const [revenueData2, setRevenueData2] = useState({
//         labels: ['Tháng 1', 'Tháng 2', 'Tháng 3', 'Tháng 4', 'Tháng 5', 'Tháng 6'],
//         datasets: [{
//             label: 'Doanh thu (VNĐ)',
//             data: [5000000, 7000000, 9000000, 8000000, 12000000, 15000000],
//             fill: false,
//             borderColor: 'rgba(153, 102, 255, 1)',
//             tension: 0.1,
//         }]
//     });

//     useEffect(() => {
//         // Hủy biểu đồ khi component unmount
//         return () => {
//             if (chartRef1.current) {
//                 const chartInstance = chartRef1.current.chartInstance;
//                 if (chartInstance) {
//                     chartInstance.destroy();
//                 }
//             }
//             if (chartRef2.current) {
//                 const chartInstance = chartRef2.current.chartInstance;
//                 if (chartInstance) {
//                     chartInstance.destroy();
//                 }
//             }
//         };
//     }, []);

//     // Đoạn mã khác để cập nhật dữ liệu biểu đồ hoặc các thao tác khác

//     return (
//         <div>
//             <h1>Dashboard</h1>
//             <Bar key={revenueData1.datasets[0].data.join(',')} ref={chartRef1} data={revenueData1} />
//             <Line key={revenueData2.datasets[0].data.join(',')} ref={chartRef2} data={revenueData2} />
//         </div>
//     );
// };

// export default AdminDashboard;
import React from 'react';
import Header from '../Components/Header';
import Sidebar from '../Components/SideBar';
import MainContent from '../Components/MainContent';

const Dashboard = () => {
    return (
        <div className="flex h-screen">
            <Sidebar />
            <div className="flex-1 flex flex-col">
                <Header />
                <main className="flex-1 p-6 overflow-y-auto">
                    <MainContent />
                </main>
            </div>
        </div>
    );
};
export default Dashboard;
