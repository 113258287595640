
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

const BusInfo = () => {
    const [bus, setBus] = useState(null);
    const { id } = useParams(); // Assuming you have a bus ID in the URL

    useEffect(() => {
        // Fetch bus data from Laravel API
        fetch(`http://localhost:8000/api/trips/${id}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Error fetching bus data');
                }
                return response.json();
            })
            .then(data => setBus(data))
            .catch(error => console.error('Error fetching bus data:', error));
    }, [id]);

    if (!bus) return <div className="flex flex-col items-center justify-center h-screen">
    <div className="w-16 h-16 border-8 border-t-8 border-gray-200 border-t-green-700 rounded-full animate-spin"></div>
    <span className="mt-4 text-lg text-green-700">Loading...</span>
  </div>;

    // Kiểm tra xem bus.chitietxebuss có tồn tại không
    const busDetails = bus.chitietxebuss || {}; // Sử dụng đối tượng rỗng nếu không có

    return (
        <div className="p-6 border rounded-xl shadow-lg max-w-9xl mx-auto">
            <h2 className="text-2xl font-bold mb-6 text-center">Một số thông tin của xe</h2>
            <div className="flex flex-col lg:flex-row gap-8">
                {/* Image Column */}
                <div className="flex-shrink-0 w-full lg:w-1/3">
                    <img
                        src={busDetails.image}
                        alt="Bus"
                        className="w-full h-full object-cover rounded-lg shadow-md"
                    />
                </div>

                {/* Info Column */}
                <div className="flex-grow">
                    <p className="mb-4"><span className="font-semibold">Số biển xe buýt:</span> {busDetails.biensoxe || 'N/A'}</p>
                    <p className="mb-4"><span className="font-semibold">Tên xe:</span> {busDetails.tenxe || 'N/A'}</p>
                    <p className="mb-4"><span className="font-semibold">Số ghế:</span> {busDetails.sochongoi || 'N/A'}</p>
                    <p className="mb-4"><span className="font-semibold">Điểm khởi hành:</span> {bus.chitiettuyenduong.noixuatphat || 'N/A'}</p>
                    <p className="mb-4"><span className="font-semibold">Điểm đến:</span> {bus.chitiettuyenduong.noiden || 'N/A'}</p>
                    <p className="mb-4"><span className="font-semibold">Giá chuyến:</span> {bus.giachuyen} VND</p>
                   
                </div>
            </div>
        </div>
    );
};

export default BusInfo;
