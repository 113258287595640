import React, { useState } from 'react';
import Footer from '../Components/Footer';
import Header from '../Components/Header';

const CustomerSupport = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    question: '',
  });
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Giả lập việc gửi dữ liệu (ở đây bạn có thể kết nối với API)
    console.log('Submitting form', formData);
    setSubmitted(true);
    // Reset form
    setFormData({
      name: '',
      email: '',
      question: '',
    });
  };

  return (
    <>
    <Header/>
    <div className="bg-white p-8 rounded-lg shadow-md mt-10 max-w-4xl mx-auto">
      <h2 className="text-3xl font-bold text-center text-green-600 mb-6">Hỗ trợ khách hàng</h2>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Form Support */}
        <div>
          {submitted ? (
            <div className="text-center">
              <h3 className="text-lg font-semibold text-gray-800">Cảm ơn bạn!</h3>
              <p className="text-gray-600">Câu hỏi của bạn đã được gửi. Chúng tôi sẽ phản hồi sớm nhất có thể.</p>
            </div>
          ) : (
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2" htmlFor="name">Họ tên</label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  className="border border-gray-300 rounded-lg p-2 w-full"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Nhập tên bạn ở đây..."
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2" htmlFor="email">Email</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="border border-gray-300 rounded-lg p-2 w-full"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Nhập email của bạn ở đây..."
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2" htmlFor="question">Câu hỏi của bạn</label>
                <textarea
                  name="question"
                  id="question"
                  className="border border-gray-300 rounded-lg p-2 w-full"
                  rows="4"
                  value={formData.question}
                  onChange={handleChange}
                  placeholder="Câu hỏi bạn cần hỗ trợ..."
                  required
                ></textarea>
              </div>
              <button type="submit" className="bg-green-600 text-white py-2 px-4 rounded-lg w-full hover:bg-green-700">
                Gửi câu hỏi
              </button>
            </form>
          )}
        </div>

        {/* Contact Information */}
        <div className="flex flex-col justify-center items-center">
        <div className="pl-12">
            <img
              src="/images/1766-scaled.jpg" // Thay thế với đường dẫn hình ảnh thực tế
              alt="Hỗ trợ khách hàng"
              className="w-full h-50"
            />
          </div>
          <h3 className="text-lg font-semibold text-gray-800 mb-8">Thông tin liên hệ</h3>
          <p className="text-gray-600 mb-2"><b>Điện thoại:</b> <a href="tel:+84123456789" className="text-green-600">+84 123 456 789</a></p>
          <p className="text-gray-600 mb-2"><b>Email:</b> <a href="mailto:support@busfree.com" className="text-green-600">support@busfree.com</a></p>
        </div>
      </div>
    </div>
    <br></br>
    <Footer/>
    </>
    
  );
};

export default CustomerSupport;
