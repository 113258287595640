import React from 'react';

const ColorfulBanner = () => {
  return (
    <section className="bg-gray-100 text-gray-800 p-10 rounded-lg shadow-md max-w-[9000px] mx-auto my-6 text-center">
      <div className="banner-container">
        <h2 className="text-3xl font-bold mb-7">Lợi ích khi đặt vé tại website của chúng tôi</h2>
        <div className="grid grid-cols-4 gap-5 justify-items-center">
          <div className="benefit-item bg-red-500 text-white p-10 rounded-lg shadow-md flex flex-col items-center w-[320px]">
            <img src="https://img.icons8.com/color/48/time.png" alt="Đặt vé nhanh chóng" className="w-12 h-12 mb-2" />
            <p className="font-medium">Đặt vé nhanh chóng</p>
          </div>
          <div className="benefit-item bg-orange-400 text-white p-10 rounded-lg shadow-md flex flex-col items-center w-[320px]">
            <img src="https://img.icons8.com/color/48/discount.png" alt="Giảm giá cho sinh viên" className="w-12 h-12 mb-2" />
            <p className="font-medium">Giảm giá cho sinh viên</p>
          </div>
          <div className="benefit-item bg-green-500 text-white p-10 rounded-lg shadow-md flex flex-col items-center w-[320px]">
            <img src="https://img.icons8.com/color/48/check.png" alt="Xác nhận ngay lập tức" className="w-12 h-12 mb-2" />
            <p className="font-medium">Xác nhận ngay lập tức</p>
          </div>
          <div className="benefit-item bg-blue-500 text-white p-10 rounded-lg shadow-md flex flex-col items-center w-[320px]">
            <img src="https://img.icons8.com/color/48/customer-support.png" alt="Hỗ trợ 24/7" className="w-12 h-12 mb-2" />
            <p className="font-medium">Hỗ trợ khách hàng 24/7</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ColorfulBanner;
