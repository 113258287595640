
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from '../Components/SideBar';
import Header from '../Components/Header';

const UserManagement = () => {
  const [searchInput, setSearchInput] = useState('');
  const [roleFilter, setRoleFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userDetails, setUserDetails] = useState(null); // To store detailed user data
  const [isPopupOpen, setIsPopupOpen] = useState(false); // Control popup visibility
  const [notification, setNotification] = useState(null); // Trạng thái thông báo
  const [pagination, setPagination] = useState({
    current_page: 1,
    per_page: 10,
    total: 0,
  })

  const fetchUsers = async (page = 1) => {
    try {
      const token = localStorage.getItem('access_token');
      const response = await fetch(`http://localhost:8000/api/alluser?page=${page}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        setUsers(data.data); // Dữ liệu người dùng
        setPagination({
          current_page: data.current_page,
          per_page: data.per_page,
          total: data.total,
        });
      }
    } catch (error) {
      console.error('Lỗi khi lấy dữ liệu:', error);
    }
  };
  
  useEffect(() => {
    fetchUsers();
  }, []);

  const searchUsers = () => {
    return users.filter(user => {
      const matchesNameOrEmail = 
        user.ten.replace(/\s+/g, '').toLowerCase().includes(searchInput.toLowerCase()) ||
        user.email.toLowerCase().includes(searchInput.toLowerCase()); // Tìm theo tên hoặc email
      const matchesRole = 
        roleFilter === '' || 
        (user.vaitro === 1 ? 'admin' : 'sinhvien') === roleFilter.toLowerCase();
      const matchesStatus = 
        statusFilter === '' || 
        (statusFilter.toLowerCase() === 'đang hoạt động' && user.anhien === 1) || 
        (statusFilter.toLowerCase() === 'bị khóa' && user.anhien === 3);
  
      return matchesNameOrEmail && matchesRole && matchesStatus;
    });
  };
  

  const fetchUserDetails = async (userId) => {
    try {
      const token = localStorage.getItem('access_token');
      const response = await fetch(`http://localhost:8000/api/use/${userId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setUserDetails(data.user); // Store detailed user data
        setIsPopupOpen(true); // Open popup
      } else {
        console.error('Không thể lấy chi tiết người dùng');
      }
    } catch (error) {
      console.error('Lỗi khi lấy chi tiết người dùng:', error);
    }
  };
  const toggleUserLock = async (userId, currentStatus) => {
    try {
      const token = localStorage.getItem('access_token');
      const response = await fetch(`http://localhost:8000/api/userlock/${userId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          anhien: currentStatus === 1 ? 3 : 1, // Chuyển đổi trạng thái
        }),
      });
  
      if (response.ok) {
        const updatedUsers = users.map((user) =>
          user.id === userId ? { ...user, anhien: currentStatus === 1 ? 3 : 1 } : user
        );
        setUsers(updatedUsers);
        setNotification('Cập nhật trạng thái thành công!'); // Đặt thông báo
        setTimeout(() => setNotification(null), 3000); // Tự động ẩn sau 3 giây
      } else {
        console.error('Không thể cập nhật trạng thái');
      }
    } catch (error) {
      console.error('Lỗi khi cập nhật trạng thái:', error);
    }
  };
  const changeUserRole = async (userId, currentRole) => {
    try {
      const token = localStorage.getItem('access_token');
      const response = await fetch(`http://localhost:8000/api/usersRole/${userId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          vaitro: currentRole === 1 ? 0 : 1, // Chuyển đổi giữa Admin (1) và Sinh viên (2)
        }),
      });
  
      if (response.ok) {
        const updatedUsers = users.map(user =>
          user.id === userId ? { ...user, vaitro: currentRole === 1 ? 0 : 1 } : user
        );
        setUsers(updatedUsers);
        setNotification('Cập nhật vai trò thành công!');
        setTimeout(() => setNotification(null), 3000);
      } else {
        console.error('Không thể cập nhật vai trò');
      }
    } catch (error) {
      console.error('Lỗi khi cập nhật vai trò:', error);
    }
  };
  
  return (
    <div className="flex h-screen">
      <Sidebar />
      <div className="flex-1 flex flex-col">
        <Header />
        {notification && (
  <div className="fixed top-4 right-4 flex items-center gap-3 bg-green-600 text-white px-4 py-3 rounded-lg shadow-lg border-l-4">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6 flex-shrink-0"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9 12l2 2 4-4m5 4a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
    <span className="text-sm font-semibold">{notification}</span>
  </div>
)}


        <section className="flex-1 p-8 overflow-y-auto">
          <h2 className="text-2xl font-bold text-center mb-4">Danh Sách Người Dùng</h2>

          {/* Search and filter */}
          <div className="mb-4 flex justify-between items-center">
          <div className="relative w-1/2">
            <input
              type="text"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              placeholder="Tìm kiếm người dùng..."
              className="border border-gray-300 rounded-md p-2 pl-10 w-full focus:outline-none focus:ring focus:ring-blue-300"
            />
            <i
              className="fas fa-search text-gray-500 absolute left-3 top-1/2 transform -translate-y-1/2"
            ></i>
          </div>

            <select
              value={roleFilter}
              onChange={(e) => setRoleFilter(e.target.value)}
              className="border border-gray-300 rounded-md p-2 ml-2 focus:outline-none focus:ring focus:ring-blue-300"
            >
              <option value="">Tất cả vai trò</option>
              <option value="admin">Admin</option>
              <option value="sinhvien">Sinh viên</option>
            </select>
            <select
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
              className="border border-gray-300 rounded-md p-2 ml-2 focus:outline-none focus:ring focus:ring-blue-300"
            >
              <option value="">Tất cả trạng thái</option>
              <option value="đang hoạt động">Đang hoạt động</option>
              <option value="bị khóa">Bị khóa</option>
            </select>
          </div>

          {/* User table */}
          <div className="bg-white shadow-lg rounded-lg p-8">
            <div className="overflow-x-auto">
              <table className="min-w-full border border-gray-300">
                <thead className="bg-gray-200">
                  <tr>
                    <th className="border border-gray-300 px-4 py-2">Tên</th>
                    <th className="border border-gray-300 px-4 py-2">Hình ảnh</th>
                    <th className="border border-gray-300 px-4 py-2">Email</th>
                    <th className="border border-gray-300 px-4 py-2">Quyền</th>
                    <th className="border border-gray-300 px-4 py-2">Trạng thái</th>
                    <th className="border border-gray-300 px-4 py-2">Hành động</th>
                  </tr>
                </thead>
                <tbody>
                  {searchUsers().map((user) => (
                    <tr key={user.id}>
                      <td className="border border-gray-300 px-4 py-2">{user.ten}</td>
                      <td className="border border-gray-300 px-4 py-2">
                      {user.img ? (
                        <img 
                          src={user.img} 
                          alt="User Avatar" 
                          className="w-16 h-16 rounded-full object-cover mx-auto"
                        />
                      ) : (
                        <span className="text-gray-500 italic">Không có ảnh</span>
                      )}
                    </td>
                      <td className="border border-gray-300 px-4 py-2">{user.email}</td>
                      <td className="border border-gray-300 px-4 py-2">{user.vaitro === 1 ? 'Admin' : 'Sinh viên'}</td>
                      <td className="border border-gray-300 px-4 py-2">{user.anhien === 1 ? 'Đang hoạt động' : 'Bị khóa'}</td>
                      <td className="border border-gray-300 px-4 py-2 text-center">
  <div className="flex items-center justify-center space-x-3">
    {/* Nút xem chi tiết */}
    <button
      className="bg-gradient-to-r from-green-500 to-green-600 text-white hover:from-green-600 hover:to-green-700 font-semibold focus:outline-none focus:ring-4 focus:ring-green-300 rounded-md p-2 transform transition duration-300 ease-in-out hover:scale-95 shadow-sm hover:shadow-md"
      onClick={() => fetchUserDetails(user.id)}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M1 12s3-7 11-7 11 7 11 7-3 7-11 7-11-7-11-7z"
        />
        <circle
          cx="12"
          cy="12"
          r="3"
          stroke="currentColor"
          strokeWidth="2"
          fill="none"
        />
      </svg>
    </button>

    {/* Nút khóa/mở khóa */}
    <button
      className={`${
        user.anhien === 3 ? 'bg-red-500 hover:bg-red-600' : 'bg-blue-500 hover:bg-blue-600'
      } text-white rounded-full p-2 focus:outline-none focus:ring-5 ${
        user.anhien === 1 ? 'focus:ring-red-300' : 'focus:ring-blue-300'
      } transform transition duration-300 ease-in-out hover:scale-95 shadow-sm hover:shadow-md`}
      onClick={() => toggleUserLock(user.id, user.anhien)}
    >
      {user.anhien === 3 ? (
        <i className="fas fa-lock h-5 w-5"></i>
      ) : (
        <i className="fas fa-unlock h-5 w-5"></i>
      )}
    </button>

    {/* Nút thay đổi vai trò */}
    <button
      className="bg-red-600 text-white px-4 py-2 rounded-lg shadow-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-700 transition"
      onClick={() => changeUserRole(user.id, user.vaitro)}
    >
      {user.vaitro === 1 ? 'Sinh viên' : 'Admin'}
    </button>
  </div>
</td>


                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="flex justify-center mt-4">
  <button
    className="bg-gray-300 px-4 py-2 rounded-md mx-2"
    onClick={() => fetchUsers(pagination.current_page - 1)}
    disabled={pagination.current_page === 1}
  >
    Trước
  </button>
  <span>
    Trang {pagination.current_page} / {Math.ceil(pagination.total / pagination.per_page)}
  </span>
  <button
    className="bg-gray-300 px-4 py-2 rounded-md mx-2"
    onClick={() => fetchUsers(pagination.current_page + 1)}
    disabled={pagination.current_page === Math.ceil(pagination.total / pagination.per_page)}
  >
    Sau
  </button>
</div>

        </section>
        
      </div>
     
      {/* User details popup */}
      {isPopupOpen && userDetails && (
  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
    <div className="bg-white rounded-lg shadow-lg p-8 w-1/2">
      <h3 className="text-2xl font-bold mb-4 text-center">Chi Tiết Người Dùng</h3>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Cột 1: Hình ảnh người dùng */}
        <div className="flex justify-center items-center">
          <img
            src={userDetails.img || 'default_image_path.jpg'} // Sử dụng ảnh mặc định nếu không có
            alt="User Avatar"
            className="w-40 h-40 object-cover rounded-lg border-4 border-gray-300 shadow-lg"
          />
        </div>
        
        {/* Cột 2: Thông tin chi tiết */}
        <div>
          <p className="text-gray-700 mb-2"><strong>Họ và tên:</strong> {userDetails.ten}</p>
          <p className="text-gray-700 mb-2"><strong>Email:</strong> {userDetails.email}</p>
          <p className="text-gray-700 mb-2"><strong>Số điện thoại:</strong> {userDetails.sdt}</p>
          <p className="text-gray-700 mb-2"><strong>Giới tính:</strong> {userDetails.gioitinh || 'Chưa cập nhật'}</p>
          <p className="text-gray-700 mb-2"><strong>Địa chỉ:</strong> {userDetails.diachi || 'Chưa cập nhật'}</p>
          <p className="text-gray-700"><strong>Mô tả:</strong> {userDetails.mota || 'Chưa có mô tả'}</p>
        </div>
      </div>
      
      {/* Nút đóng */}
      <div className="flex justify-center mt-6">
        <button
          className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
          onClick={() => setIsPopupOpen(false)}
        >
          Đóng
        </button>
        
      </div>
    </div>
  </div>
)}

    </div>
  );
};

export default UserManagement;
