import React from 'react';

const Partners = () => {
  return (
    <section className="partners text-center py-10 px-5 bg-white shadow-md">
      <h2 className="text-3xl text-gray-800 font-bold mb-10">ĐỐI TÁC CỦA BUSFREE</h2>
      <div className="partner-logos flex justify-around flex-wrap mx-[-10px]">
        <div className="partner flex-1 flex-shrink-0 max-w-[220px] mx-2 text-center border-2 border-green-600 rounded-lg p-2 transition-transform duration-300 hover:translate-y-[-5px] hover:shadow-lg">
          <img src="images/HoangLong.webp" alt="Hoang Long" className="w-[50%] h-auto mb-2" />
          <p className="text-base text-gray-600 font-bold">Hoang Long</p>
        </div>
        <div className="partner flex-1 flex-shrink-0 max-w-[220px] mx-2 text-center border-2 border-green-600 rounded-lg p-2 transition-transform duration-300 hover:translate-y-[-5px] hover:shadow-lg">
          <img src="images/HanhCafe.webp" alt="Hanh Cafe" className="w-[50%] h-auto mb-2" />
          <p className="text-base text-gray-600 font-bold">Hanh Cafe</p>
        </div>
        <div className="partner flex-1 flex-shrink-0 max-w-[220px] mx-2 text-center border-2 border-green-600 rounded-lg p-2 transition-transform duration-300 hover:translate-y-[-5px] hover:shadow-lg">
          <img src="images/LongVanLImousine.webp" alt="Long Van Limousine" className="w-[50%] h-auto mb-2" />
          <p className="text-base text-gray-600 font-bold">Long Van Limousine</p>
        </div>
        <div className="partner flex-1 flex-shrink-0 max-w-[220px] mx-2 text-center border-2 border-green-600 rounded-lg p-2 transition-transform duration-300 hover:translate-y-[-5px] hover:shadow-lg">
          <img src="images/LuxuryTrans.webp" alt="LuxuryTrans" className="w-[50%] h-auto mb-2" />
          <p className="text-base text-gray-600 font-bold">LuxuryTrans</p>
        </div>
        <div className="partner flex-1 flex-shrink-0 max-w-[220px] mx-2 text-center border-2 border-green-600 rounded-lg p-2 transition-transform duration-300 hover:translate-y-[-5px] hover:shadow-lg">
          <img src="images/TuanNga.webp" alt="Ben Tre Limousine" className="w-[50%] h-auto mb-2" />
          <p className="text-base text-gray-600 font-bold">Ben Tre Limousine</p>
        </div>
        <div className="partner flex-1 flex-shrink-0 max-w-[220px] mx-2 text-center border-2 border-green-600 rounded-lg p-2 transition-transform duration-300 hover:translate-y-[-5px] hover:shadow-lg">
          <img src="images/Futa.webp" alt="Futa" className="w-[50%] h-auto mb-2" />
          <p className="text-base text-gray-600 font-bold">Futa</p>
        </div>
      </div>
    </section>
  );
};

export default Partners;

// import React, { useEffect, useState } from 'react';

// const Partners = () => {
//   const [partners, setPartners] = useState([]);

//   useEffect(() => {
//     // Fetch the partners data from Laravel backend
//     fetch('http://localhost:8000/api/partners')
//       .then((response) => response.json())
//       .then((data) => setPartners(data))
//       .catch((error) => console.error('Error fetching partners:', error));
//   }, []);

//   return (
//     <section className="partners text-center py-10 px-5 bg-white shadow-md">
//       <h2 className="text-3xl text-gray-800 font-bold mb-10">ĐỐI TÁC CỦA BUSFREE</h2>
//       <div className="partner-logos flex justify-around flex-wrap mx-[-10px]">
//         {partners.map((partner) => (
//           <div
//             key={partner.id}
//             className="partner flex-1 flex-shrink-0 max-w-[220px] mx-2 text-center border-2 border-green-600 rounded-lg p-2 transition-transform duration-300 hover:translate-y-[-5px] hover:shadow-lg"
//           >
//             <img src={`images/${partner.image}`} alt={partner.name} className="w-[50%] h-auto mb-2" />
//             <p className="text-base text-gray-600 font-bold">{partner.name}</p>
//           </div>
//         ))}
//       </div>
//     </section>
//   );
// };

// export default Partners;
