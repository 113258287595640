import React, { useState, useEffect } from "react";
import axios from "axios";
import Sidebar from "../Components/SideBar";
import Header from "../Components/Header";

const BusManagement = ({ categoryId }) => {
  const [buses, setBuses] = useState([]);
  
  const [categories, setCategories] = useState(
    {
      mausac:"",
      trangthai:""
    }
  );
  const [successMessage, setSuccessMessage] = useState(""); // State for success message
  const [categoryDetails, setCategoryDetails] = useState([]);
  const [colors, setColors] = useState([]); // Lưu danh sách màu sắc (id = 1)
  const [statuses, setStatuses] = useState([]); // Lưu danh sách trạng thái (id = 2)
  const [detailId, setDetailId] = useState(null);
  const [busData, setBusData] = useState({
    id: "",
    tenxe: "",
    biensoxe: "",
    img: "",
    mausac: "",
    trangthai: "",
    sochongoi: "",
    anhien: "",
    mota: "",
  });
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [deletedBuses, setDeletedBuses] = useState([]);
  useEffect(() => {
    const fetchCategoryDetail = async () => {
      try {
        const token = localStorage.getItem("access_token");
  
        // Gọi API để lấy chi tiết của category
        const response = await axios.get(
          `http://localhost:8000/api/category/${categoryId}/details/${detailId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
  
        console.log("Chi tiết của category:", response.data);
        setCategoryDetails(response.data);  // Lưu dữ liệu chi tiết của category vào state
      } catch (error) {
        console.error("Lỗi khi lấy chi tiết category:", error);
      }
    };
  
    fetchCategoryDetail();
  }, [categoryId, detailId]); // Lấy dữ liệu khi categoryId hoặc detailId thay đổi
  
  useEffect(() => {
    const fetchDeletedRoutes = async () => {
      try {
        const token = localStorage.getItem('access_token'); // Lấy token từ localStorage hoặc nơi lưu trữ khác
        const response = await axios.get(`http://localhost:8000/api/bussDelete?page=${currentPage}`, {
          headers: {
            Authorization: `Bearer ${token}`,  // Gửi token trong header
          },
        });
        console.log("Dữ liệu tuyến đường đã xóa: ", response.data);
        setDeletedBuses(response.data.data); // Giả sử dữ liệu trả về có cấu trúc { data: [...], meta: { total_pages: ... } }
        setTotalPages(response.data.meta.total_pages); // Lấy tổng số trang từ API
      } catch (error) {
        console.error('Lỗi khi lấy danh sách tuyến đường đã xóa:', error);
        if (error.response && error.response.status === 401) {
          alert("Phiên làm việc hết hạn, vui lòng đăng nhập lại.");
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchDeletedRoutes();
  }, [currentPage]); 
 
  // Hàm lọc danh sách xe buýt theo tên
  const filteredBuses = buses.filter(bus =>
    bus.tenxe.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleRestore = async (id) => {
    try {
      const token = localStorage.getItem('access_token'); // Lấy token từ localStorage (hoặc từ nơi lưu trữ khác)
      
      // Thêm token vào header của yêu cầu PATCH
      await axios.patch(
        `http://localhost:8000/api/buss/${id}/restore`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`, // Thêm Authorization header với Bearer token
          },
        }
      );
  
      alert('Tuyến đường đã được khôi phục!');
      setDeletedBuses(deletedBuses.filter((bus) => bus.id !== id));
    } catch (error) {
      console.error('Lỗi khi khôi phục tuyến đường:', error);
      alert('Không thể khôi phục tuyến đường. Vui lòng thử lại!');
    }
  };
  useEffect(() => {
    const fetchAllCategoryDetails = async () => {
      try {
        const token = localStorage.getItem("access_token");

        // Fetch tất cả categories
        const categoriesResponse = await axios.get("http://localhost:8000/api/category", {
          headers: { Authorization: `Bearer ${token}` },
        });

        const categories = categoriesResponse.data;

        // Fetch chi tiết của từng category
        const detailsPromises = categories.map(async (category) => {
          const response = await axios.get(
            `http://localhost:8000/api/category/${category.id}/details`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          
          return { categoryId: category.id, details: response.data };
        });
        const allDetails = await Promise.all(detailsPromises);

        // Lưu dữ liệu vào state
        setCategoryDetails(allDetails);

        // Tách dữ liệu theo id
        const colorsData = allDetails.find((item) => item.categoryId === 1)?.details || [];
        const statusesData = allDetails.find((item) => item.categoryId === 2)?.details || [];
        console.log("Màu sắc:", colors);
        setColors(colorsData);
        setStatuses(statusesData);
      } catch (error) {
        console.error("Error fetching category details:", error);
      }
    };

    fetchAllCategoryDetails();
  }, []);
  
  useEffect(() => {
    // Fetch danh sách xe buýt theo trạng thái từ đường dẫn URL
    const fetchBusesByStatus = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("access_token");
        // Thêm status_id vào đường dẫn URL
        const response = await axios.get(`http://localhost:8000/api/buss?status_id=${statusFilter}&page=${currentPage}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
  
        setBuses(response.data.data); // Cập nhật danh sách xe buýt
        setCurrentPage(response.data.current_page); // Trang hiện tại
        setTotalPages(response.data.last_page); // Tổng số trang
        setBusData({
          ...busData,
          img: busData.img, // Lưu URL hoặc base64 của ảnh vào state
        });
      } catch (error) {
        console.error("Lỗi khi lấy danh sách xe buýt theo trạng thái:", error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchBusesByStatus();
  }, [currentPage, statusFilter]);
  
  
  
  // Phương thức phân trang
  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  
  // Thêm các hàm xử lý thêm và cập nhật xe buýt

  const handleAddBus = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    try {
      const token = localStorage.getItem("access_token");
      const formData = new FormData();
      
      // Thêm các trường vào FormData
      for (const key in busData) {
        if (key === "img" && busData.img) {
          formData.append(key, busData.img); // Thêm tệp ảnh vào FormData
        } else if (busData[key] !== null && busData[key] !== "") {
          formData.append(key, busData[key]); // Thêm các trường còn lại vào FormData
        }
      }
  
      const response = await axios.post("http://localhost:8000/api/buss", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
  
      if (response.status === 201) {
        alert("Thêm xe buýt thành công!");
        setIsPopupOpen(false);
        setCurrentPage(1); // Làm mới danh sách xe
      }
    } catch (error) {
      console.error("Lỗi khi thêm xe buýt:", error.response?.data || error);
      alert("Đã xảy ra lỗi: " + JSON.stringify(error.response?.data || error));
    } finally {
      setLoading(false);
    }
  };
  
  
  
  const handleUpdateBus = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    try {
      const token = localStorage.getItem("access_token");
      const { id, tenxe, biensoxe, mausac, trangthai, sochongoi, anhien, mota, img } = busData;
      const updatePayload = new FormData(); // Use FormData to send files
  
      // Append data to FormData
      updatePayload.append("tenxe", tenxe);
      updatePayload.append("biensoxe", biensoxe);
      updatePayload.append("mausac", mausac);
      updatePayload.append("trangthai", trangthai);
      updatePayload.append("sochongoi", sochongoi);
      updatePayload.append("anhien", anhien);
      updatePayload.append("mota", mota);
      // Only append the image file if it exists
      if (img) {
        updatePayload.append("img", img); // Append the file as part of the form data
      }
  
      console.log("Update Payload:", updatePayload);
  
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data", // Make sure content-type is multipart/form-data for file upload
      };
  
      const response = await axios.post(
        `http://localhost:8000/api/buss/${id}`,
        updatePayload,
        { headers }
      );
  
      if (response.status === 200) {
        alert("Cập nhật xe buýt thành công!");
        setIsPopupOpen(false);
        setCurrentPage(1); // Refresh the list after update
        setBusData({
          id: "",
          tenxe: "",
          biensoxe: "",
          img: "",
          mausac: "",
          trangthai: "",
          sochongoi: "",
          anhien: "",
          mota: "",
        });
  
        // Return early to prevent further code execution
        return; // Prevent further processing
      }
    } catch (error) {
      console.error("Error updating bus:", error.response?.data || error.message);
      alert("Lỗi khi cập nhật xe buýt.");
    } finally {
      setLoading(false);
    }
  };
  
  
  
  const handleEditBus = (bus) => {
    setBusData({
      id: bus.id,
      tenxe: bus.tenxe,
      biensoxe: bus.biensoxe,
      img: bus.img,
      mausac: bus.mausac,
      trangthai: bus.trangthai,
      sochongoi: bus.sochongoi,
      anhien: bus.anhien,
      mota: bus.mota,
      _method:"PUT"
    });
    setIsPopupOpen(true);

    
  };
  const handleDeleteBus = async (busId) => {
    if (!window.confirm("Bạn có chắc chắn muốn xóa xe buýt này?")) {
      return;
    }
  
    try {
      const token = localStorage.getItem("access_token");
      const response = await axios.delete(`http://localhost:8000/api/buss/${busId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response.status === 200) {
        alert("Xóa xe buýt thành công!");
        setBuses((prevBuses) => prevBuses.filter((bus) => bus.id !== busId)); // Cập nhật danh sách xe buýt
      }
    } catch (error) {
      console.error("Lỗi khi xóa xe buýt:", error.response?.data || error.message);
      alert("Đã xảy ra lỗi: " + JSON.stringify(error.response?.data || error));
    }
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value); // Cập nhật giá trị tìm kiếm khi người dùng thay đổi ô nhập liệu
  };

const handleInputChange = (e) => {
  const { name, value, type, files } = e.target;
  if (type === "file" && files.length > 0) {
    setBusData({ ...busData, [name]: files[0] });
  } else {
    setBusData({ ...busData, [name]: value });
  }
};
const handleImageChange = (e) => {
  const file = e.target.files[0]; // Get the selected file
  if (file) {
    setBusData({ ...busData, img: file }); // Update the busData with the selected image file
  }
};

  return (
    <div className="flex h-screen">
      <Sidebar />
      <div className="flex-1 flex flex-col">
        <Header />
        <div className="p-4 overflow-y-auto">
          <h1 className="text-2xl font-bold mb-4">Quản lý Xe Buýt</h1>

          {/* Search and Filter */}
          <div className="mb-4 flex items-center space-x-4">
          <div className="relative">
          <input
                type="text"
                placeholder="Tìm kiếm theo tên hoặc biển số..."
                className="border px-4 py-2 rounded w-[650px] pl-10"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            <i className="fas fa-search absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500"></i>  {/* Icon tìm kiếm */}
          </div>

          <select
          id="statusFilter"
          value={statusFilter}
          onChange={(e) => setStatusFilter(e.target.value)}
          className="p-2 border rounded"
        >
          <option value="">Tất cả</option>
          <option value="21">Đang hoạt động</option>
          <option value="22">Không hoạt động</option>
        </select>


            <button
          onClick={() => {
            setBusData({
              id: "", // Đảm bảo `id` là null để phân biệt thêm và cập nhật
              tenxe: "",
              biensoxe: "",
              img: "",
              mausac: "",
              trangthai: "",
              sochongoi: "",
              anhien: "",
              mota: "",
            });
            setIsPopupOpen(true);
          }}
          className="bg-green-500 text-white px-4 py-2 rounded flex items-center gap-2"
        >
          <i className="fas fa-plus text-lg"></i> {/* Icon Cộng */}
          <span className="text-sm">Thêm xe buýt</span>
        </button>
          </div>

          {/* Table of buses */}
          <table className="min-w-full table-auto border-collapse border border-gray-300">
            <thead>
              <tr>
                <th className="px-4 py-2 border border-gray-300">ID</th>
                <th className="px-4 py-2 border border-gray-300">Tên Xe</th>
                <th className="px-4 py-2 border border-gray-300">Hình ảnh</th>
                <th className="px-4 py-2 border border-gray-300">Biển số xe</th>
                <th className="px-4 py-2 border border-gray-300">Số Ghế</th>
                <th className="px-4 py-2 border border-gray-300">Màu sắc</th>
                <th className="px-4 py-2 border border-gray-300">Trạng Thái</th>
                <th className="px-4 py-2 border border-gray-300">Hành Động</th>
              </tr>
            </thead>
            <tbody>
              {filteredBuses.map((bus) => (
                <tr key={bus.id}>
                  <td className="px-4 py-2 border border-gray-300">{bus.id}</td>
                  <td className="px-4 py-2 border border-gray-300">{bus.tenxe}</td>
                  <td className="px-4 py-2 border border-gray-300"><img
                  src={bus.img}
                  className="w-20 h-20 rounded-full object-cover border-2 border-gray-200"
                /></td>
                  <td className="px-4 py-2 border border-gray-300">{bus.biensoxe}</td>
                  <td className="px-4 py-2 border border-gray-300">{bus.sochongoi}</td>
                  <td className="px-4 py-2 border border-gray-300">{bus.mausac}</td>
                  <td className="px-4 py-2 border border-gray-300">
                  {bus.trangthai}
                </td>

                  <td className="px-4 py-2 border border-gray-300 text-center">
                  {/* Nút Sửa với nền và icon Font Awesome */}
                  <button
                    className="bg-blue-500 text-white rounded-lg p-2 hover:bg-blue-600 transition"
                    onClick={() => handleEditBus(bus)}
                  >
                    <i className="fas fa-edit"></i> {/* Icon Sửa */}
                  </button>

                  {/* Nút Xóa với nền và icon Font Awesome */}
                  <button
                    className="bg-red-500 text-white rounded-lg p-2 ml-2 hover:bg-red-600 transition"
                    onClick={() => handleDeleteBus(bus.id)}
                  >
                    <i className="fas fa-trash"></i> {/* Icon Xóa */}
                  </button>
                </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Pagination */}
          <div className="mt-4 flex justify-between items-center">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="px-4 py-2 bg-gray-300 rounded"
            >
              Previous
            </button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="px-4 py-2 bg-gray-300 rounded"
            >
              Next
            </button>
          </div>
         
          {isPopupOpen && (
            
  <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center z-50">
     {successMessage && (
        <div className="fixed top-4 left-1/2 transform -translate-x-1/2 bg-green-500 text-white px-6 py-3 rounded-md shadow-lg z-50">
          {successMessage}
        </div>
      )}
    <div className="bg-white p-4 rounded-lg shadow-lg w-full max-w-xl">
      {/* Tiêu đề */}
      <h2 className="text-lg font-semibold mb-4 text-gray-800 text-center">
        {busData.id ? "Cập nhật Xe Buýt" : "Thêm Xe Buýt"}
      </h2>

      {/* Form */}
      <form onSubmit={busData.id ? handleUpdateBus : handleAddBus} className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        {/* Tên Xe */}
        <div>
          <label className="block mb-1 font-semibold text-gray-700">Tên Xe</label>
          <input
            type="text"
            name="tenxe"
            value={busData.tenxe}
            onChange={handleInputChange}
            className="border border-gray-300 rounded-md p-2 w-full focus:ring-2 focus:ring-blue-500 focus:outline-none"
            placeholder="Nhập tên xe"
            required
            minLength={3}
            title="Tên xe phải có ít nhất 3 ký tự."
          />
        </div>

        {/* Biển Số Xe */}
        <div>
          <label className="block mb-1 font-semibold text-gray-700">Biển Số Xe</label>
          <input
            type="text"
            name="biensoxe"
            value={busData.biensoxe}
            onChange={handleInputChange}
            className="border border-gray-300 rounded-md p-2 w-full focus:ring-2 focus:ring-blue-500 focus:outline-none"
            placeholder="Nhập biển số xe"
            required
            pattern="^[A-Z0-9\-]{1,10}$"
            title="Biển số xe chỉ được chứa chữ in hoa, số, và dấu gạch ngang (-), tối đa 10 ký tự."
          />
        </div>

        {/* Số Ghế */}
        <div>
          <label className="block mb-1 font-semibold text-gray-700">Số Ghế</label>
          <input
            type="number"
            name="sochongoi"
            value={busData.sochongoi}
            onChange={handleInputChange}
            className="border border-gray-300 rounded-md p-2 w-full focus:ring-2 focus:ring-blue-500 focus:outline-none"
            placeholder="Nhập số chỗ ngồi"
            required
            min={1}
            max={100}
            title="Số chỗ ngồi phải từ 1 đến 100."
          />
        </div>
        {/* Màu Sắc */}
        <div>
          <label className="block mb-1 font-semibold text-gray-700">Màu Sắc</label>
          <select
            name="mausac"
            value={busData.mausac}
            required
            onChange={handleInputChange}
            className="border border-gray-300 rounded-md p-2 w-full focus:ring-2 focus:ring-blue-500 focus:outline-none"
          >
            <option name="mausac" value="">Chọn màu sắc</option>
            {colors.map((color) => (
              <option key={color.id} value={color.id}>
                {color.name}
              </option>
            ))}
          </select>
        </div>

        {/* Trạng Thái */}
        <div>
          <label className="block mb-1 font-semibold text-gray-700">Trạng Thái</label>
          <select
            name="trangthai"
            value={busData.trangthai}
            required
            onChange={(e) => setBusData({ ...busData, trangthai: e.target.value })}
            className="border border-gray-300 rounded-md p-2 w-full focus:ring-2 focus:ring-blue-500 focus:outline-none"
          >
            <option name="trangthai" value="">Chọn trạng thái</option>
            {statuses.map((status) => (
              <option key={status.id} value={status.id}>
                {status.name}
              </option>
            ))}
          </select>
        </div>
        {/* Ảnh Xe Buýt (Image Upload) */}
        <div className="col-span-2">
          <label className="block mb-1 font-semibold text-gray-700">Ảnh Xe Buýt</label>
          <input
            type="file"
            name="img"
            accept="image/*"
            onChange={handleImageChange} // Make sure this function handles the image selection
            className="border border-gray-300 rounded-md p-2 w-full focus:ring-2 focus:ring-blue-500 focus:outline-none"
          />
        </div>
        {/* Ẩn/Hiện */}
        <div>
          <label className="block mb-1 font-semibold text-gray-700">Ẩn/Hiện</label>
          <select
            name="anhien"
            value={busData.anhien}
            onChange={(e) => setBusData({ ...busData, anhien: e.target.value })}
            className="border border-gray-300 rounded-md p-2 w-full focus:ring-2 focus:ring-blue-500 focus:outline-none"
            required
          >
            <option value="">Chọn trạng thái</option>
            <option value="1">Hiện</option>
            <option value="0">Ẩn</option>
          </select>
        </div>

        {/* Mô Tả */}
        <div className="col-span-2">
          <label className="block mb-1 font-semibold text-gray-700">Mô Tả</label>
          <textarea
            name="mota"
            value={busData.mota}
            onChange={handleInputChange}
            className="border border-gray-300 rounded-md p-2 w-full h-20 focus:ring-2 focus:ring-blue-500 focus:outline-none"
            placeholder="Nhập mô tả"
          ></textarea>
        </div>

        {/* Buttons */}
      <div className="flex justify-end gap-4 col-span-2">
        {/* Nút Hủy với icon */}
        <button
          type="button"
          onClick={() => setIsPopupOpen(false)}
          className="px-6 py-3 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500 transition flex items-center gap-3"
        >
          <i className="fas fa-times text-lg"></i> {/* Icon Hủy */}
          <span className="text-sm font-medium">Hủy</span>
        </button>

        {/* Nút Cập Nhật/Thêm với icon */}
        <button
          type="submit"
          className="px-6 py-3 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 transition flex items-center gap-3"
        >
          <i className="fas fa-save text-lg"></i> {/* Icon Cập nhật */}
          <span className="text-sm font-medium">{busData.id ? "Cập Nhật" : "Thêm"}</span>
        </button>
      </div>
      </form>
    </div>
  </div>
)}
{/* Deleted Routes Section */}
<div className="p-4 bg-gray-100">
        <h1 className="text-2xl font-semibold text-gray-700 mb-4">Danh sách xe bus đã xóa</h1>
        {isLoading ? (
          <div className="flex justify-center items-center h-32">
            <div className="animate-spin rounded-full h-10 w-10 border-t-4 border-blue-500"></div>
          </div>
        ) : deletedBuses.length === 0 ? (
          <p className="text-gray-500">Không có dữ liệu</p>
        ) : (
          <table className="min-w-full table-auto bg-white shadow-lg rounded-lg">
            <thead className="bg-gray-800 text-white">
              <tr>
              <th className="px-4 py-2 border border-gray-300">ID</th>
                <th className="px-4 py-2 border border-gray-300">Tên Xe</th>
                <th className="px-4 py-2 border border-gray-300">Biển số xe</th>
                <th className="px-4 py-2 border border-gray-300">Số Ghế</th>
                <th className="px-4 py-2 border border-gray-300">Màu sắc</th>
                <th className="px-4 py-2 border border-gray-300">Trạng Thái</th>
                <th className="px-4 py-2 border border-gray-300">Hành Động</th>
              </tr>
            </thead>
            <tbody className="text-gray-700">
              {deletedBuses.map((bus) => (
                <tr key={bus.id} className="border-b hover:bg-gray-50">
                  <td className="px-4 py-2 border border-gray-300">{bus.id}</td>
                  <td className="px-4 py-2 border border-gray-300">{bus.tenxe}</td>
                  <td className="px-4 py-2 border border-gray-300">{bus.biensoxe}</td>
                  <td className="px-4 py-2 border border-gray-300">{bus.sochongoi}</td>
                  <td className="px-4 py-2 border border-gray-300">{bus.mausac}</td>
                  <td>
                    {statuses.find((status) => status.id === bus.trangthai)?.name ||
                      "Không xác định"}
                  </td>
                  <td className="px-4 py-3 flex justify-center space-x-3">
                  <button
                        className="bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600 flex items-center justify-center"
                        onClick={() => handleRestore(bus.id)}
                      >
                        <i className="fas fa-undo"></i>
                      </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
        </div>



      </div>
    </div>
  );
};

export default BusManagement;
