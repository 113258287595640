import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { TrashIcon } from '@heroicons/react/20/solid';
import { PencilIcon , ArrowUturnLeftIcon } from '@heroicons/react/20/solid';
import { toast } from 'react-toastify'; 
import Sidebar from '../Components/SideBar';
import Header from '../Components/Header';
function TripsManagement() {
  const [trips, setTrips] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTrip, setSelectedTrip] = useState(null);
  const [editTrip, setEditTrip] = useState(null);
  const [createTrip, setCreateTrip] = useState(false); // New state to control the "Add New Trip" form visibility
  const [deletedTrips, setDeletedTrips] = useState([]);
  const [formData, setFormData] = useState({
    idbuss: 0,
    idtuyenduong: 1,
    thoigianbatdau: "",
    thoigianketthuc: "",
    giachuyen: 0,
    mota: "",
    anhien: 1,
    trangthai: 1,
  });
  useEffect(() => {
    const fetchDeletedTrips = async () => {
      try {
        const token = localStorage.getItem('access_token'); // Lấy token từ localStorage hoặc nơi lưu trữ khác
        const response = await axios.get('http://localhost:8000/api/tripsDelete', {
          headers: {
            Authorization: `Bearer ${token}`,  // Gửi token trong header
          },
        });
        console.log("Dữ liệu chuyến đi đã xóa: ", response.data);
        setDeletedTrips(response.data);
      } catch (error) {
        console.error('Lỗi khi lấy danh sách chuyến đi đã xóa:', error);
        if (error.response && error.response.status === 401) {
          alert("Phiên làm việc hết hạn, vui lòng đăng nhập lại.");
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchDeletedTrips();
  }, []);
  const handleRestore = async (id) => {
    try {
      const token = localStorage.getItem('access_token'); // Lấy token từ localStorage (hoặc từ nơi lưu trữ khác)
      
      // Thêm token vào header của yêu cầu PATCH
      await axios.patch(
        `http://localhost:8000/api/trips/${id}/restore`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`, // Thêm Authorization header với Bearer token
          },
        }
      );
  
      alert('Tuyến đường đã được khôi phục!');
      setDeletedTrips(deletedTrips.filter((trip) => trip.id !== id));
    } catch (error) {
      console.error('Lỗi khi khôi phục tuyến đường:', error);
      alert('Không thể khôi phục tuyến đường. Vui lòng thử lại!');
    }
  };
  
  useEffect(() => {
    // Fetch the trips data from your Laravel API
    axios
      .get('http://localhost:8000/api/trips')
      .then((response) => {
        setTrips(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('There was an error fetching the trips!', error);
        setLoading(false);
      });
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    // Định dạng thời gian bắt đầu và kết thúc
    const formattedStartTime = new Date(formData.thoigianbatdau).toISOString().slice(0, 19).replace('T', ' ');
    const formattedEndTime = new Date(formData.thoigianketthuc).toISOString().slice(0, 19).replace('T', ' ');
  
    const updatedData = {
      ...formData,
      thoigianbatdau: formattedStartTime,
      thoigianketthuc: formattedEndTime,
    };
  
    const token = localStorage.getItem('access_token');
  
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  
    if (formData.idchuyen) {
      // Nếu là chỉnh sửa chuyến đi
      axios
        .put(`http://localhost:8000/api/trips/${formData.idchuyen}`, updatedData, config)
        .then((response) => {
          setTrips((prevTrips) =>
            prevTrips.map((trip) => (trip.idchuyen === formData.idchuyen ? response.data : trip))
          );
          setEditTrip(null);
          setFormData({}); // Reset form
          toast.success('Cập nhật chuyến đi thành công!');
        })
        .catch((error) => {
          console.error('Lỗi khi cập nhật chuyến đi:', error);
          toast.error('Không thể cập nhật chuyến đi!');
        });
    } else {
      // Nếu là tạo mới chuyến đi
      axios
        .post('http://localhost:8000/api/trips', updatedData, config)
        .then((response) => {
          setTrips([...trips, response.data]);
          setCreateTrip(false);
          setFormData({}); // Reset form
          toast.success('Tạo chuyến đi thành công!');
        })
        .catch((error) => {
          console.error('Lỗi khi tạo chuyến đi:', error);
          toast.error('Không thể tạo chuyến đi!');
        });
    }
  };
  
  

  const handleEdit = (trip) => {
    setFormData({
      idchuyen: trip.idchuyen || null, // Đảm bảo có ID của chuyến đi
      idbuss: trip.idbuss || 0,
      idtuyenduong: trip.idtuyenduong || 1,
      thoigianbatdau: trip.thoigianbatdau || "",
      thoigianketthuc: trip.thoigianketthuc || "",
      giachuyen: trip.giachuyen || 0,
      mota: trip.mota || "",
      anhien: trip.anhien || 1,
      trangthai: trip.trangthai || 1,
    });
  
    setEditTrip(trip); // Đặt trạng thái chuyến đi đang chỉnh sửa
  };
  

  const handleDelete = (id) => {
    // Get the token from localStorage or another source where it's stored
    const token = localStorage.getItem('access_token'); // or use context or state if needed
    
    // Set up headers with the Authorization token
    const headers = {
      Authorization: `Bearer ${token}`,
    };
  
    axios
      .delete(`http://localhost:8000/api/trips/${id}`, { headers })
      .then(() => {
        setTrips(trips.filter((trip) => trip.idchuyen !== id));
      })
      .catch((error) => {
        console.error('Error deleting the trip:', error);
      });
  };
  

  return (
   <>
  <div className="flex h-screen">
        <Sidebar />
    <div className="bg-gray-100 flex-1 flex flex-col">
     <Header/>
        <div className="flex-1 overflow-y-auto p-4 ">
           <div className="p-4 shadow-md flex justify-between items-center">
                    <button
            onClick={() => setCreateTrip(true)} // Show the create trip form
            className="bg-green-600 text-white px-4 py-2 rounded flex items-center space-x-2"
          >
            <i className="fas fa-plus"></i> {/* Icon add (plus) */}
            <span> Add New Trip</span>
          </button>   

           </div>
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white border border-gray-200">
              <thead>
                <tr>
                  <th className="py-2 px-4 border">ID</th>
                  <th className="py-2 px-4 border">Bus Name</th>
                  <th className="py-2 px-4 border">From</th>
                  <th className="py-2 px-4 border">To</th>
                  <th className="py-2 px-4 border">Price</th>
                  <th className="py-2 px-4 border">Seats</th>
                  <th className="py-2 px-4 border">Actions</th>
                </tr>
              </thead>
              <tbody>
                {trips.map((trip) => (
                  <tr key={trip.idchuyen} className="border">
                    <td className="py-2 px-4 border">{trip.idchuyen}</td>
                    <td className="py-2 px-4 border">
                      {trip.chitietxebuss?.tenxe}
                    </td>
                    <td className="py-2 px-4 border">
                      {trip.chitiettuyenduong?.noixuatphat}
                    </td>
                    <td className="py-2 px-4 border">
                      {trip.chitiettuyenduong?.noiden}
                    </td>
                    <td className="py-2 px-4 border">{trip.giachuyen} VND</td>
                    <td className="py-2 px-4 border">
                      {trip.chitietxebuss?.sochongoi}
                    </td>
                    <td className="py-3 px-4 border flex justify-center items-center">
                    <button
                      className="bg-yellow-500 text-white p-2 rounded mr-2"
                      onClick={() => handleEdit(trip)}
                    >
                      <PencilIcon className="h-5 w-5" />
                    </button>
                    <button
                      className="bg-red-500 text-white p-2 rounded mr-2"
                      onClick={() => handleDelete(trip.idchuyen)}
                    >
                      <TrashIcon className="h-5 w-5" />
                    </button>      
                  </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Add New Trip Modal */}
          {createTrip && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
    <div className="bg-white p-6 rounded shadow-lg w-3/4">
      <h2 className="text-xl font-semibold mb-6">Add New Trip</h2>
      
      <form onSubmit={handleSubmit}>
        <div className="flex flex-wrap -mx-4">
          {/* Cột trái */}
          <div className="w-full md:w-1/2 px-4 mb-4">
            {/* Buss ID */}
            <label className="block text-gray-700 mb-2" htmlFor="id_buss">
              Buss ID
            </label>
            <input
              type="number"
              name="idbuss"
              value={formData.idbuss}
              onChange={handleInputChange}
              onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')} // Chỉ cho phép nhập số
              inputMode="numeric"  // Thêm để kích hoạt bàn phím số trên di động
              className="w-full p-2 border border-gray-300 rounded"
              required
            />
          </div>

          {/* Route ID */}
          <div className="w-full md:w-1/2 px-4 mb-4">
            <label className="block text-gray-700 mb-2" htmlFor="id_tuyenduong">
              Route ID
            </label>
            <input
              type="number"
              name="idtuyenduong"
              value={formData.idtuyenduong}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
              required
            />
          </div>

          {/* Start Time */}
          <div className="w-full md:w-1/2 px-4 mb-4">
            <label className="block text-gray-700 mb-2" htmlFor="thoigianbatdau">
              Start Time
            </label>
            <input
              type="datetime-local"
              name="thoigianbatdau"
              value={formData.thoigianbatdau}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
              required
            />
          </div>

          {/* End Time */}
          <div className="w-full md:w-1/2 px-4 mb-4">
            <label className="block text-gray-700 mb-2" htmlFor="thoigianketthuc">
              End Time
            </label>
            <input
              type="datetime-local"
              name="thoigianketthuc"
              value={formData.thoigianketthuc}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
              required
            />
          </div>

          {/* Price */}
          <div className="w-full md:w-1/2 px-4 mb-4">
            <label className="block text-gray-700 mb-2" htmlFor="giachuyen">
              Price
            </label>
            <input
              type="number"
              name="giachuyen"
              value={formData.giachuyen}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
              required
            />
          </div>

          <div className="w-full md:w-1/2 px-4 mb-4">
          <label className="block text-gray-700 mb-2" htmlFor="trangthai">
            Status (Active / Inactive)
          </label>
          <div className="flex items-center">
            <input
              type="checkbox"
              name="trangthai"
              checked={formData.trangthai === 1} // Nếu "Active", checkbox được check
              onChange={(e) => handleInputChange({ target: { name: 'trangthai', value: e.target.checked ? 1 : 0 } })}
              className="mr-2"
            />
            <span>{formData.trangthai === 1 ? "Active" : "Inactive"}</span>
          </div>
        </div>

          {/* Trip Description */}
          <div className="w-full md:w-1/2 px-4 mb-4">
            <label className="block text-gray-700 mb-2" htmlFor="mota">
              Description
            </label>
            <textarea
              name="mota"
              value={formData.mota}
              onChange={handleInputChange}
              rows=""
              className="w-full p-2 border border-gray-300 rounded"
              placeholder="Enter a detailed description for the trip"
            />
          </div>

        </div>

        <div className="flex justify-end mt-6">
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded mr-2"
          >
            Create Trip
          </button>
          <button
            type="button"
            onClick={() => setCreateTrip(false)} // Close the modal
            className="bg-gray-500 text-white px-4 py-2 rounded"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  </div>
)}
{/* Edit Trip Modal */}
{editTrip && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
    <div className="bg-white p-6 rounded shadow-lg w-3/4">
      <h2 className="text-xl font-semibold mb-6">Edit Trip</h2>

      <form onSubmit={handleSubmit}>
        <div className="flex flex-wrap -mx-4">
          {/* Buss ID */}
          <div className="w-full md:w-1/2 px-4 mb-4">
            <label className="block text-gray-700 mb-2" htmlFor="id_buss">
              Buss ID
            </label>
            <input
              type="number"
              name="idbuss"
              value={formData.idbuss}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
              required
            />
          </div>

          {/* Route ID */}
          <div className="w-full md:w-1/2 px-4 mb-4">
            <label className="block text-gray-700 mb-2" htmlFor="id_tuyenduong">
              Route ID
            </label>
            <input
              type="number"
              name="idtuyenduong"
              value={formData.idtuyenduong}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
              required
            />
          </div>

          {/* Start Time */}
          <div className="w-full md:w-1/2 px-4 mb-4">
            <label className="block text-gray-700 mb-2" htmlFor="thoigianbatdau">
              Start Time
            </label>
            <input
              type="datetime-local"
              name="thoigianbatdau"
              value={formData.thoigianbatdau}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
              required
            />
          </div>

          {/* End Time */}
          <div className="w-full md:w-1/2 px-4 mb-4">
            <label className="block text-gray-700 mb-2" htmlFor="thoigianketthuc">
              End Time
            </label>
            <input
              type="datetime-local"
              name="thoigianketthuc"
              value={formData.thoigianketthuc}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
              required
            />
          </div>

          {/* Price */}
          <div className="w-full md:w-1/2 px-4 mb-4">
            <label className="block text-gray-700 mb-2" htmlFor="giachuyen">
              Price
            </label>
            <input
              type="number"
              name="giachuyen"
              value={formData.giachuyen}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
              required
            />
          </div>
        </div>

        <div className="flex justify-end mt-6">
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded mr-2"
          >
            Save Changes
          </button>
          <button
            type="button"
            onClick={() => setEditTrip(null)} // Close the modal
            className="bg-gray-500 text-white px-4 py-2 rounded"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  </div>
)}
 {/* Deleted Routes Section */}
 <div className="p-4 bg-gray-100">
        <h1 className="text-2xl font-semibold text-gray-700 mb-4">Danh sách chuyến đi đã xóa</h1>
        {isLoading ? (
          <div className="flex justify-center items-center h-32">
            <div className="animate-spin rounded-full h-10 w-10 border-t-4 border-blue-500"></div>
          </div>
        ) : deletedTrips.length === 0 ? (
          <p className="text-gray-500">Không có dữ liệu</p>
        ) : (
          <table className="min-w-full table-auto bg-white shadow-lg rounded-lg">
            <thead className="bg-gray-800 text-white">
              <tr>
                <th className="px-4 py-2 text-left">ID</th>
                <th className="px-4 py-2 text-left">Bus Name</th>
                <th className="px-4 py-2 text-left">From</th>
                <th className="px-4 py-2 text-left">To</th>
                <th className="px-4 py-2 text-left">Price</th>
                <th className="px-4 py-2 text-left">Seats</th>
                <th className="px-4 py-2">Actions</th>
              </tr>
            </thead>
            <tbody className="text-gray-700">
              {deletedTrips.map((trip) => (
                <tr key={trip.idchuyen} className="border-b hover:bg-gray-50">
                  <td className="px-4 py-3">{trip.idchuyen}</td>
                  <td className="px-4 py-3">{trip.chitietxebuss?.tenxe}</td>
                  <td className="px-4 py-3">{trip.chitiettuyenduong?.noixuatphat}</td>
                  <td className="px-4 py-3">{trip.chitiettuyenduong?.noiden}</td>
                  <td className="px-4 py-3">{trip.giachuyen}</td>
                  <td className="px-4 py-3">{trip.chitietxebuss?.sochongoi}</td>
                  <td className="px-4 py-3 flex justify-center space-x-3">
                  <button
                        className="bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600 flex items-center justify-center"
                        onClick={() => handleRestore(trip.idchuyen)}
                      >
                        <i className="fas fa-undo"></i>
                      </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
        </div>
      </div>
    </div>
   </>
  );
}

export default TripsManagement;
