import React from 'react';

const ThanhToanThanhCong = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-green-50 p-4">
      <div className="bg-white shadow-md rounded-lg p-6 text-center">
        <h2 className="text-2xl font-bold text-green-600 mb-4">
          Thanh toán thành công!
        </h2>
        <p className="text-gray-700">
          Cảm ơn bạn đã thanh toán. Đơn hàng của bạn đã được xử lý thành công.
          Vui lòng check mail để nhận mã QR
        </p>
      </div>
    </div>
  );
};

export default ThanhToanThanhCong;
