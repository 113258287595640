import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { QRCodeSVG } from "qrcode.react";  // Import QRCodeSVG từ thư viện

const MaQRPage = () => {
  const location = useLocation();
  const { qrData } = location.state || {};  // Lấy dữ liệu mã QR từ state

  const [qrUrl, setQrUrl] = useState("");

  useEffect(() => {
    if (qrData) {
      // Tạo URL cho mã QR từ thông tin vé
      setQrUrl(`https://localhost:80000/ve/${qrData.ticketId}`);
    }
  }, [qrData]);

  return (
    <div className="max-w-7xl mx-auto p-4">
      <h2 className="text-xl font-semibold text-center mb-6">Mã QR vé thanh toán</h2>
      {qrData ? (
        <div className="text-center">
          {/* Sử dụng QRCodeSVG để tạo mã QR */}
          <QRCodeSVG value={qrUrl} size={256} />
          <p className="mt-4 text-lg">Mã QR của vé có mã số: {qrData.ticketId}</p>
        </div>
      ) : (
        <p>Đang tải thông tin mã QR...</p>
      )}
    </div>
  );
};

export default MaQRPage;
