import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white py-16">
      <div className="footer-container max-w-screen-xl mx-auto px-6 md:px-10 flex flex-col md:flex-row justify-between items-start md:items-center">
        {/* About Section */}
        <div className="footer-about mb-8 md:mb-0 w-full md:w-1/3">
          <h3 className="text-3xl font-bold mb-4 text-green-600">BusFree</h3>
          <p className="text-lg">Cung cấp giải pháp đặt vé xe buýt nhanh chóng và tiện lợi cho sinh viên, với chất lượng dịch vụ ưu việt và giá cả phải chăng.</p>
        </div>

        {/* Useful Links Section */}
        <div className="footer-links mb-8 md:mb-0 w-full md:w-1/3">
          <h3 className="text-lg font-semibold mb-4">Liên kết hữu ích</h3>
          <ul className="space-y-2">
            <li>
              <a href="#" className="text-lg hover:text-green-600 transition-colors duration-300">Về chúng tôi</a>
            </li>
            <li>
              <a href="#" className="text-lg hover:text-green-600 transition-colors duration-300">Hỗ trợ</a>
            </li>
            <li>
              <a href="#" className="text-lg hover:text-green-600 transition-colors duration-300">Điều khoản sử dụng</a>
            </li>
          </ul>
        </div>

        {/* Social Media Section */}
        <div className="footer-social w-full md:w-1/3">
          <h3 className="text-lg font-semibold mb-4">Theo dõi chúng tôi</h3>
          <div className="flex space-x-6">
            <a href="#" className="flex items-center text-lg hover:text-green-600 transition-colors duration-300">
              <FontAwesomeIcon icon={faFacebook} className="mr-2" />
              Facebook
            </a>
            <a href="#" className="flex items-center text-lg hover:text-green-600 transition-colors duration-300">
              <FontAwesomeIcon icon={faInstagram} className="mr-2" />
              Instagram
            </a>
            <a href="#" className="flex items-center text-lg hover:text-green-600 transition-colors duration-300">
              <FontAwesomeIcon icon={faTwitter} className="mr-2" />
              Twitter
            </a>
          </div>
        </div>
      </div>

      {/* Footer Bottom Section */}
      <div className="mt-10 text-center text-sm text-gray-400">
        <p>&copy; 2024 BusFree. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
